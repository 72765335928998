.suppliers-page-header-statuses {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 8px 0 0 0;
  gap: 16px;
  row-gap: 8px;

  > * {
    // flex: 0 1 7em;
    justify-content: flex-start;
  }

  @media screen and (min-width: 576px) {
    margin: 0;
    justify-content: flex-end;
  }
}
