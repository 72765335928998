@use '../variables.scss';

.document-table-card {
  padding: 20px 20px 10px;
  display: block;
  border: 1px solid $light-grey;
  border-radius: 5px;
  font-weight: 500;

  .document-table-header {
    padding-bottom: 10px;

    // Indicates the Document accepts submissions and can expand/collapse
    &.is-clickable {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &.is-supplier {
      cursor: auto;
    }

    .main {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      .title-container {
        .title {
          font-size: 20px;
          color: $default-text;
          font-weight: 600;
          display: inline-block;
          margin-right: 10px;
        }

        .document-label {
          font-size: 12px;
          padding: 2px 15px;
          display: inline-block;
          vertical-align: text-bottom;

          &.is-required {
            color: $base-blue;
            border: 1px solid $base-blue;
            border-radius: 2.5px;
          }

          &.requires-approval {
            border: 1px solid $medium-blue;
            border-radius: 2.5px;
            color: $medium-blue;
          }

          &.blocks-access {
            border-radius: 2.5px;
            color: $medium-light-red;
            border: 1px solid $medium-light-red;
          }

          &.consent-required {
            border-radius: 2.5px;
            color: $medium-blue;
            border: 1px solid $medium-blue;
          }

          &:not(:last-child) {
            margin-right: 5px;
          }
        }
      }

      .menu {
        i {
          font-size: 30px;
        }
      }
    }

    .secondary {
      display: flex;
      justify-content: space-between;

      .text-container {
        .text-item {
          display: inline-block;

          &.file-type {
            span {
              text-transform: uppercase;
            }
          }

          &.filename {
            a {
              text-overflow: ellipsis;
              overflow-x: hidden;
              max-width: 200px;
              white-space: nowrap;
              color: $theme-link-color;
              display: inline-block;
              vertical-align: bottom;
              text-decoration: underline;

              @media screen and (min-width: $xlBreak) {
                max-width: 400px;
              }

              @media screen and (min-width: $xxlBreak) {
                max-width: 700px;
              }
            }
          }

          &.status {
            span {
              color: $base-grey;
            }

            // Indicates vendor has submitted a doc
            &.submitted {
              span {
                color: $dark-green;
              }
            }
          }

          &:not(:last-child) {
            margin-right: 60px;
          }
        }
      }

      .expand-icon {
        font-size: 24px;
        line-height: 24px;
        cursor: pointer;
        position: relative;
        top: -2px;
      }

      .description-container {
        margin-bottom: 25px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $dark-grey;
      }
    }

    .upload-button-container {
      text-align: right;
      margin-top: 20px;
    }

    .consent-checkbox-container {
      height: 20px;
      margin-top: 5px;
      .consent-text {
        font-size: 16px;
        font-weight: 500;
        color: $medium-grey;
        vertical-align: top;
      }
    }
  }

  .document-table-body {
    height: 0;
    opacity: 0;
    transition: all 0.2s ease-in 0s;
    -webkit-transition: all 0.2s ease-in 0s;
    -moz-transition: all 0.2s ease-in 0s;
    overflow: hidden;

    // Indicates is expanded
    &.is-expanded {
      border-top: 1px solid $lighter-grey;
      padding-top: 15px;
      height: 100%;
      opacity: 1;
    }
  }
}

.document-table-dropdown-menu {
  .ant-dropdown-menu {
    padding: 10px;
  }

  i {
    margin-right: 10px;
    width: 20px;
    color: $default-text;
  }

  .ant-dropdown-menu-item {
    font-weight: 600;

    &.delete {
      color: $base-red;

      i {
        color: $base-red;
      }
    }
  }
}

.submission-message-approved { color: $base-green !important }
.submission-message-rejected { color: $base-red !important }
.submission-message-pending { color: $light-grey !important }
