@import '@tokens';

.container {
  justify-content: space-between;
  height: 90px;
  background-color: $colorBgBase;
  padding: 0 10px 0 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid $colorBorder;

  .logo {
    // use normal visibility breakpoint when set (show only above 768px)
    &.true {
      display: none;

      @media screen and (max-width: $mediumBreak) {
        display: block;
      }
    }
  }

  // Without the sidebar
  padding-left: 0px;
  @media all and (min-width: $minSidebarBreak) {
    // With the sidebar showing and expanded
    &.sidebarExpanded {
      padding-left: $expandedSidebar;
    }

    // With the sidebar showing but collapsed
    &.sidebarCollapsed {
      padding-left: $collapsedSidebar;
    }
  }
}

.leftColumn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow: hidden;
}
