@use '../variables.scss';

$safeZIndex: 1001;

.side-menu {
  .ant-affix { z-index: $safeZIndex }
}

#sider {
  height: 100vh;
  overflow-x: hidden;
  background: #242528;
  position: relative;
  z-index: $safeZIndex;

  ul {
    background: #242528;
    border-color: transparent;

    li {
      margin: 0;
      height: auto;
      line-height: 19px;
      padding: 0 4px !important; /* Override AntD generated inline styles */

      &:active {
        background: transparent;
      }

      a {
        .adsicon,
        .anticon {
          font-size: 16px;

          svg {
            fill: #a5a6ab;
          }
        }

        .ads-badge {
          color: inherit;

          .link-text {
            font-size: 16px;
          }

          .ads-badge-count {
            background: $base-red;
            box-shadow: none;
          }
        }

        .adsicon {
          margin-right: 10px;
        }

        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        background: transparent;
        color: #a5a6ab;
        padding: 12px 15px;
        text-align: left;

        &.active {
          background: rgba(217, 217, 217, .15);
          color: white;
        }
      }
    }
  }

  // Use AntD classes for collapsed state
  &.ant-layout-sider-collapsed {
    @media screen and (max-width: $mediumBreak) {
      max-width: 0px !important;
      min-width: 0px !important;
      width: 0px !important;
    }

    ul li a {
      text-align: center;

      .adsicon {
        margin-right: 0;
      }

      // This is needed because the messages nav item has a different structure
      & > span:first-of-type > span.badge-count > span:nth-of-type(2),
      & > span:first-of-type > span:nth-of-type(2),
      & > span:nth-of-type(2) {
        display: none;
      }
    }
  }

  .adsicon,
  .anticon {
    line-height: 10px;
  }

  @media screen and (max-width: $mediumBreak) {
    position: fixed;
    z-index: $safeZIndex + 20;
    height: 100%;
  }
}
