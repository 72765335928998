.pre-qualification-table-header {
  border-bottom: 1px solid $lighter-grey;
  color: $medium-light-grey;

  th.column {
    font-size: 12px;
    padding: {
      top: 5px;
      bottom: 5px;
    }
  }
}
