.request-statuses-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 8px 0 0 0;
  gap: 16px;
  row-gap: 8px;

  > * {
    justify-content: flex-start;
  }
}
