.pre-qualification-lot {
  margin-bottom: 35px;

  .lot-header-container {
    display: flex;
    justify-content: space-between;

    .name {
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .line-items-count {
      text-align: right;
      font-size: 13px;
      color: $base-grey;
    }
  }
}
