@use 'sass:color';
@use './icons';
@import '@tokens';

@mixin light {
  --ht-font-size: #{$fontSize};
  --ht-line-height: 22px;
  --ht-font-weight: 400;
  --ht-gap-size: #{$sizeXxs};
  --ht-icon-size: #{$fontSizeMd};
  --ht-table-transition: 0.15s;
  --ht-border-color: #{$gray5};
  --ht-accent-color: #{$colorPrimary};
  --ht-foreground-color: #{$gray11};
  --ht-background-color: #{$gray1};
  --ht-placeholder-color: #{$gray7};
  --ht-read-only-color: #{$gray6};
  --ht-disabled-color: #{$gray6};
  --ht-cell-horizontal-border-color: #{$colorBorderSecondary};
  --ht-cell-vertical-border-color: #{$colorBorderSecondary};
  --ht-wrapper-border-width: 1px;
  --ht-wrapper-border-radius: #{$borderRadius};
  --ht-wrapper-border-color: #{$gray5};
  --ht-row-header-odd-background-color: #{$gray1};
  --ht-row-header-even-background-color: #{$gray1};
  --ht-row-cell-odd-background-color: transparent;
  --ht-row-cell-even-background-color: transparent;
  --ht-cell-horizontal-padding: #{$paddingXs};
  --ht-cell-vertical-padding: #{$paddingXxs};
  --ht-cell-editor-border-width: #{$controlOutlineWidth};
  --ht-cell-editor-border-color: #{$blue3};
  --ht-cell-editor-foreground-color: #{$gray13};
  --ht-cell-editor-background-color: #{$gray1};
  --ht-cell-editor-shadow-blur-radius: 0;
  --ht-cell-editor-shadow-color: #{$blue1};
  --ht-cell-success-background-color: rgba(55, 188, 108, 0.2);
  --ht-cell-error-background-color: rgba(250, 77, 50, 0.2);
  --ht-cell-selection-border-color: #{$blue3};
  --ht-cell-selection-background-color: #{$blue1};
  --ht-cell-autofill-size: 6px;
  --ht-cell-autofill-border-width: 1px;
  --ht-cell-autofill-border-radius: #{$borderRadiusSm};
  --ht-cell-autofill-border-color: #{$gray1};
  --ht-cell-autofill-background-color: #{$colorPrimary};
  --ht-cell-autofill-fill-border-color: #{$gray11};
  --ht-resize-indicator-color: #{$colorFillTertiary};
  --ht-move-backlight-color: #{$colorFillSecondary};
  --ht-move-indicator-color: #{$colorPrimary};
  --ht-hidden-indicator-color: #{$colorFillTertiary};
  --ht-scrollbar-border-radius: #{$borderRadiusLg};
  --ht-scrollbar-track-color: #{$gray2};
  --ht-scrollbar-thumb-color: #{$gray7};
  --ht-checkbox-size: #{$fontSizeMd};
  --ht-checkbox-border-radius: #{$borderRadiusSm};
  --ht-checkbox-border-color: #{$gray5};
  --ht-checkbox-background-color: #{$gray1};
  --ht-checkbox-icon-color: transparent;
  --ht-checkbox-focus-border-color: #{$gray5};
  --ht-checkbox-focus-background-color: #{$gray1};
  --ht-checkbox-focus-icon-color: transparent;
  --ht-checkbox-focus-ring-color: #{$colorPrimary};
  --ht-checkbox-disabled-border-color: #{$gray3};
  --ht-checkbox-disabled-background-color: #{$gray5};
  --ht-checkbox-disabled-icon-color: transparent;
  --ht-checkbox-checked-border-color: #{$colorPrimaryActive};
  --ht-checkbox-checked-background-color: #{$colorPrimary};
  --ht-checkbox-checked-icon-color: #{$gray1};
  --ht-checkbox-checked-focus-border-color: #{$gray1};
  --ht-checkbox-checked-focus-background-color: #{$colorPrimary};
  --ht-checkbox-checked-focus-icon-color: #{$gray1};
  --ht-checkbox-checked-disabled-border-color: #{$gray3};
  --ht-checkbox-checked-disabled-background-color: #{$gray5};
  --ht-checkbox-checked-disabled-icon-color: #{$gray6};
  --ht-header-font-weight: 00;
  --ht-header-foreground-color: #{$gray11};
  --ht-header-background-color: #{$colorFillQuaternary};
  --ht-header-active-border-color: #{$blue3};
  --ht-header-active-foreground-color: #{$gray13};
  --ht-header-active-background-color: #{$blue1};
  --ht-header-highlighted-shadow-size: 0;
  --ht-header-highlighted-foreground-color: #{$gray13};
  --ht-header-highlighted-background-color: #{$blue1};
  --ht-header-filter-background-color: rgba(55, 188, 108, 0.2);
  --ht-header-row-foreground-color: #{$gray11};
  --ht-header-row-background-color: #{$gray2};
  --ht-header-row-highlighted-foreground-color: #{$gray13};
  --ht-header-row-highlighted-background-color: #{$blue1};
  --ht-header-row-active-foreground-color: #{$gray13};
  --ht-header-row-active-background-color: #{$blue1};
  --ht-icon-button-border-radius: #{$borderRadiusXs};
  --ht-icon-button-border-color: #{$gray2};
  --ht-icon-button-background-color: #{$gray2};
  --ht-icon-button-icon-color: #{$colorFillTertiary};
  --ht-icon-button-hover-border-color: #{$gray3};
  --ht-icon-button-hover-background-color: #{$gray3};
  --ht-icon-button-hover-icon-color: #{$colorFillTertiary};
  --ht-icon-active-button-border-color: #{$blue7};
  --ht-icon-active-button-background-color: #{$colorPrimary};
  --ht-icon-active-button-icon-color: #{$gray1};
  --ht-icon-active-button-hover-border-color: #{$blue7};
  --ht-icon-active-button-hover-background-color: #{$blue6};
  --ht-icon-active-button-hover-icon-color: #{$gray1};
  --ht-collapse-button-border-radius: #{$borderRadiusSm};
  --ht-collapse-button-open-border-color: #{$gray5};
  --ht-collapse-button-open-background-color: #{$gray1};
  --ht-collapse-button-open-icon-color: #{$gray8};
  --ht-collapse-button-open-icon-active-color: #{$gray8};
  --ht-collapse-button-open-hover-border-color: #{$gray5};
  --ht-collapse-button-open-hover-background-color: #{$gray2};
  --ht-collapse-button-open-hover-icon-color: #{$gray8};
  --ht-collapse-button-open-hover-icon-active-color: #{$gray8};
  --ht-collapse-button-close-border-color: #{$gray5};
  --ht-collapse-button-close-background-color: #{$gray4};
  --ht-collapse-button-close-icon-color: #{$gray8};
  --ht-collapse-button-close-icon-active-color: #{$gray8};
  --ht-collapse-button-close-hover-border-color: #{$gray5};
  --ht-collapse-button-close-hover-background-color: #{$gray4};
  --ht-collapse-button-close-hover-icon-color: #{$gray8};
  --ht-collapse-button-close-hover-icon-active-color: #{$gray8};
  --ht-button-border-radius: #{$borderRadiusSm};
  --ht-button-horizontal-padding: #{$paddingSm};
  --ht-button-vertical-padding: 6px;
  --ht-primary-button-border-color: transparent;
  --ht-primary-button-foreground-color: #{$gray1};
  --ht-primary-button-background-color: #{$colorPrimary};
  --ht-primary-button-disabled-border-color: transparent;
  --ht-primary-button-disabled-foreground-color: #{$gray6};
  --ht-primary-button-disabled-background-color: #{$colorTextQuaternary};
  --ht-primary-button-hover-border-color: transparent;
  --ht-primary-button-hover-foreground-color: #{$gray1};
  --ht-primary-button-hover-background-color: #{$colorPrimaryHover};
  --ht-primary-button-focus-border-color: #{$gray1};
  --ht-primary-button-focus-foreground-color: #{$gray1};
  --ht-primary-button-focus-background-color: #{$colorPrimaryActive};
  --ht-secondary-button-border-color: #{$gray3};
  --ht-secondary-button-foreground-color: #{$gray11};
  --ht-secondary-button-background-color: #{$gray1};
  --ht-secondary-button-disabled-border-color: #{$gray3};
  --ht-secondary-button-disabled-foreground-color: #{$gray6};
  --ht-secondary-button-disabled-background-color: #{$gray4};
  --ht-secondary-button-hover-border-color: #{$gray3};
  --ht-secondary-button-hover-foreground-color: #{$gray11};
  --ht-secondary-button-hover-background-color: #{$gray1};
  --ht-secondary-button-focus-border-color: #{$gray3};
  --ht-secondary-button-focus-foreground-color: #{$gray11};
  --ht-secondary-button-focus-background-color: #{$gray1};
  --ht-comments-textarea-horizontal-padding: #{$paddingXs};
  --ht-comments-textarea-vertical-padding: #{$paddingXxs};
  --ht-comments-indicator-size: 6px;
  --ht-comments-indicator-color: #{$colorPrimary};
  --ht-comments-textarea-border-width: 1px;
  --ht-comments-textarea-border-color: transparent;
  --ht-comments-textarea-foreground-color: #{$gray11};
  --ht-comments-textarea-background-color: #{$gray2};
  --ht-comments-textarea-focus-border-width: 1px;
  --ht-comments-textarea-focus-border-color: #{$colorPrimary};
  --ht-comments-textarea-focus-foreground-color: #{$gray11};
  --ht-comments-textarea-focus-background-color: #{$gray1};
  --ht-license-horizontal-padding: #{$padding};
  --ht-license-vertical-padding: #{$paddingXs};
  --ht-license-foreground-color: #{$gray11};
  --ht-license-background-color: #{$gray2};
  --ht-link-color: #{$colorPrimary};
  --ht-link-hover-color: #{$colorPrimaryHover};
  --ht-input-border-width: 1px;
  --ht-input-border-radius: #{$borderRadiusSm};
  --ht-input-horizontal-padding: #{$paddingSm};
  --ht-input-vertical-padding: 6px;
  --ht-input-border-color: #{$gray3};
  --ht-input-foreground-color: #{$gray11};
  --ht-input-background-color: #{$gray2};
  --ht-input-hover-border-color: #{$gray3};
  --ht-input-hover-foreground-color: #{$gray11};
  --ht-input-hover-background-color: #{$gray1};
  --ht-input-disabled-border-color: #{$gray3};
  --ht-input-disabled-foreground-color: #{$gray6};
  --ht-input-disabled-background-color: #{$colorBgContainerDisabled};
  --ht-input-focus-border-color: #{$colorPrimary};
  --ht-input-focus-foreground-color: #{$gray11};
  --ht-input-focus-background-color: #{$gray1};
  --ht-menu-border-width: 1px;
  --ht-menu-border-radius: #{$borderRadiusSm};
  --ht-menu-horizontal-padding: 0;
  --ht-menu-vertical-padding: #{$paddingXs};
  --ht-menu-item-horizontal-padding: #{$paddingSm};
  --ht-menu-item-vertical-padding: #{$paddingXxs};
  --ht-menu-shadow-x: 0;
  --ht-menu-shadow-y: 8px;
  --ht-menu-shadow-blur: 16px;
  --ht-menu-border-color: #{$gray3};
  --ht-menu-shadow-color: #{$colorFillContentHover};
  --ht-menu-item-hover-color: #{$colorBgTextHover};
  --ht-menu-item-active-color: #{$colorBgTextActive};
}

.ht-theme-line-items,
.ht-theme-line-items-dark,
.ht-theme-line-items-dark-auto {
  @include light();
}

/* Icons */
[class*='ht-theme-line-items'] {
  @include icons.output();
}

// Custom overrides
.handsontable.ht-theme-line-items {
  // this is needed to make sure there is no space between
  // the bottom of the table and the add line items button
  margin-bottom: -2px;

  th, td {
    height: auto;
    vertical-align: middle;
    padding: 8px;
    line-height: 22px;

    .relative {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  thead tr {
    // first header row with column letters
    &:first-of-type th {
      padding: 6px 10px;
    }

    // second header row with column header names
    &:nth-child(2) th {
      font-weight: 600;
      background-color: $gray3;

      .relative {
        justify-content: flex-start;
      }
    }
  }

  // styles applied to the left sticky columns
  .ht_clone_top_inline_start_corner.ht_clone_top_left_corner.handsontable,
  .ht_clone_inline_start.ht_clone_left.handsontable {
    box-shadow: 8px 0 8px -1px rgba(0, 0, 0, 0.08);

    thead tr th {
      background: $gray2;
    }

    thead tr:nth-child(2) {

      th:first-of-type {
        padding: 0;

        .relative {
          border-right: 1px solid $colorBorderSecondary;
        }
      }

      th:nth-child(2) {
        border-left: none;
      }
    }

    // remove the border between the line item number and the name
    tbody td:nth-child(2) {
      border-left: none;
    }
  }

  // styles applied to the header of the right sticky column
  .ht_clone_top.handsontable table.htCore thead tr {
    th {
      &:last-child {
        box-shadow: -8px 0 8px -1px rgba(0, 0, 0, 0.08);
        position: sticky;
        right: 0;
        z-index: 12;
        background: $gray2;
      }
    }

    &:nth-child(2) th {
      background: $gray3;
    }
  }

  // styles applied to the body of the right sticky column
  .ht_master.handsontable table.htCore tbody td {
    &:last-child:not([role='rowheader']):not([role='row']) {
      background: $gray1;
      box-shadow: -8px 0 8px -1px rgba(0, 0, 0, 0.08);
      position: sticky;
      right: 0;
      z-index: 12;
    }
  }

  // Prevent the table z-index from overlapping with the main header in the app when scrolling
  &.ht-wrapper:before {
    z-index: 997;
  }

  // Prevent the inline styling from Handsontable JS from applying a set width
  // Forces contents to the correct width; no blank space when screen is resized
  .wtHider {
    width: 100% !important

  }
}
