.suppliers-by-table-container {
  .scroll-container {
    min-width: 100%;
    overflow: auto;

    // Disable overflow scrolling set by Ant to make Sync'd Scrolling work
    .styled-table.ant-table-wrapper .ant-table > .ant-table-content {
      .ant-table-scroll,
      .ant-table-scroll .ant-table-body {
        overflow: visible !important;
        overflow-x: visible !important;
      }
    }
  }

  .styled-table.ant-table-wrapper {
    .ant-table {
      > .ant-table-content {
        .ant-table-body {
          table {
            tbody.ant-table-tbody {
              tr {
                td {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }

  .actions-row {
    margin-top: 15px;
    text-align: center;
  }
}

