@use '../variables.scss';

.lot-questions-container {
  font-size: 14px;
  color: $dark-grey;
  font-weight: 600;

  > .ant-collapse.ant-collapse-icon-position-right {
    margin-bottom: 10px;

    > .ant-collapse-item {
      border-width: 1px;
      background: $lightest-green; // #fafefd;

      > .ant-collapse-header {
        font-size: 16px;
        font-weight: 600;

        .adsicon.adsicon-right,
        .anticon.anticon-right {
          &.ant-collapse-arrow {
            bottom: auto;
            top: 50%;
            font-size: 22px;
            color: $base-grey;
          }
        }
      }

      .ant-col {
        &:last-child {
          text-align: right;
        }
      }

      .ant-row {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }

  .header-row {
    color: $medium-dark-grey;
    font-size: 12px;
    font-weight: 400;
  }
}
