.company-transactions-container {
  padding: 20px;

  .action-buttons-container {
    margin-top: 30px;
    text-align: center;
    padding: 40px;
    border: 1px solid $light-grey;
    border-radius: 5px;

    i {
      font-size: 30px;
      color: $light-grey;
    }

    .add-new-btn {
      color: $theme-button-secondary-color;
      font-weight: 600;
    }
  }
}
