.channel-group-members-header {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.channel-group-members-title {
  //TODO: Add to tokens
  color: #9fa9ba;
}
