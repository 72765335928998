html {
  height: 100%;
}

// Wrapping div element from Next JS
#__next, #content-body {
  height: 100%;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: Roboto, Helvetica, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.5;
  color: $theme-text-color;
}

// Scrollbar Options
// ::-webkit-scrollbar              { background: red; }
// ::-webkit-scrollbar-button       {}
// ::-webkit-scrollbar-track        {}
// ::-webkit-scrollbar-track-piece  { }
// ::-webkit-scrollbar-thumb        { background: black }
// ::-webkit-scrollbar-corner       { /* 6 */ }
// ::-webkit-resizer                { /* 7 */ }

a {
  color: inherit;
  text-decoration: none;
}

.normal-link {
  color: $colorLink;
}

* {
  box-sizing: border-box;
}

// Dark Mode can affect background color on fields
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: $theme-text-color;
    background: inherit;
  }

  input,
  textarea {
    background: transparent;
  }
}

.primary-themed-background {
  background-color: #282F43;
  background: linear-gradient(155deg, #444548 10%, #2b2c2f 79%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.vertical-table {
  display: table;
  height: 100%;
  width: 100%;

  .vertical-table-cell {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }
}

.bottom-links {
  a {
    color: $theme-link-color;
    &:hover {
      color: #ffffff;
    }
  }
}

.details a,
.nav a {
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
}

label {
  color: $theme-text-color;
  display: block;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
}

#error_explanation {
  color: #ff0000;
}

.congrats-image {
  background-image: image-url('https://bidops-public.s3.amazonaws.com/assets/images/congrats.png');
  display: block;
  width: 141px;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
}

.opaque-tooltip {
	opacity: 1 !important;
}

.main-layout {
  .trigger {
    cursor: pointer;
  }
}

.content-outer {
  .content-header {
    background-color: #ffffff;
  }
  .content-inner {
    background-color: #ffffff;
    border: 1px solid #BAC8D0;
  }
  .content {
    min-height: 600px;
    padding: 5px;
  }
  .page-header {
    background-color: $page-header;
    color: #ffffff;
    padding: 0.75rem 1.25rem;
  }
}

.content-outer-new {
  .content-header {
    background-color: $primary-color;
  }

  .content {
    background-color: #ffffff;
    min-height: 600px;
    padding: 5px;
  }
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: $theme-text-color;
  font-size: 26px;
  margin-bottom: 20px;
  padding: 3px 10px;
}

.heading-container {
  border-bottom: 1px solid $lighter-grey;
  padding: {
    left: 20px;
    right: 20px;
    top: 30px;
    bottom: 20px;
  }
  margin-bottom: 15px;

  .page-panel-header {
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    margin-bottom: 35px;
  }
}

.page-actions {
  margin-top: -4px;
  text-align: right;
}

.transparent-page-panel {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

// ADS Spacing Overrides

.pull-right {
  float: right;
}

.padding-20 {
  padding: 20px;
}

.margin-bottom-small {
  margin-bottom: 8px;
}

.margin-bottom-medium {
  margin-bottom: 10px;
}

.margin-bottom-large {
  margin-bottom: 16px;
}

.margin-left-small {
  margin-left: 8px;
}

.margin-left-medium {
  margin-left: 10px;
}

.margin-left-large {
  margin-left: 16px;
}

.margin-left-xlarge {
  margin-left: 30px;
}

.margin-right-small {
  margin-right: 8px;
}

.margin-right-medium {
  margin-right: 10px;
}

.margin-right-large {
  margin-right: 16px;
}

.margin-right-xlarge {
  margin-right: 20px;
}

.margin-top-medium {
  margin-top: 10px;
}

.margin-top-large {
  margin-top: 20px;
}

.margin-top-xlarge {
  margin-top: 24px;
}

.padding-left-small {
  padding-left: 4px;
}

// ADS Button Overrides

.insight-button {
  align-items: center;
  align-self: stretch;
  height: $sizeXxl;
}

.insight-list-page-button {
  border-radius: $borderRadius;
  border-color: $colorBorder;
  box-shadow: $boxShadowButtonSolid;
  align-items: 'center';
}

.task-button {
  margin-top: 10px;
  justify-content: flex-end;
}

// Icon Overrides

.icon-red {
  color: red;
}

.award-icon {
  color: #ffcb13;
  margin-right: 10px;
  font-size: 20px;

  .large {
    font-size: 28px;
  }
}

.star-icon-color {
  color: #bebe00;
}

.key-icon-color {
  color: #be0000;
}
