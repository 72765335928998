.company-line-item-form-container {
  .fields-container {
    label {
      color: $medium-grey;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
    span.validation {
      color: $medium-red;
      font-size: 10px;
    }
  }

  .action-btns-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    .ant-btn-link {
      color: $theme-link-color;
      padding-right: 30px;
      padding-left: 30px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
  }
}
