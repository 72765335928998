.vendor-org-dashboard-block {
  .blocks {
    border: 1px solid #c9cdd1;
    padding: 15px;
    border-radius: 5px;

    .title {
      font-size: 14px;
    }

    .count-container {
      display: flex;
      align-items: baseline;
      font-size: 14px;
      color: #ababab;

      .count {
        font-size: 46px;
        margin-right: 5px;
      }
    }

    // Base Green
    &.shortlisted {
      .title {
        color: $theme-text-color;
      }

      .count-container {
        .count {
          color: $theme-text-color;
        }
      }
    }

    // Base Blue
    &.invited {
      .title {
        color: rgba(29, 147, 211, 1);
      }

      .count-container {
        .count {
          color: rgba(29, 147, 211, 1);
        }
      }
    }

    // Orange
    &.survey_pending {
      .title {
        color: rgba(255, 197, 21, 1);
      }

      .count-container {
        .count {
          color: rgba(255, 197, 21, 1);
        }
      }
    }

    // Dark Blue
    &.ready {
      .title {
        color: rgba(64, 96, 255, 1);
      }

      .count-container {
        .count {
          color: rgba(64, 96, 255, 1);
        }
      }
    }

    .list {
      border-top: 1px solid #eeeeee;
      padding-top: 20px;
      font-weight: 600;

      .label {
        font-size: 12px;
        color: #9b9ea8;
        margin-bottom: 10px;
        font-weight: 500;
      }
    }
  }
}
