.transaction-stats-bar-container {
  display: flex;
  justify-content: space-between;
  color: $medium-grey;

  .left {
    display: flex;
    justify-content: flex-start;

    > div {
      margin-right: 20px;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
  }
}
