.api-docs {
  &.impersonating {
    .redoc-wrap {
      .menu-content {
        top: 120px !important;
      }
    }
  }
  .redoc-wrap {
    .menu-content {
      top: 90px !important;
    }
  }
}
