.supplier-header-container {
  background: white;
  padding: 20px 20px 90px;

  .back-button-container {
    border-bottom: 1px solid $lighter-grey;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }
}
