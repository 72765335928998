@import '@tokens';

.container {
  // Without the sidebar
  padding-left: 0px;
  @media all and (min-width: $minSidebarBreak) {
    // With the sidebar showing and expanded
    &.sidebarExpanded {
      padding-left: $expandedSidebar;
    }

    // With the sidebar showing but collapsed
    &.sidebarCollapsed {
      padding-left: $collapsedSidebar;
    }
  }
}
