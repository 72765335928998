.question-display-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .line-number {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 600;
    max-width: 60px;
    padding-top: 25px;
    text-align: right;
    padding-right: 20px;

    // Ensures uniformity of line number widths
    &.low { width: 40px; } // < 3 characters
    &.medium { width: 50px; } // 3 characters
    &.high { width: 60px; } // 4 characters
  }

  .field-dot {
    border-radius: 100%;
    border: solid;
    display: inline-block;
    margin-left: 5px;

    &.red { color: $base-red; background-color: $base-red; }
    &.small {
      width: 6px;
      height: 6px;
      opacity: 1;
      margin-bottom: 2px;
    }
  }

  // The container of the actual question it and options
  .question-display {
    flex-grow: 1;
    padding: 20px;
    border-radius: 4px;
    border: solid 1px $light-grey;

    .question-text-container {
      .question-text {
        display: inline-block;
        font-size: 22px;
        color: $default-text;
      }

      .char-limit {
        font-size: 14px;
      }

      span:last-child {
        margin-left: 5px;
      }

      .pre-populated-indicator {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 5px;
        background: $theme-button-secondary-color;
        cursor: pointer;
        vertical-align: super;
      }
    }

    .options-container {
      margin-top: 15px;

      .ant-radio-group {
        &.ant-radio-group-outline {
          .ant-radio-wrapper {
            display: block; // Makes radio buttons vertical
            height: 30px;
            line-height: 30px;
          }
        }
      }
    }
  }
}
