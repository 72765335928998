.settings-dropdown {
  width: 38px !important;
  height: 38px !important;
  padding: 0 !important;
  font-size: 18px !important;
  margin-left: 8px;
  line-height: 38px;
}

.settings-dropdown-menu .upload-text {
  font-weight: bold;
}
