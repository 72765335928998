.vendor-group-anchor-price {
  border-radius: 5px;
  border: solid 1px #e5e9ed;
  padding: 20px;
  margin-bottom: 9px;

  .primary-table td {
    border-bottom: none;
    font-weight: 600;
  }

  .company-header-row {
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: bold;
    position: relative;

    .company-avatar-anchor {
      width: 35px;
      height: 34px;
      margin-right: 11px;
    }
  }

  .lot-name {
    font-size: 18px;
    font-weight: 600;
    color: #2b2c2f;
  }

  .lot-name-label {
    font-size: 12px;
    font-weight: 500;
    color: #9b9ea8;
    margin-right: 7px;
    vertical-align: top;
  }

  .total-price {
    font-size: 18px;
    font-weight: 600;
    color: #2b2c2f;
  }

  .ant-collapse {
    border: none;
    background-color: #ffffff;

    .ant-collapse-item {
      border-radius: 5px;
      border: solid 1px #c9cdd1;
      margin-bottom: 5px;

      .ant-collapse-content {
        background-color: inherit;
      }
    }

    .ant-collapse-item-active {
      border: solid 1px #9da3a9;
    }
  }

  .load-more-line-items-container {
    text-align: center;
    padding-top: 20px;
  }
}
