#supplier-pre-qualification {
  .action-btns {
    margin-top: 15px;
    background: white;
    padding: 15px 0;
    border-top: 1px solid transparent;
  }

  .ant-affix {
    height: auto !important;

    .action-btns {
      margin-top: 0;
      border-color: $light-grey;
      box-shadow: 0 -10px 10px -10px $light-grey;
    }
  }
}
