.notification-controls-table {
  .ant-table-thead {
    > tr:first-child {
      > th {
        background: transparent;
      }
    }
  }

  .ant-table-tbody {
    tr:last-child {
      td {
        border-bottom-color: transparent;
      }
    }
  }
}
