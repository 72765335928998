@import '../../../../styles/variables.scss';

.input {
  border: 1px solid $medium-grey;
  width: 100%;
  color: $default-text;
  font-size: 18px;
  line-height: 1;

  &:hover, &:focus {
    border-color: $theme-hover-color;
    outline: none;
  }
}

.textarea {
  width: 100%;
  border-color: $medium-grey;
  color: #000000;
  font-size: 14px;
  border-width: 1px;
  border-radius: 4px;
  min-height: 70px;

  &:hover, &:focus {
    border-color: $theme-hover-color;
    outline: none;
  }
}

// Styling borders
.input, .textarea {
  &[readonly] {
    cursor: not-allowed;
  }

  &:not(.withBorders) {
    border-top: none;
    border-left: none;
    border-right: none;
  }

  &.withError {
    border-color: $medium-red;
  }

  &.withBorders {
    border-radius: 4px;
    padding: 10px 15px;
    font-size: 14px;
  }
}

.label {
  color: $medium-grey;
  font-size: 14px;
  text-transform: none;
}

.closeButton {
  position: absolute;
  right: 15px;
  height: 20px;
  top: 50%;
  margin-top: -10px;
  cursor: pointer;

  &:hover {
    color: #333333;
  }
}
