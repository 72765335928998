#pricing-submission {
  .bid-request-tab-pane-content {
    padding: 0;

    .card {
      border: none;
    }

    #request-details-header {
      padding: 20px 20px 0 20px;

      @media all and (max-width: $xlBreak) {
        padding-top: 40px;
      }
    }

    .round-number-wrapper {
      padding: 0 20px;
    }

    #bid-submission {
      .search-container {
        margin: 0 20px;
      }

      .top-bar-button-row {
        padding: {
          left: 20px;
          right: 20px;
        }

        &.show-pricing {
          padding: 0 20px;
        }
      }

      .lots-list-info-row {
        padding: 0 20px;
      }

      .infinite-scroll-component {
        padding: 0 20px;
      }
    }
  }
}
