@import '../variables.scss';

.bid-request-tab-pane-content {
  padding: 20px;
  margin-bottom: 50px;
  .view-bid-btn-container {
    border-left: 1px solid $light-grey;
    padding: 5px;
  }
  .vendor-step-header-container {
    padding-bottom: 10px;
    border-bottom: 1px solid $lighter-grey;
  }
  .search-container {
    padding-top: 25px;
  }
  .supplier-documents-footer {
    display: flex;
    justify-content: space-between;
    color: $white;
    border-top: 1px solid transparent;
    padding-left: 15px;

    .title {
      display: flex;
      p {
        margin-top: 10px;
      }
    }
    i.supplier-documents-step-icon {
      color: $theme-icon-color;
      margin-top: 15px;
    }
    .supplier-documents-step-icon {
      float: left;
      margin-top: 10px;
      width: 34px;
      height: 34px;
      margin-right: 10px;
    }
  }
  .supplier-documents-status {
    display: flex;
    justify-content: space-between;
    .progress-container{
      margin-right: 20px;
    }
  }
}

.supplier-documents-complete-modal {
  &.modal_header {
    display: flex;
    flex-direction: row;

    i {
      color: $theme-text-color;
      font-size: 24px;
      margin-left: 8px;
    }
  }
  &.modal_footer {
    button {
      width: 20%;
    }
  }
}
