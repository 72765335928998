@import '@styles/variables.scss';

// Add a border between siblings
.listItem + .listItem {
  border-top: 1px solid $colorBorderSecondary;
}

.listItem {
  display: flex;
  align-items: flex-start;
  padding: 12px;
  box-sizing: border-box;

  .listItemContent {
    flex-grow: 1;

    .header {
      display: flex;
      justify-content: space-between;
      background-color: transparent;
      padding: 0;
      margin: 0;

      .newDot {
        display: inline-block;
        min-width: 8px;
        min-height: 8px;
        border: 1px solid $colorInfoBase;
        box-sizing: border-box;
        border-radius: 4px;
        margin-right: 8px;
        background-color: $colorInfoBase;
      }

      .title {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px;
        color: $colorText;
        width: 70%;
      }

      .timestamp, .timestampNew {
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        padding: 0;
        margin: 0;
      }

      .timestampNew {
        color: $colorPrimaryBase;
      }

      .timestamp {
        color: $colorTextTertiary;
      }
    }

    .content {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: $colorTextSecondary;
    }

    .footer {
      display: flex;
      justify-content: space-between;
    }
  }

  .icon {
    margin-right: 12px;
    background-color: $colorFillTertiary;

    img {
      max-width: 20px;
      max-height: 20px;
    }

    span[role=img] {
      display: block;
      box-sizing: border-box;
      padding: 6px;
    }

    // RecommendedSuppliers.scss is overwriting the color choice
    // TODO: when we determine the icons for the notifications, re-review this piece
    [data-icon=mail] {
      color: $colorIcon;
    }
  }
}
