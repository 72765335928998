@use '../variables.scss';

.csv-dropdown-menu {
  hr {
    margin-top: .5rem;
  }

  .menu-item {
    color: $default-text;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    padding: 4px 10px;
    width: 100%;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 5px;

    &:hover {
      background-color: $lightest-grey;
    }

    &:focus {
      outline: none;
    }

    &.switch {
      padding: 4px 4px;

      &:hover {
        background: none;
      }

      .ant-switch-small {
        min-width: 24px;
      }
    }

    .icon {
      padding-bottom: 4px;
      margin-right: 15px;
    }

    .toggle {
      margin-right: 10px;
    }

    .toggle-text {
      line-height: 20px;
      position: relative;
      top: -2px;
    }

    .icon-text {
      vertical-align: middle;
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }

    &.access-control {
      &.disabled {
        color: $light-grey;
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
}

.csv-options-dropdown {
  border: 1px solid #efefef;
  box-shadow: 0 2px 4px #ccc;
  background: white;
  padding: 15px 15px 5px;
  border-radius: 5px;

  button,
  a {
    margin-bottom: 10px;
  }

  .react-file-reader {
    display: block;
  }
}
