#supplier-questions-table-container {
  input {
    &:disabled {
      color: $default-text;
    }
  }

  .ant-select-disabled {
    color: $default-text;
  }

  .supplier-questions-table {
    .ant-table > .ant-table-content {
      .ant-table-body {
        overflow-x: auto !important;
      }
    }

    table {
      thead {
        tr {
          th {
            background: white;
            font-size: 12px;
            color: $medium-light-grey;
            border-color: transparent;

            &.line-number {
              text-align: center;
            }
          }
        }
      }

      tbody {
        tr {
          &.ant-table-row-hover,
          &:hover {
            td {
              background: transparent;
            }
          }

          td {
            padding: 2px;
            border-color: transparent;

            &.line-number {
              text-align: center;
              font-weight: 600;
              color: $dark-grey;
            }

            .cell-container {
              font-weight: 600;
              color: $dark-grey;

              &.question-text {
                input {
                  font-weight: 600;

                  &:not(:focus):not(:hover) {
                    border-color: $base-grey;
                  }
                }
              }

              &.question-type {
                .ant-select-selection {
                  .ant-select-selection-selected-value {
                    color: $dark-grey;
                  }
                }
              }

              &.weighting {
                border: 1px solid $lighter-grey;
                height: 100%;
                min-height: 42px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                flex-wrap: nowrap;
                align-items: center;
                border-radius: 4px;
                background: $lightest-grey;

                &.yes_no, &.multiple_choice {
                  background: white;
                  border-color: $base-grey;
                }

              }

              &.row-actions {
                display: flex;
                justify-content: center;
                align-items: center;

                i.anticon {
                  font-size: 20px;
                  color: $base-grey;

                  &:hover {
                    color: $dark-red;
                  }
                }

                &.branded {
                  i.adsicon,
                  i.anticon {
                    font-size: 20px;
                    color: $theme-icon-color;

                    &:hover {
                      color:$theme-hover-color;
                    }
                  }
                }
              }
            }
          }

          &.has-errors {
            td {
              .cell-container {
                &.weighting {
                  background: $background-yellow;
                  border: 1px solid $dark-yellow;

                  .action-container {
                    color: $dark-yellow;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
