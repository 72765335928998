.regenerate-anchor-prices-wrapper {
  position: absolute;
  right: 105px;
  top: 1px;

  .regenerate-anchor-prices-icon {
    font-size: 37px;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
