.questions-display-container {
  padding: 20px;
  margin-bottom: 50px;

  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
