.recommended-suppliers {
  background-color: #F5F5F5;
  padding: 1rem 1rem;
  margin: 1.5rem 0;

  h1 {
    background-color: transparent;
    color: $theme-header-color;
  }

  td {
    font-weight: 600;
  }

  tr {
    background-color: white;
  }

  .styled-table.ant-table-wrapper .ant-table > .ant-table-content .ant-table-body table thead.ant-table-thead > tr {
    background: none;
  }

  .add-icon {
    text-align: right;
    padding-left: auto;
    padding-right: 1.5rem;
  }

  .reason-text {
    font-weight: 400;
  }

  .organization-text,
  .approval-status,
  .date {
    font-weight: 600;
  }

  .header {
    display: flex;

    .toggle-container {
      margin-left: auto;
      display: flex;
      align-items: center;

      .switch {
        margin-top: 1%;
        margin-left: 0.5rem;
      }

      span {
        font-size: 0.85rem;
        font-weight: 500;
      }
    }
  }

  .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body  {
    background: transparent;
  }

  table {
    tr {
      th {
        background: #F5F5F5 !important;

        &.ant-table-column-sort {
          background: $background-green;
        }
      }
    }
  }

  &.recommended-suppliers-hide-bg {
    background-color: transparent;
  }

  &.pull-top {
    margin-bottom: -1rem;
  }

  .no-recommendations{
    text-align: center;
    margin: 0 auto;
    max-width: 50%;
    font-size: .9rem;
  }

}

[data-icon="mail"] {
  color: black;
}
