@use '../variables.scss';

#pre-qualification-lots {
  .search-bar  {
    margin-bottom: 20px;
  }

  .load-more-lots-container {
    text-align: center;
  }

  .actions-row {
    margin-top: 15px;
    background: white;
    padding: 15px 0;
    border-top: 1px solid transparent;
  }

  .ant-affix {
    height: auto !important;

    .actions-row {
      margin-top: 0;
      border-color: $light-grey;
      box-shadow: 0 -10px 10px -10px $light-grey;
    }
  }
}
