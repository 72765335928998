.suppliers-table-container {
  padding: 20px;

  .page-panel:first-child {
    margin-bottom: 20px;
  }

  .suppliers-table-body {
    .table-filters-container {
      margin-bottom: 15px;
    }

    .suppliers-table.styled-table.ant-table-wrapper .ant-table > .ant-table-content .ant-table-body table tbody.ant-table-tbody {
      > tr {
        td {
          color: #2b2c2f;
          font-weight: 600;
        }
      }
    }
  }
}
