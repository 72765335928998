.collapsible-card {
  padding: 20px 20px 10px;
  display: block;
  border: 1px solid $light-grey;
  border-radius: 5px;
  font-weight: 500;

  &:hover {
    border-color: $theme-hover-color;
  }

  // Indicates body is expanded
  &.true {

  }

  .collapsible-card-body {
    height: 0;
    opacity: 0;
    transition: all 0.2s ease-in 0s;
    -webkit-transition: all 0.2s ease-in 0s;
    -moz-transition: all 0.2s ease-in 0s;
    overflow: hidden;

    // Indicates is expanded
    &.true {
      border-top: 1px solid $lighter-grey;
      padding-top: 15px;
      height: 100%;
      opacity: 1;
    }
  }
}
