.suppliers-page-header-dropdown-button {
  .ant-dropdown-trigger {
    display: inline-flex;
    flex: 0;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: {
      left: 0;
    }
  }

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    color: $default-text;
  }

  .adsicon,
  .anticon {
    margin-left: 16px;
    font-size: 22px;
    color: $theme-icon-color;
  }
}
