@use '../variables.scss';

.analytics-block,
.dashboard-block {
  .ant-card-body {
    padding: 5px 10px;
  }
  font-weight: 500;
  .title {
    font-size: 16px;
    text-align: center;
  }
  .count {
    font-size: 24px;
    text-align: center;
  }
  .name {
    font-size: 16px;
    text-align: left;
  }
  .center {
    text-align: center;
  }
  .badge-large {
    background-color: #ffffff;
    border-radius: 80%;
    color: #ff0000;
    display: inline-block;
    font-size: 18px;
    height: 35px;
    line-height: 35px;
    width: 35px;
  }
  .badge {
    background-color: #ffffff;
    border-radius: 80%;
    color: #ff0000;
    display: inline-block;
    font-size: 14px;
    font-weight: 300;
    height: 25px;
    line-height: 18px;
    width: 25px;
  }
  .legned-box {
    margin-left: 20px;
    margin-right: 3px;
    display: inline-block;
    width: 10px;
    height: 10px;
  }
}

.dashboard-block.accept {
  .card {
    color: #ffffff;
  }
}

.dashboard-block.bid-counts {
  .card {
    background-color: #83A9CB;
    color: #ffffff;
  }
  .card-td {
    padding: 0 5px;
  }
}


.dashboard-block.ranks {
  .bid {
    text-align: center;
  }
  .ant-card {
    background-color: #229CBC;
    color: #ffffff;
  }
  .tank-td {
    text-align: center;
    width: 40px;
  }

}

.dashboard-block.timings {
  .bid-time {
    margin-right: 5px;
  }
}

.analytics-block {
  margin-top: 20px;
  .ant-card {
    color: #ffffff;
  }
  .block {
    margin-top: 20px;
  }
}
