.weighting-display-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 14px;
  font-weight: 600;
  width: 100%;

  // Indicates Data Exists
  &.true {
    flex-direction: row;
    justify-content: space-between;
  }

  &.false {
    cursor: pointer;
  }

  .info-container {
    padding-left: 15px;
    text-align: left;
    color: $default-text;
    cursor: pointer;
    white-space: nowrap;
    max-width: 85%;
    text-overflow: ellipsis;
    overflow-x: hidden;

    .weight-display {
      display: inline-block;
      font-weight: 600;

      &:first-child {
        margin-right: 10px;
      }

      &.bonus {
        .value,
        .questionable-type {
          color: $dark-green;
        }
      }

      &.malus {
        .value,
        .questionable-type {
          color: $dark-red;
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    background: $lightest-grey;

    .info-container {
      cursor: not-allowed;
    }

    .action-container {
      span {
        cursor: not-allowed;
      }
    }
  }

  .action-container {
    color: $theme-text-color;
    text-align: right;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;

    &:focus, &:hover {
      outline: none;
      color: $theme-hover-color;
    }

    span {
      cursor: pointer;
    }
  }

  // Indicates No Data
  &:not(.true) {
    .action-container {
      width: 100%; // Allows entire div to be clickable to Add Weightings
    }
  }
}
