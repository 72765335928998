.weightings-choice-row {
  input {
    height: auto;
  }

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  .heading {
    color: $medium-light-grey;
    font-size: 12px;
    text-align: left;
  }

  .cell-container {
    .ads-input-number {
      padding: 5px 5px 5px 0;
      border-color: $base-grey;
    }
    .ads-input-number-group-addon {
      padding: 0;
      border-color: $base-grey;
      .ant-select-selector {
        border: 0;
      }
    }
    &.bonus-malus {
      &.bonus {
        .ant-select {
          color: $dark-green;
        }
      }

      &.malus {
        .ant-select {
          color: $dark-red;
        }
      }
    }

    &.delete {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      padding: 10px;

      .adsicon,
      .anticon {
        font-size: 20px;
        cursor: pointer;

        &:hover {
          color: $dark-red;
        }
      }
    }
  }

  // Indicates it's a Yes/No Row
  &.true {
    .cell-container {
      &.supplier-answer {
        font-weight: 600;
        padding: 10px;
      }
    }
  }
}
