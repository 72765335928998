@use '../variables.scss';

.weighting-section-container {
  margin-bottom: 20px;

  .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }

  .subtitle {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .slider-container {
      margin-right: 20px;
      width: 100%;

      .ant-slider-track {
        background: $theme-color;
      }

      .ant-slider-handle {
        border-color: $theme-color;
      }
    }

    .input-container {
      position: relative;
      width: 100px;

      .add-on {
        position: absolute;
        right: 10px;
        text-align: right;
        content: '';
        z-index: 1;
        font-size: 12px;
        height: 14px;
        top: 50%;
        margin-top: -7px;
        line-height: 12px;
        color: $base-grey;
      }

      input {
        width: 100%;
      }
    }
  }
}
