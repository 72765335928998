@use '../variables.scss';

.field-dot-container {
  padding-right: 8px;
  width: 20px;
  display: inline-block;
}

.field-dot {
  border-radius: 100%;
  border: solid;
  display: inline-block;
  margin-left: 5px;

  &.red { color: $base-red; background-color: $base-red; }
  &.teal { color: $theme-color; background-color: $theme-color; }
  &.blue { color: $base-blue; background-color: $base-blue;}

  &.small {
    width: 6px;
    height: 6px;
    opacity: 1;
    margin-bottom: 2px;
  }
}

.line-item-submission-sno {
  min-width: max($snoColumnMinWidth,5vw);
  left: 0;
  position: sticky;
  z-index: 1;
  background-color: white;
}

.line-item-submission-name {
  min-width: 200px;
  left: max($snoColumnMinWidth,5vw);
  position: sticky;
  z-index: 1;
  background-color: white;
}

$tableBackground: white;
$deleteWidth: 45px;

tr.lots-line-item-row {
  td {
    color: $default-text;
    font-size: 14px;
    font-weight: 500;
    background: $tableBackground;

    .ant-input, .ant-input-number-input {
      color: $default-text;
      font-size: 14px;
      font-weight: 500;
    }

    &.errors {
      background: $light-red;
    }
  }
}


#lots-line-items {
  input[type='text'] {
    &.access-control {
      &.disabled {
        color: $light-grey;
        cursor: not-allowed;
        border-color: $light-grey;
      }
    }
  }

  .access-control {
    &.disabled {
      input[type='number'] {
        color: $light-grey;
        cursor: not-allowed;
        border-color: $light-grey;
      }
    }
  }

  button {
    &.add-line-item-button {
      &.access-control {
        &.disabled {
          background-color: $gray6;
          border: none;
          color: $gray7;
          cursor: not-allowed;
        }
      }
    }
  }

  tr.lots-line-item-row {
    .row-header {
      padding-top: 4px;
      padding-bottom: 4px;
      padding-left: 14px;
      border-bottom: none;
    }
    td,
    .row-header {
      color: $default-text;
      font-size: 14px;
      font-weight: 500;
      background: $tableBackground;

      .line-item-switch {
        vertical-align: middle;
        margin-bottom: 1px;
      }

      .ant-input, .ant-input-number-input {
        color: $default-text;
        font-size: 14px;
        font-weight: 500;
      }

      &.delete {
        color: $light-grey;
        font-size: 20px;
        text-align: center;
        padding: {
          left: 10px;
          right: 10px;
        }
        position: absolute;
        right: 1px;
        line-height: 44px;
        background: $tableBackground;
        width: $deleteWidth;

        &:hover {
          color: $base-red;
        }
      }

      &:nth-last-of-type(2) {
        padding-right: $deleteWidth + 10px;
      }

      .screen-reader-label {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    }

    &.withErrors td,
    &.withErrors .row-header {
      background-color: $background-red;
    }
    &.withWarnings td,
    &.withWarnings .row-header  {
      background-color: $antd-warning-background-color;
    }
  }
}
