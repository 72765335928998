@use 'sass:map';

@mixin icon($icons, $name) {
  width: var(--ht-icon-size);
  height: var(--ht-icon-size);
  -webkit-mask-size: contain;
  -webkit-mask-image: url(map.get($icons, $name));
  background-color: currentColor;
}

@mixin icons-map($icons) {
  &.htDropdownMenu table tbody tr td.htSubmenu .htItemWrapper::after,
  &.htContextMenu table tbody tr td.htSubmenu .htItemWrapper::after,
  &.htFiltersConditionsMenu table tbody tr td.htSubmenu .htItemWrapper::after,
  .pika-single .pika-next {
    @include icon($icons, 'arrow-right');
  }

  .pika-single .pika-prev {
    @include icon($icons, 'arrow-left');
  }

  .changeType::before {
    @include icon($icons, 'menu');
  }

  .htUISelectCaption::after,
  .htAutocompleteArrow::after {
    @include icon($icons, 'select-arrow');
  }

  .columnSorting.sortAction.ascending::before {
    @include icon($icons, 'arrow-narrow-up');
  }

  .columnSorting.sortAction.descending::before {
    @include icon($icons, 'arrow-narrow-down');
  }

  &.htDropdownMenu table tbody tr td .htItemWrapper span.selected::after,
  &.htContextMenu table tbody tr td .htItemWrapper span.selected::after,
  &.htFiltersConditionsMenu
    table
    tbody
    tr
    td
    .htItemWrapper
    span.selected::after {
    @include icon($icons, 'check');
  }

  .htCheckboxRendererInput::after {
    @include icon($icons, 'checkbox');
  }

  th.beforeHiddenColumn::after {
    @include icon($icons, 'caret-hidden-left');
  }

  th.afterHiddenColumn::before {
    @include icon($icons, 'caret-hidden-right');
  }

  th.beforeHiddenRow::after {
    @include icon($icons, 'caret-hidden-up');
  }

  th.afterHiddenRow::before {
    @include icon($icons, 'caret-hidden-down');
  }

  .collapsibleIndicator,
  .ht_nestingButton {
    &::before {
      @include icon($icons, 'collapse-off');
    }

    &.collapsed,
    &.ht_nestingExpand {
      &::before {
        @include icon($icons, 'collapse-on');
      }
    }
  }

  .htUIRadio > input[type='radio']::after {
    @include icon($icons, 'radio');
  }
}
