.filters-button {
  display: inline-block;
  border-radius: 20px;
  padding: 10px 25px;
  background: #e0e0e0;
  color: #2b2c2f;
  cursor: pointer;

  &:hover {
    background: #efefef;
  }

  .filter-divider {
    margin-left: 10px;
    margin-right: 10px;
    color: #767a85;
  }

  .displayed-filter {
    color: #2b2c2f;
    font-weight: 600;
  }
}
