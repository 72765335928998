@use '../variables.scss';

body {
  background: white;

  #request-overview {
    .header {
      background: $dark-grey;
      padding: 15px 25px;
      text-align: center;
      border-bottom: 1px solid #ccc;
    }

    .content {
      margin: 0 auto;
      padding: 30px 20px;

      input {
        border-radius: 5px;
        padding: 5px;
        border: 1px solid #ccc;
        margin-bottom: 20px;
        min-width: 300px;
      }
    }
  }
}
