.upload-special-template-delete {
  color: $base-red;
  font-size: 18px;
  cursor: pointer;
  opacity: .6;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
}
