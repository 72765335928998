@use '../variables.scss';

$verticalSpacing: 15px;

.survey-section-wrapper {
  margin-bottom: $verticalSpacing;
  border-radius: 5px;
  position: relative;
  z-index: 1;

  // Indicates dragging a question over this list
  &.true {
    &:after {
      content: '';
      position: absolute;
      border: 2px dashed $theme-hover-color;
      top: 145px;
      left: -10px;
      right: -10px;
      bottom: 50px;
      z-index: -1;
      border-radius: 5px;
    }
  }

  .survey-section-container {
    border: 1px solid $light-grey;
    position: relative;
    margin-bottom: $verticalSpacing;
    border-radius: 5px;

    // Is editing
    &.true {
      border-color: $theme-hover-color;
    }

    .handle {
      opacity: 0;
      position: absolute;
      top: 10px;
      width: 30px;
      text-align: center;
      left: 50%;
      margin-left: -15px;
      cursor: grab;
      color: $theme-text-color;
      transition: all 0.3s ease 0s;
    }

    &:hover {
      .handle {
        opacity: 1;
      }
    }

    .section-header {
      position: relative;
      font-size: 12px;
      padding: 10px 20px;
      background: #F5F5F5;
      color: #000;
    }

    .section-body {
      padding: 20px;
      background: white;

      .title-container {
        .title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          font-size: 20px;
          font-weight: 700;

          .input-field-wrapper {
            flex-grow: 1;

            input {
              margin-right: 20px;
              z-index: 1;
            }
          }

          .actions {
            position: relative;
            text-align: right;
            width: 100px;
            z-index: 2; // Important for event propagation with editing state

            .delete {
              color: $dark-red;
              padding: 10px;
              text-align: center;
              border-radius: 20px;
              background: transparent;
              transition: all 0.3s ease 0s;

              &:hover {
                background: $base-red;
                color: white;
              }
            }
          }
        }
      }

      .questions-count-container {
        font-size: 12px;
      }

      .extras-container {

      }
    }

    &.false {
      .section-body {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        cursor: pointer;
      }
    }
  }

  .add-new-container {
    // This number corresponds to the width of the question number
    padding-left: 40px;
  }
}
