.sliding-drawer {
  .ant-drawer-header-no-title,
  .ant-drawer-header {
    z-index: 5;
  }

  .channel-drawer-lists-wrapper {
    .str-chat__channel-preview__action-button--archive {
      display: none;
    }
    .str-chat__channel-preview__action-button--pin {
        display: none;
    }

    .str-chat-channel-list {
      width: 100% !important;
      max-width: 100% !important;
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      min-height: auto !important;
      z-index: auto !important;
      overflow-y: auto !important;
      float: unset !important;
      border: none;
      box-shadow: none;
    }
  }
}
