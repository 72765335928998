@import '../../styles/variables';
#bid-submission-action-buttons {
  overflow: hidden;
  margin-left: 50px;
  h3{
    color: $white;
  }
  .submit-buttons-row {
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .draft-last-saved-at-container {
      div:first-of-type {
        font-size: 12px;
      }

      .saved-text-container {
        position: relative;

        .saved-text {
          display: inline-block;
          position: relative;
          top: 0;
          left: 0;
          transition: left 250ms linear, height 500ms linear, width 500ms linear, opacity 500ms linear;
          opacity: 1;
          white-space: nowrap;

          &.false {
            opacity: 0;
            transition: left 250ms linear, height 250ms linear, width 250ms linear, opacity 250ms linear;
            height: 0;
            width: 0;
          }
        }
      }
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
    }

    @media screen and (min-width: $largeBreak) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      .col {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
      }
    }
  }
}
