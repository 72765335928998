.collapsible-card-header {
  // Indicates body has children to display
  &.true {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.category-collapsible-card-header-container {
  padding-bottom: 10px;

  .main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .title-container {
      .label-container {
        color: $theme-text-color;
      }

      .title {
        font-size: 20px;
        color: $default-text;
        font-weight: 600;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .menu {
      i {
        font-size: 30px;
      }
    }
  }

  .summary {
    margin-bottom: 15px;
  }

  .secondary {
    display: flex;
    justify-content: flex-end;

    // Indicates there are stats being included
    &.true {
      justify-content: space-between;
    }

    .stats-container {
      width: 100%;
    }

    .expand-icon {
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      color: $theme-icon-color;
      position: relative;
      margin-left: 35px;
    }
  }

  .fields-container {
    label {
      color: $medium-grey;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }
  }

  .action-btns-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;

    .ant-btn-link {
      color: $theme-text-color;
      padding-right: 30px;
      padding-left: 30px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.collapsible-card-dropdown-menu {
  .ant-dropdown-menu {
    padding: 10px;
  }

  i {
    margin-right: 10px;
    width: 20px;
    color: $default-text;
  }

  .ant-dropdown-menu-item {
    font-weight: 600;

    &.delete {
      color: $base-red;

      i {
        color: $base-red;
      }
    }
  }
}
