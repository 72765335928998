.schedule-section {
  font-size: 18px;
  line-height: 22px;
  padding: 20px 0;

  .delimiter-column {
    font-size: 18px;
    text-align: center;
  }

  .section-date-time {
    text-align: center;

    p {
      margin: 0;
    }
  }

  .schedule-section-title {
    padding-left: 20px;
    padding-top: 10px;

    i {
      vertical-align: -0.135em;
    }
  }
}

.active-schedule-section {
  background: $lightest-grey;
  color: $theme-text-color;

  .schedule-section-countdown-container {
    display: inline;
    background: $white;
    padding: 4px 8px;
    font-size: 12px;
    border: 1.2px solid $theme-text-color;
    border-radius: 3px;
    margin-left: 10px;
    color: $theme-text-color;
    width: 209px;
    height: 26px;

    .closed-in {
      font-weight: 400;
    }
  }
}
