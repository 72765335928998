@use '../variables.scss';

.bullet-label-container {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .ellipse {
    min-width: 8px;
    min-height: 8px;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 8px;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $default-text;
    white-space: nowrap;
    text-transform: capitalize;
  }
}
