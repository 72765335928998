@use '../variables.scss';

.published-alert {
  border: #000;
  margin-top: 5px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  .published-alert-title {
    font-weight: 600;
  }
  p {
    margin-bottom: 0;
  }
}
