@use '../variables.scss';

.stacked-name-display-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span.children {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: $default-text;
  }

  span.secondary {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $medium-light-grey;
  }
}
