@use '../variables.scss';

// TabPaneTitle component is designed to be used within AntD's Tabs.TabPane on the {tab} prop for styles overrides
// to work as expected.

.tab-pane-title-container {

  .title {
    font-size: 16px;
  }

  .subtitle {
    color: $base-grey;
    font-size: 12px;
    line-height: 140%;
  }
}

.ant-tabs-tab-active.ant-tabs-tab {
  .tab-pane-title-container .subtitle {
    color: inherit;
  }
}
