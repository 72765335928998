@use '../variables.scss';

.survey-section-extra {
  padding: 15px;
  background: $lightest-grey;
  border-radius: 4px;
  font-weight: 600;
  font-size: 13px;

  span {
    margin-left: 15px;
    margin-right: 15px;
  }
}
