#suppliers-table-filter-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .table-filters-container {

  }

  .sort-dropdown-container {

  }
}
