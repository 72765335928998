@import '@tokens';

.bid-request-tabs-container {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid $light-grey;

    .card-body {
      padding: 0;
      flex: 1 1 auto;
    }
  }

  .bid-request-tabs {
    // Top Tabs
    .ads-tabs.primary-tabs {
      width: 100%;
    }

    // Secondary Tabs
    .ads-tabs.boxed {
      .ads-tabs-nav-wrap {
        margin: 0 8px;
      }

      // This is needed to make the label the full size of the tab and not just the text
      // Important for when we use the tabs with PopConfirm
      &.popconfirm-tabs .ads-tabs-tab {
        padding: 0 !important;

        .ads-tabs-tab-btn > div,
        .ads-tabs-tab-btn > span > div
        {
          padding: 8px 16px;
        }
      }
    }

    .subtabs-row {
      // Override the negative margin from <Row>
      margin: 0 !important;
    }
  }

  .bid-request-tab-pane-content {
    padding: 20px;
  }

  &.supplier-request-details {
    .ads-tabs {
      width: 100%;
    }

    .loading-container {
      padding: 20px;

      @media all and (max-width: $xlBreak) {
        padding-top: 40px;
      }
    }

    .bid-request-tab-pane-content {
      @media all and (max-width: $xlBreak) {
        padding-top: 40px;
      }
    }
  }
}

.with-sticky-footer {
  padding-bottom: 80px;

  @media all and (max-width: $mediumBreak) {
    // Mobile
    padding-bottom: 170px;
  }

  @media all and (max-width: $largeBreak) {
    // Small screens
    padding-bottom: 120px;
  }
}
