#line-item-pre-qualification-header {
  .header-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      font-weight: 600;
      font-size: 20px;
    }

    .show-suppliers-toggle {
      font-weight: 500;
      font-size: 13px;
    }
  }
}
