@use '../variables.scss';

// TODO: remove all of these overwrites once <Steps> component is brought in from ADS
.request-schedule-steps {
  .antd-v5-steps-item .antd-v5-steps-item-icon {
    background-color: transparent;
    border-color: $base-grey;
  }

  .antd-v5-steps-item > .antd-v5-steps-item-container > .antd-v5-steps-item-tail::after {
    background-color: $base-grey;
  }

  .antd-v5-steps-item .antd-v5-steps-item-icon > .antd-v5-steps-icon {
    color: $base-grey;
  }

  .antd-v5-steps-item-finish .antd-v5-steps-item-icon {
    background-color: $light-grey;
    border-color: $light-grey;
  }

  .antd-v5-steps-item-finish > .antd-v5-steps-item-container > .antd-v5-steps-item-tail::after {
    background-color: $default-text;
  }

  .antd-v5-steps-item-finish .antd-v5-steps-item-icon > .antd-v5-steps-icon {
    color: $default-text;
  }

  .antd-v5-steps-item-process .antd-v5-steps-item-icon {
    background-color: $default-text;
    border-color: $default-text;
  }

  .antd-v5-steps-item-process > .antd-v5-steps-item-container > .antd-v5-steps-item-tail::after {
    background-color: $light-grey;
  }

  .antd-v5-steps-item-process .antd-v5-steps-item-icon > .antd-v5-steps-icon {
    color: $light-grey;
  }
}
