.fraction-display {
  white-space: nowrap;

  // Indicates to fill background
  &.true {
    padding: 16px 15px;
    border-radius: 5px;
    display: inline-block;
    background: rgba(230, 230, 230, 0.5);
    line-height: 0;
  }

  .label {
    font-size: 12px;
    text-align: center;
    margin-bottom: 4px;
    line-height: 12px;
    color: $medium-grey;
  }

  .numberValue {
    text-align: center;
  }
}
