#new-event-wrapper {
  background: white;
  padding: 40px;
  height: 100%;

  // For centering
  .new-event-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 75%;

    .new-event-card {
      min-width: 55%;

      .heading {
        font-size: 35px;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 20px;

        .step-progress-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .new-event-progress-bar {
            white-space: nowrap;
            margin-right: 15px;
            width: 100%;

            .progress-bar-wrap {
              background: $lightest-grey;
            }
          }

          .step-text {
            white-space: nowrap;
            font-size: 14px;
            line-height: 18px;
           color: $theme-text-color;
            text-align: right;
          }
        }
      }

      .body {
        padding: 30px;
        border: 1px solid $base-grey;
        border-radius: 5px;
        margin-top: 10px;

        .ant-collapse {
          border: none;

          .ant-collapse-header {
            font-weight: 700;
            font-size: 16px;
            padding: 20px 0px;
            background: white;

            .ant-collapse-extra .check-circle-container {
              .adsicon.adsicon-check-circle,
              .anticon.anticon-check-circle{
                color: $theme-icon-color;
              }
            }
          }

          .ant-collapse-item {
            .ant-collapse-arrow {
              display: none;
            }

            .ant-collapse-content-box {
              padding: {
                left: 0;
                right: 0;
              }
            }

            &:last-child {
              border-bottom-color: transparent;

              > .ant-collapse-content {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom-color: transparent;
              }
            }
          }
        }

        .col-right {
          > a {

          }

          text-align: right;
        }

        .action-btns {
          margin-top: 20px;
        }

        .ready-step-container {
          text-align: center;

          .title {
            margin-bottom: 30px;

            img {
              width: 80px;
            }

            h2 {
              font-size: 26px;
            }

            p {
              color: $medium-grey;
            }
          }
        }
      }
    }
  }
}
