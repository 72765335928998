@use '../variables.scss';

$radioSize: 14px;

.answer-choice-row-wrapper {
  .answer-choice-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .radio {
      width: $radioSize;
      height: $radioSize;
      border: 1px solid $base-grey;
      border-radius: $radioSize;
      margin-right: $radioSize;
      margin-left: 5px;
    }

    .choice-input {
      flex-grow: 1;
      position: relative;
      margin-right: 10px;

      i {
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 15px;
      }

      // Indicates the text is in an empty state
      &.true {
        input {
          border-color: $dark-red;
        }
      }
    }

    .pointage-container {
      position: relative;

      .points-display {
        position: absolute;
        top: -30px;
        left: 0;
        color: $base-grey;
        font-size: 12px;
      }
    }

    .actions {
      width: 80px;
      padding: 0 15px 0 20px;
      text-align: right;
      font-size: 20px;

      .handle {

      }

      .delete {
        display: inline-block;
        padding: 0 5px;
        cursor: pointer;
        transition: all 0.2s ease-in 0s;
        -webkit-transition: all 0.2s ease-in 0s;
        -moz-transition: all 0.2s ease-in 0s;
        color: $light-grey;

        a, i {
          color: $light-grey;
        }

        &:hover {
          a, i {
            color: $dark-red;
          }
        }
      }
    }
  }
}
