$inputHeight: 45px;
$leftColumnPadding: 14px;
$borderRadius: 5px;
$borderWidth: 1px;
$tablePadding: 15px;

.pre-qualification-lot-table {
  border: $borderWidth solid $light-grey;
  border-radius: $borderRadius;
  padding: $tablePadding;
  overflow-x: auto;

  &.standard-scrollbar {
    &::-webkit-scrollbar {
      height: .5em !important;
    }
  }

  &:hover {
    &.standard-scrollbar {
      &::-webkit-scrollbar-thumb {
        background: $theme-hover-color;
      }
    }
  }

  table {
    thead {
      tr {
        th {
          color: $medium-light-grey;
          background-color: white;
          font-weight: 600;
          font-size: 12px;
          padding: 15px 0 15px $leftColumnPadding;
          border-bottom: $borderWidth solid $light-grey;

          // Every column but the row number
          &:not(:first-child) {
            min-width: 115px;
          }
        }
      }
    }

    tbody {
      td {
        padding: 20px 0 20px $leftColumnPadding;
        border-bottom: none;
        font-weight: 600;
        vertical-align: top;

        .ant-input-number,
        .ant-input-number input {
          min-width: 75px;
          height: $inputHeight;
          min-height: $inputHeight;
          text-align: left;
        }

        .ant-input {
          min-width: 75px;
        }

        textarea {
          min-height: $inputHeight !important;
          text-align: left;
          vertical-align: top;
        }
      }
    }
  }
}
