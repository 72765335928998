.tag-container {
  display: inline-flex;
  padding: 6px 8px;
  border-radius: 4px;

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $default-text;
    white-space: nowrap;
  }
}
