@import '../../styles/variables.scss';

$actionBtnMargin: 15px;
$saveTextMargin: 5px;

#standard-supplier-survey-questions {
  .file-upload-download{
    padding-top: 20px;
    border-bottom: 1px solid $light-grey;
  }
  h3 {
    line-height: 42px;
    margin-bottom: 20px;
    font-weight: 600;
    justify-content: space-between;

    .icon-button {
      font-weight: 400;

      &.upload {
        padding-right: 20px;
      }
    }
  }

  .ant-affix {
    height: auto !important;
  }

  .survey-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    border-color: $light-grey;
    box-shadow: 0 -10px 10px -10px $light-grey;
    font-size: 18px;
    background: #242528;
    border-top: 1px solid transparent;
  }
  .action-btns {
    display: flex;
    justify-content: space-between;
    border-color: $light-grey;
    box-shadow: 0 -10px 10px -10px $light-grey;
    font-size: 18px;
    background: #242528;
    color: white;
    border-top: 1px solid transparent;

    .saving-container {
      padding-left: 15px;
      line-height: 120%;

      i.supplier-survey-step-icon {
        color: $white;
        margin-top: 15px;
      }
      .supplier-survey-step-icon{
        float: left;
        margin-top: 10px;
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }
      .right-no-save {
        margin-top: 13px;
        float: left;
      }
      .right{
        float: left;
        margin-left: 10px;
        padding-top: 5px;
      }
      .saving-changes {
        color: $medium-grey;
        font-size: 14px;
        opacity: 100;
        transition: opacity 500ms linear;

        // Indicates should be hiding the "Saved" text so fade it out
        &.false {
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s 500ms, opacity 500ms linear;
        }
      }

    }

    // Specificies the View Bid Button that generates as an <a> and not <button>
    a {
      margin-right: $actionBtnMargin;
    }

    button {
      &:not(:last-of-type) {
        margin-right: $actionBtnMargin;
      }
    }

    .submit-container{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 18px;
      padding-left: 15px;
      padding-right: 15px;

      .questions-answered-container{
        padding-right: 10px;
        &.false{
          border-right: $base-grey solid 1px;
        }
        &.buyer-preview{
          visibility: hidden;
        }
      }
      .submit{
        padding-left: 10px;
        &.true{
          border-right: $base-grey solid 1px;
          margin-right: 10px;
          padding-right: 10px;
        }
        &.buyer-preview{
          visibility: hidden;
        }
      }
    }
  }
}
  .action-helper {
    font-weight: 600;
    font-size: 13px;
    text-align: right;
    color: $medium-grey;
  }

.submission-success-modal {
  text-align: center;

  i.success {
    font-size: 46px;
    margin-top: 50px;
    color: $theme-icon-color;
    margin-bottom: 10px;
  }

  .header {
    font-size: 22px;
  }

  p {
    margin-bottom: 50px;
  }
}
