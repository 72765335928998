#line-item-pre-qual-submissions {
  .stats-bar {
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .stats-group {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
