@use '../variables.scss';

#scorers-tab-pane {
  .invite-btns {
    button {
      padding: 5px 30px;
      height: 42px;
      font-weight: 500;
      font-size: 14px;
      border-width: 2px;
      text-transform: uppercase;

      &.cancel {
        background: white;
        color: #000;
        border-color: #000;
      }

      &.send:not(:disabled) {
        background: #000;
        color: white;
        border-color: #000;
      }

      &.add {
        background: $theme-button-secondary-color;
        color: white;
        border-color: $theme-button-secondary-color;
        padding-right: 10px;
      }

      &.invite {
        background: white;
        color: $theme-button-secondary-color;
        border-color: $theme-button-secondary-color;
      }
    }
  }
}
