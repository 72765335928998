@use '../variables.scss';

$borderRadius: 20px;
$inputFontSize: 14px;

.search-bar {
  position: relative;
  height: 42px;
  line-height: 0;
  white-space: nowrap;
  border-radius: $borderRadius;

  .search-bar-icon {
    position: absolute;
    font-size: 18px;
    height: 20px;
    top: 50%;
    margin-top: -10px;
    left: 15px;
    color: $medium-light-grey;
    z-index: 2;
  }

  .clear-input-icon {
    position: absolute;
    z-index: 2;
    top: 50%;
    color: #9b9ea8;
    margin-top: -5px;
    font-size: 8px;
    height: 20px;
    right: 15px;
  }

  .input {
    background: #eef0f2;
    font-size: 14px;
    padding: 20px 20px 20px 40px;
    border-radius: $borderRadius;
    border-color: transparent;

    // addonAfter prop style overrides
    &.ant-input-group-wrapper {
      padding: 0;
      border-top-right-radius: $borderRadius;
      border-bottom-right-radius: $borderRadius;

      .ant-input-wrapper {
        .ant-select {
          .ant-select-selection__rendered {
            padding-right: 15px;
            padding-left: 15px;

            .ant-select-selection-selected-value {
              font-weight: 600;
              color: $default-text;
            }
          }
        }

        input {
          background: #eef0f2;
          padding: 20px 20px 20px 40px;
          font-size: 14px;
          border-top-left-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
          border-color: transparent;
        }

        &.ant-input-group {
          .ant-select-focused {
            color: $default-text;
          }

          > .ant-input:last-child,
          .ant-input-group-addon:last-child {
            border: 1px solid $light-grey;
            border-top-right-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;
          }

          .ant-input:hover,
          .ant-input:focus {
            z-index: 0;
          }

          .ant-input:focus {
            outline: none;
            border-color: #cccccc;
            box-shadow: none;
          }
        }
      }
    }

    &:focus {
      outline: none;
      border-color: #cccccc;
      box-shadow: none;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: $light-grey;
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: $light-grey;
    }

    &:-ms-input-placeholder { /* IE 10+ */
      color: $light-grey;
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: $light-grey;
    }
  }
  .autocomplete-select-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;

    .no-match-display {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      padding: 20px;
      top: 40px;
      background: white;
      z-index: 2;
      text-align: center;
      border: 1px solid $light-grey;
      border-radius: $borderRadius;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top-color: transparent;
    }

    .autocomplete-select {
      display: block;
      height: 32px;

      .ant-select-selection {
        height: 100%;
        border-radius: $borderRadius;

        .ant-select-selection__rendered {
          line-height: $inputFontSize;
        }
      }

      .ant-select-selection__placeholder {
        padding-left: 30px;
        margin-top: -5px;
      }

      // Weird css positioning glitch with AntD Autocomplete, so don't need this in disabled state
      &.ant-select-disabled {
        height: 42px;

        .ant-select-selection__placeholder {
          margin-top: -10px;
        }
      }

      .ant-input.ant-select-search__field {
        height: 42px;
        font-size: $inputFontSize;
        padding: 0 20px 0 40px;
        border-radius: $borderRadius;

        &:focus {
          outline: none;
          border-color: #cccccc;
          box-shadow: none;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $light-grey;
        }

        &::-moz-placeholder { /* Firefox 19+ */
          color: $light-grey;
        }

        &:-ms-input-placeholder { /* IE 10+ */
          color: $light-grey;
        }

        &:-moz-placeholder { /* Firefox 18- */
          color: $light-grey;
        }
      }

      /* Opened */
      &.ant-select-open {
        input.ant-input.ant-select-search__field {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-color: $light-grey;
        }
      }
    }
    .input {
      background: #eef0f2
    }
  }

  .addon-counts-container {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 10px;
    bottom: 0;
    width: auto;
    line-height: 15px;
    padding: 5px;
    text-align: right;
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
  }
}




// Customize the look of the select fields display
.autocomplete-select-dropdown {
  &.ant-select-dropdown {
    border-radius: $borderRadius;
    border: 1px solid $light-grey;
    box-sizing: border-box;

    ul {
      padding: 13px 15px 15px 15px;

      li {
        padding-left: 2px;

        &:not(:last-child) {
          margin-bottom: 2px;
        }

        &.ant-select-dropdown-menu-item {
          border: 1px solid transparent;
          padding: {
            left: 5px;
            right: 5px;
          }
        }

        &.ant-select-dropdown-menu-item-active {
          background: transparent;
          border-color: $theme-color;
        }

        &.ant-select-dropdown-menu-item-selected {
          background: $theme-color;
          color: white;
        }

        i {
          margin-right: 10px;
        }
      }

      // Style the scrollbar
      &::-webkit-scrollbar {
        width: .6em;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
        box-shadow: none;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #e8e8e8;
        outline: none;
        border-radius: 10px;
      }
    }

    .ant-select-dropdown-menu-item {
      border-radius: 4px !important;
    }
  }
}
