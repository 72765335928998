.chat-current-channel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;

  @media all and (max-width: 960px) {
    background-color: $white;
    padding-bottom: 30px;
  }
}
