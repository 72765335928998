.upload-special-template-file-display-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $light-grey;
  border-radius: 5px;
  padding: 10px 20px;

  .loading-text {
    text-align: right;
    font-size: 13px;
    color: $medium-grey;
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  a {
    text-overflow: ellipsis;
    overflow-x: hidden;
    color: $theme-link-color;
    display: inline-block;
    text-decoration: underline;
  }
}
