@use '../variables.scss';

.individual-vendors-request-table.styled-table.ant-table-wrapper {
  .ant-table > .ant-table-content .ant-table-body {
    table {
      tbody.ant-table-tbody {
        > tr {
          // Don't show a clickable state if invitation is pending
          &.not-accessible,
          &.not-accessible td {
            cursor: default;
            background: transparent;

            &:hover {
              background: transparent;
            }
          }

          &.selection-disabled,
          &.selection-disabled td {
            cursor: default;
            span {
              cursor: not-allowed;
              label {
                span {
                  background-color: $lightest-grey;
                  input {
                    cursor: not-allowed;
                  }
                }
              }
            }
          }

          td {
            color: $default-text;
            font-weight: 600;
          }
        }
      }
    }
  }
}

.selected-suppliers-user-message {
  padding: 16px 0;
  border-radius: 5px;
  background-color: #edf5f4;
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
}
