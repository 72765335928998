.icon-button {
  cursor: pointer;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;

  &:focus {
    outline: none;
  }

  .icon-wrapper {
    padding: 10px;
    display: inline-flex;
    border-radius: 34px;
    width: 34px;
    height: 34px;
    border: 2px solid $lightest-grey;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    i {
      color: $theme-text-color;
      font-size: 18px;
    }
  }

  // Indicates button is active
  &.true .icon-wrapper {
    background: $dark-grey;
    color: white;
    border-color: $dark-grey;

    i {
      color: white;
    }
  }

  span {
    color: $default-text;
    font-size: 14px;
  }
}
