@use '../variables.scss';

.request-date-widget {
  display: inline-flex;
  align-items: center;
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 15px;

  // Indicates active widget
  &.true {
    border-color: $lighter-grey;
  }

  .request-state-icon-container {
    margin-right: 15px;
  }

  .date-container {
    .date,
    .time {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .schedule-container {
    color: $theme-link-color;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;

    &.disabled {
      color: $light-grey;
    }
  }
}
