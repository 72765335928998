.section-container {
  margin-top: 30px;

  .read-only {
    .label {
      color: $medium-grey;
      font-size: 14px;
      text-transform: capitalize;
    }

    .text {
      font-size: 18px;
    }
  }
}
