.icon {
  font-size: 20px;
  margin-left: 30px;
  margin-right: 24px;
  color: #b9c2d0;
  cursor: pointer;

  &:hover {
    color: #242528;
  }
}
