.supplier-body-wrapper {
  padding: 0 20px;

  .supplier-body-container {
    background: white;
    position: relative;
    top: -60px; // required offset based on supplier header padding
    border: 1px solid $light-grey;
    border-radius: 5px;

    section {
      .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 20px;
        color: $default-text;
      }

      .secondary-title {
        font-size: 14px;
        line-height: 18px;
        color: $medium-grey;
        margin-bottom: 15px;
      }
    }
  }
}
