.superadmin-file-uploads-container {
  .upload-option-row {
    border-bottom: 1px solid grey;
    padding: 20px;

    .upload-option-description {
      width: 45%;
      display: inline-block;
    }
    .upload-option {
      display: inline-block;
    }
  }
}


