.grouped-suppliers-table-container {
  position: relative;

  .suppliers-by-table-container {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .standalone-table-headers-container {
    table {
      .ant-table-thead > tr > th {
        &:not(:first-of-type) {
          padding-left: 8px;
        }
      }
    }
  }
}
