@import '@tokens';

.no-wrap {
  white-space: nowrap;
}

// <mark> tags that highlight sections of text
.mark-container {
  mark {
    background: $marks;
    padding: 0.2em 0;
  }
}

#route-div {
  height: 100%;
}

.react-file-reader {
  display: inline-block;
}

.field-label {
  font-size: 1.2em;
}

.grey-label-text {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #95979e;
}

.teal-text {
  color: $theme-text-color;
}

.field-value {
  font-size: 1.2em;
  font-weight: 500;
  margin-left: 10px;
}

.small {
  .field-label {
    font-size: 1em;
  }

  .field-value {
    font-size: 1em;
    font-weight: 500;
    margin-left: 10px;
  }
}

.str-chat__header-livestream-right-button-wrapper {
  display: none;
}

.sub-bar {
  background-color: #F4F4F4;
}

.primary-button {
  background-color: $theme-primary-button-color;
  color: $theme-primary-button-color;
  padding: 7px 15px 7px 15px;
  &:hover {
    color: lighten($theme-primary-button-color, 20%);
    text-decoration: none;
  }
}

.primary-button-large {
  background-color: $primary-color;
  color: $theme-primary-button-color;
  padding: 7px 15px 7px 15px;
  &:hover {
    color: lighten($theme-primary-button-color, 20%);
    text-decoration: none;
  }
  font-size: 24px;
}

.warning-button-large {
  background-color: $no;
  color: $theme-primary-button-color;
  padding: 7px 15px 7px 15px;
  &:hover {
    color: lighten($theme-primary-button-color, 20%);
    text-decoration: none;
  }
  font-size: 24px;
}

.table-no-padding {
  width: 100%;
  td {
    padding: 2px !important;
  }
}

.acceptance-btn {
  border: none;
  color: $white;
  &:active, &:focus, &:hover, &:visited {
    color: $white;
    font-weight: 500;
  }
}

// Below are finalized style. Review and remove above

.newevent {
  text-align: center;
  margin: auto;
  width: 75%;
  font-size: 20px;
}

.vendorBidButtonRow {
  display: flex;
  td {
    margin-top: 10px;
    padding-left: 10px;
    vertical-align: top;
  }
}

.vendorBidButtonRowToggler{
  span {
    padding-left: 10px;
  }
}

.vendor-invited {
  color: #A9A9A9;
}

.vendor-input {
  width: 200px;
  margin-right: 10px;
}
.invite-vendor {
  margin-right: -47px;
  color: #1890ff;
}

.link-button {
  background: none;
  border: none;
  cursor: pointer;
}

.onboard {
  .title {
    color: #000000;
    font-size: 20px;
    line-height: 40px;
    padding-left: 10px;
  }
  .sign-out {
    float: right;
    font-size: 12px;
    line-height: 40px;
    padding-right: 10px;

    a {
      color: #cccccc;
    }
  }
  .content {
    height: 360px;
  }
  .footer {
    padding: 5px 0;
    text-align: right;
    button {
      margin-right: 10px;
    }
  }
}

.task-date-wrapper {
  .ant-calendar-picker-input, .ant-input-number, .ant-select-selection, .ant-time-picker-input  {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none;
    padding-left: 0;

    &:focus {
      border-color: $theme-hover-color;
    }
    &:hover {
      border-color: $theme-hover-color;
    }
    &:active {
      border-color: $theme-hover-color;
    }
  }
}

.request-flow {
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;

  .header-bar-wrap{
    width: 100%;
    position: absolute;
    bottom: 100;
    left: 100;
    height: 72px;

    .header-bar {
      background-color: white;
      height: 100%;
    }
  }

  .request-date-wrapper {
    .ant-calendar-picker-input, .ant-input-number, .ant-select-selection, .ant-time-picker-input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      box-shadow: none;
      padding-left: 0;

      &:focus {
         border-color: $theme-hover-color;
      }
      &:hover {
        border-color: $theme-hover-color !important;
      }
      &:active {
         border-color: $theme-hover-color;
      }
    }

    .ant-select-selection {
      background-color:  #f6f6f6;
    }

    .ant-input-number-input{
      padding-left: 0 !important;
    }
  }

  .title-text {
    font-size: 21px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: normal;
    padding-top: 10px;
    color: #242528;
  }

  .subtitle-text {
    font-size: 18px;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #95979e;

    &.small {
      font-size: 14px
    }
  }

  .wider-info-section {
    max-width: 830px;
    height: 561px;
    margin: auto;
    position: absolute;
    padding: 36px 46px 25px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 5px 20px 3px rgba(95, 163, 178, 0.1);
    display: flex;
    flex-direction: column;
    background-color: #ffffff;

    &.category {
      height: 530px;

      @media screen and (max-width: $smallBreak) {
        height: 630px;
      }
    }
  }

  .option-button-div {
    text-align: center;
    position: absolute;
    bottom: 55px;
    right: 0;
    left: 0;
  }

  .option-button {
    max-width: 335px;
    min-width: 335px;
    height: 45px;
    border-radius: 25px;
    background-color: $theme-button-secondary-color;
    border: none;
    margin-right: 12px;
    margin-left: 13px;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: 10px;

    @media screen and (max-width: $smallBreak) {
      top: 20px;
      width: 80%;
      min-width: 305px;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .more-info-text {
    font-size: 16px;
    color: #95979e;
    font-weight: 200;
    margin-top: 25px;
  }

  .title-text-input {
    margin-top: 40px;

    input {
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }

  .description-text-input {
    margin-top: 20px;
  }

  .continue-button-div {
    text-align: center;
    margin-top: 29px;
  }

  .step-fraction {
    font-size: 18px;
    color: $theme-text-color;
    text-align: right;
    float: right;

    @media screen and (max-width: $smallBreak) {
      position: absolute;
      top: 57px;
      right: 22px;
    }
  }

  .character-limit-text {
    float: right;
    color: #95979e
  }

}

.select-borderless {
  .ant-select-selection{
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-radius: 0;
    border-color: $theme-color !important;
  }

  .ant-select-selection__rendered {
    margin-left: 1px;
  }

  .ant-select-arrow-icon {
    color: $theme-icon-color;
  }
}

.buyer-onboard {
  background-image: linear-gradient(155deg, #edf4ed, #c5e2ea);
  height: 100vh;
  width: 100vw;
  .header {
    padding-bottom: 56px;
  }
  .tall-info-section {
    width: 447px;
    height: 569px;
    margin: auto;
    position: absolute;
    padding: 36px 46px 25px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 5px 20px 3px rgba(95, 163, 178, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  }
  .short-info-section {
    width: 447px;
    height: 415px;
    margin: auto;
    position: absolute;
    padding: 36px 46px 25px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 5px 20px 3px rgba(95, 163, 178, 0.1);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
  }
  .longer-info-section {
    width: 478px;
    height: 695px;
    margin: auto;
    position: absolute;
    padding: 36px 46px 25px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 5px 20px 3px rgba(95, 163, 178, 0.1);
    background-color: #ffffff;
    flex-direction: column;
    display: flex;
  }
  .wider-info-section {
    width: 830px;
    height: 561px;
    margin: auto;
    position: absolute;
    padding: 36px 46px 25px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 4px;
    box-shadow: 0 5px 20px 3px rgba(95, 163, 178, 0.1);
    flex-direction: column;
    background-color: #ffffff;
    display: flex;
  }
  .overview-text-section {
    margin-top: 30%;
  }
  .flex-vertical-card {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  .flex-horizontal-card {
    display: flex;
    flex: 1 0 auto;
  }
  .title-text {
    font-size: 27px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    padding-top: 10px;
    padding-bottom: 20px;
    color: #2b2c2f;
  }
  .body-text-form-label {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-transform: none;
    color: #95979e;
  }
  .body-text {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #95979e;
  }
  .logo_onboarding {
    height: 40px;
    float: right;
  }
  .lets-begin-text {
    margin-bottom: 48px;
  }
  .continue-button-div {
    text-align: center;
    right: 0;
    left: 0;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .continue-text-button-div {
    text-align: center;
    right: 0;
    left: 0;
  }
  .first-last-job-form {
    padding-top: 30px;
  }
  .input-box-only-underline {
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    color: #000000;
  }
  .name-box {
    width: 50%;
    display: inline-block;
    padding: 0 10px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .job-title-section {
    padding-top: 30px;
  }
  .job-title-box {
    width: 100%;
    display: inline-block;
  }
  .text-column {
    width: 50%;
    display: flex;
    padding-bottom: 90px;
    flex-direction: column;
  }
  .text-box {
    flex: 1 0 auto;
  }
  .graphic-column {
    width: 50%;
    display: inline-block;
    text-align: center;
    position: relative;
  }
  .overview-text-box {
    display: inline-block;
  }
  .nav-box {
    display: flex;
    flex: 0 0 auto;
    text-align: center;
  }

  .Privacy-Policy-Ter {
    width: 213px;
    height: 23px;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.64;
    letter-spacing: normal;
    text-align: center;
    color: #c0c0c0;
  }

  .privacy-terms-line {
    text-align: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
  }

  .skip-button {
    height: unset;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0;
    letter-spacing: 1.5px;
    text-align: right;
    border: none;
    box-shadow: none;
    padding: 0;
    color: $theme-button-secondary-color;
    i {
      margin-left: unset;
    }
  }
  .skip-span {
    position: absolute;
    bottom: 0;
    right: 25px;
  }

 .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $theme-button-secondary-color;
   border-color: $theme-button-secondary-color;
  }

  .spend-selection-subtitle {
    font-size: 16px;
    line-height: 1.38;
  }
}

.request-onboarding-tooltip-line {
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #c0c0c0;
  margin-top: 25px;
}

.request-dropdown-button {
  display: inline;

  .dropdown-list-div {
    margin-left: 0;
  }
}


.dropdown-button-div {
  text-align: center;
  margin-bottom: 1em;
  position: relative;
}

.dropdown-menu-div {
  list-style-type: none;
  z-index: 100;
  position: absolute;
  background-image: linear-gradient(to bottom, #fafeff, #ffffff);
  max-height: 304px;
  border: solid 0.9px $theme-color;
  overflow-y: auto;
  border-top: none;
  border-radius: 0;
  width: 98.5%;

  ul {
    list-style-type: none;
    font-size: 13px;
    line-height: 1.57;
    color: #2b2c2f;
  }

  li {
    line-height: 1.5;
  }
}

.dropdown-last-menu-div {
  list-style-type: none;
  background-image: linear-gradient(to bottom, #fafeff, #ffffff);
  max-height: 304px;
  border: solid 0.9px $theme-color;
  overflow-y: auto;
  border-top: none;
  border-radius: 0;
  width: 100%;

  ul {
    list-style-type: none;
    font-size: 13px;
    line-height: 1.57;
    color: #2b2c2f;
  }

  li {
    line-height: 1.5;
  }
}

.spend-category-selector {
  float: right;

  .ant-radio-wrapper {
    .ant-radio {
      .ant-radio-inner {
        border-color: darkgrey;

        &::after {
          background-color: $theme-color;
        }
      }
    }
  }
}

.category-name {
  float: left;
  cursor: pointer;
}

.no-border {
  border: none !important;
  &:hover {
    border: none !important;
  }
  &:focus {
    border: none !important;
  }
}

.full-width {
  width: 100% !important;
}

.width40 {
  width: 40%;
}

.width35 {
  width: 35%;
}

.width30 {
  width: 30% !important;
}

.width20 {
  width: 20%;
}

.width15 {
  width: 15%;
}

width10 {
  width: 10%;
}

.typography {
  .card-medium-text {
    text-align: center;
    font-size: 20px;
  }
  .card-large-text {
    text-align: center;
    font-size: 36px;
  }
}


.card-header {
  color: #000000;
  font-weight: 500;
}

.card-header-primary {
  background-color: $primary-color;
  color: #ffffff;
  font-weight: 500;
}

.card-header-secondary {
  background-color: GREEN;
  color: #ffffff;
  font-weight: 500;
}

.task-edit{
  .title-input {
    font-size: 36px;
    border-top: none;
    border-left: none;
    border-right: none;
    color: $theme-text-color;
    border-radius: 0;
    box-shadow: none;
    padding-left: 0;
    margin-bottom: 30px;
    font-weight: 200;
    height: 40px;

    &:focus {
      border-color: $theme-hover-color;
    }
    &:hover {
     border-color: $theme-hover-color;

    }
    &:active {
     border-color: $theme-hover-color;
    }
  }

  .task-description {
    box-shadow: none;
    font-weight: 600;

    &:focus {
      border-color: black;
    }
    &:hover {
      border-color: black!important;
    }
    &:active {
      border-color: black;
    }
  }
}

.rbc-calendar {
  min-height: 600px;
}

.warning-div {
  background-color: #efafac;
}

.buyerOnlyEvent {
  background-color: #2E8B57;

  // this is so that the agenda list view does not have colored background
  td {
    background-color: #ffffff;
  }
}

.buyerAndVendorEvent {
  background-color: #3174ad;

  // this is so that the agenda list view does not have colored background
  td {
    background-color: #ffffff;
  }
}

.legendBox {
  margin-top: 5px;
  margin-left: 15px;
  width: 15px;
  height: 15px;
  display: inline-block;
};

.calendarWithSpaceForSidebar {
  float: left;
  width: 70%;
}

.calendarSidebar {
  float: right;
  width: 28%;
  border: 1px solid #cccccc;
}

.primary-table {
  width: 100%;
  tr {
    height: 45px;
    min-height: 45px;
  }
  th {
    font-weight: 500;
    font-size: 12px;
    border-bottom: 1px solid #cccccc;
  }
  thead {
    th {
      border-bottom-width: 2px;
    }
  }
  td {
    border-bottom: 1px solid #cccccc;
    min-height: 20px;
  }
  input.line-checkbox {
    width: 20px;
    height: 20px;
  }

  .sno {
    margin-left: 10px;
  }

  .right,
  .right input
  {
    padding-right: 13px;
    text-align: right;
  }
}

.listing-table .ant-table-tbody td {
  word-break: normal;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  70%  { opacity:1; }
  90%  { opacity:0.2; }
  100% { opacity:1; }
}

@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}

@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}

@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}

.animate-flicker {
  -webkit-animation: flickerAnimation 3s infinite;
  -moz-animation: flickerAnimation 3s infinite;
  -o-animation: flickerAnimation 3s infinite;
  animation: flickerAnimation 3s infinite;
}

.full-width-modal {
  width: 80%;
  max-width: 1000px;
  padding: 30px;
}

.low-width-modal {
  width: 30%;
  max-width: 1000px;
}

.link {
  color: rgba(0, 0, 0, 0.65);
  text-decoration: none;
  &:hover {
    color: rgba(0, 0, 0, 0.65);
    text-decoration: underline;
  }
}

.error-form-item {
  background-color: $error-highlight
}

.file-load-arrows-container {
  font-size: 16px;
  line-height: 34px;
  vertical-align: middle;

  &.awarding {
    padding-right: 28px;
    margin-top: 19px;
  }

  .arrows-row {
    display: inline-flex;

    .arrow-circle {
      text-align: center;
      width: 34px;
      height: 34px;
      line-height: 32px;
      padding: 0 !important;
      border-radius: 25px;
      border: solid 1.5px rgba(63, 207, 193, 0.15);

      &:first-child {
        margin-right: 15px;
      }

      &:hover {
        background-color: $lightest-grey;
        cursor: pointer;
      }
    }
  }

  i {
    color: $theme-text-color;
  }
}

// Custom scrollbar
.standard-scrollbar {
  // Style the scrollbar
  &::-webkit-scrollbar {
    width: .6em;
    height: .6em;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #e8e8e8;
    outline: none;
    border-radius: 10px;
  }
}

.dropdown-menu-wrapper {
  display: inline-block;
  position: relative;
  top: 5px;
  padding-left: 5px;
  width: 323px;
  outline: none;

  .custom-dropdown-status {
    min-height: 27px;
    display: inline-block;
    border-bottom: 1px solid $theme-button-secondary-color;
    width: 100%;
    cursor: pointer;
  }

  .custom-dropdown-option {
    display: inline-block;
    padding-left: 5px;
  }

  .custom-icon {
    float: right;
    color: $theme-icon-color;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $theme-color;
    border-color: $theme-color;
  }
}

@-webkit-keyframes flash-bg {
  0% {   background: rgba(29, 147, 211, .3); }
  50% {  background: rgba(29, 147, 211, .15); }
  100% { background: rgba(29, 147, 211, 0); }
}

.flash-bg {
  animation-name: flash-bg;
  animation-duration: 650ms;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  -webkit-animation-name: flash-bg;
  -webkit-animation-duration: 650ms;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
}

.capitalized {
  text-transform: capitalize;
}
