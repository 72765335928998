body .str-chat {
  &.str-chat-channel {
    .rfu-dropzone {
      background: #F5F6FB;

      .str-chat__input {
        background: #F5F6FB;
        border: none;
        box-shadow: none;
        padding: 0 20px;

        .str-chat__textarea textarea {
          border: 2px solid #354052;
          border-radius: 5px;
        }
      }

      // Hide the footer, but in a way that let's us style the background and avoid a small horizontal grey line
      .str-chat__input-footer {
        background: #F5F6FB;
        padding: 0px 20px;
        opacity: 0;
        height: 0;
      }
    }
  }
}
