.clear-anchor-prices-wrapper {
  position: absolute;
  right: 57px;
  top: 1px;

  .clear-anchor-prices-icon {
    font-size: 38px;

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}
