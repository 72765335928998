#add-collaborators-from-db {
  .table-wrapper {
    margin-top: 20px;
    max-height: 600px;
    overflow-y: auto;
    padding-right: 10px;
  }

  .collaborators-add-from-db-table {
    table {
      width: auto !important;
    }
  }
}
