@use '../variables.scss';

#upload-csv-modal {
  .ant-comment {
    .ant-comment-avatar {
      min-width: 60px;
      font-weight: 500;
      padding-top: 10px;
      cursor: default;
    }

    .ant-comment-content-author {
      display: none;
    }
  }

  // Force width to 100% to make entire region clickable
  .react-file-reader {
    width: 100%;
    height: 100%;
  }

  .upload-input-container {
    border: 1px dashed $light-grey;
    padding: 0;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      border-color: $base-grey;
    }

    .add-file-button {
      padding: 10px 25px;
      width: 100%;
    }

    .file-display-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      padding: 10px 20px;

      .uploading-text {
        text-align: right;
        font-size: 13px;
        color: $medium-grey;
      }

      i {
        font-size: 18px;
        color: $medium-red;
      }
    }

    // Houses extended line item related information beyond the standard message and can handle a list view
    .line-item-error-messages {
      max-height: 300px;
      overflow-y: auto;
      border: 1px solid $light-grey;
      padding: 15px;
      margin: 15px;
      border-radius: 5px;

      table {
        width: 100%;

        th, td {
          text-align: left;
        }

        thead {
          color: $base-grey;
          border-bottom: 1px solid $light-grey;

          tr {
            th {
              font-size: 12px;

              &:last-of-type {
                text-align: right;
              }
            }
          }
        }

        tbody {
          tr {
            &:first-child {
              td {
                padding-top: 10px;
              }
            }

            td {
              vertical-align: top;
              color: $default-text;
              padding-bottom: 5px;
              font-size: 13px;

              &:first-child {
                min-width: 150px;
                width: 45%;
              }

              &:last-of-type {
                text-align: right;
              }
            }
          }
        }
      }
    }

    // Indicates a file has been attached
    &.has-file {
      cursor: auto;
      border-style: solid;
    }

    &.errors {
      border-color: $medium-red;
    }
  }

  .errors-container {
    margin-top: 5px;

    .error-item {
      color: $dark-red;
      font-size: 13px;
    }
  }

  .upload-message-container {
    h2 {
      display: flex; // corrects vertical alignment with image
    }

    img {
      margin-left: 20px;
    }

    p {
      color: $medium-grey;
      font-size: 18px;
      margin-bottom: 45px;
    }
  }
}
