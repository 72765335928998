@use '../variables.scss';

#bidding-event-status-details {
  .header {
    margin-bottom: 10px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;

    .status-container {
      color: $medium-grey;

      span {
        &.draft,
        &.closed,
        &.awarded {
          color: $theme-color;
        }

        &.open_for_questions,
        &.open_for_bidding {
          color: $green7;
        }

        &.ready_to_award {
          color: $blue6;
        }

        &.unawarded {
          color: $orange6;
        }
      }
    }

    .view-submissions-container {
      text-align: right;
      padding-right: 15px;

      a {
        color: $theme-link-color;
      }
    }
  }

  .body {
    position: relative;
    border: 1px solid $lighter-grey;
    padding: 20px 25px;
    border-radius: 6px;
    margin-bottom: 15px;

    &.awarded,
    &.closed {
      border-color: $theme-color;

      .body-content {
        margin-bottom: 15px;
      }
    }

    .body-content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      .column {
        text-align: center;

        .count {
          font-weight: 600;
          font-size: 42px;
          color: $theme-text-color;
          line-height: 42px;
        }

        .title {
          font-weight: normal;
          font-size: 14px;
          color: $medium-grey;
        }
      }
    }

    .action-btn {
      position: absolute;
      text-align: center;
      margin: 0 auto;
      bottom: -17px;
      background: $theme-button-secondary-color;
      color: white;
      border-radius: 20px;
      padding: 7px 25px;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      left: 50%;
      transform: translate(-50%);
      text-transform: uppercase;
    }
  }
}
