@use '../variables.scss';

$inputWidth: 200px;

.custom-item-header {
  .input-value-display {
    border: 1px solid $light-grey;
    padding: 0 10px;
    width: 100%;
    max-width: $inputWidth;
    cursor: text;
    color: $default-text;
    font-weight: 600;
    background: white;
    display: inline-flex;
    align-items: center;
    height: 30px;
    border-radius: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:focus {
      outline-color: $theme-hover-color;
    }

    &.disabled {
      background: #f5f5f5; // Color matches other standard disabled colors, hence not in variables
      cursor: not-allowed;
    }
  }

  strong {
    &.is-required {
      color: $dark-red;
      font-size: 14px;
      position: relative;
      top: 0;
    }
  }

  .header-item {
    .field-group {
      > div {
        &:last-child {
          display: flex; // adjust width of first input field row to ensure +/- buttons don't wrap
        }
      }
    }

    .access-control {
      &.disabled {
        color: $light-grey;
        cursor: not-allowed;
      }
    }
  }

  // Applies to styles within QuestionHeaderInput as well
  .field-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > div {
      &:last-child {
        flex-grow: 1;
        width: max-content;
      }
    }

    .label {
      flex-grow: 0;
      width: 120px;

      div {
        &:last-child {
          font-size: 11px;
        }
        &:first-child {
          font-size: 12px;
        }
      }
    }
  }
}

::placeholder {
  color: $medium-grey;
  opacity: 1;
}

.ant-popover.custom-item-header-popover {
  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      border: none;
      box-shadow: none;

      .ant-popover-inner-content {
        padding: 0;
        min-width: $inputWidth;
        max-width: $inputWidth + 50;

        // Question text input field
        textarea {
          padding: 5px;
          width: 100%;
          border: 1px solid $light-grey;
          min-height: 70px;
          max-height: 300px;
          font-weight: 600;
          font-size: 13px;

          &:focus {
            border-color: $theme-hover-color;
            outline: $theme-hover-color;
          }
        }
      }
    }
  }
}
