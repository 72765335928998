@use '../variables.scss';

$dividerOffset: 30px;

.round-detail-container {
  position: relative;

  &:not(.over-threshold) {
    overflow: hidden;
    flex: 1;
    justify-content: center;

    .round-detail {
      overflow: visible;
      position: relative;

      &:not(.is-over-and-final) {
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          margin-top: -1px;
          display: block;
          height: 1px;
          width: 9999px;
          height: 1px;
          left: 100%;
          right: calc($dividerOffset * -1);
          background: $medium-grey;
        }
      }
    }
  }

  // Indicates the last round
  &.true {
    flex: none;
    margin-left: 20px;
  }
}

.round-detail {
  white-space: nowrap;
  margin-right: calc($dividerOffset / 2);
  border-radius: 22px;
  padding: 10px 0px;
  border: 1px solid transparent;
  display: inline-flex;
  align-items: center;
  background: $lightest-grey;
  overflow: hidden;
  flex: 1;
  justify-content: center;

  // Indicates that we should show full details
  &.true {
    padding: 10px 20px;
  }

  &:last-of-type {
    margin-right: 0;
    flex: none;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    position: relative;

    &.number-only {
      width: 40px;
      border: 1px solid transparent;
      border-radius: 40px;
      height: 40px;
      vertical-align: middle;
      text-align: center;
      display: inline-flex;
      font-size: 16px;
      align-items: center;
      justify-content: center;
    }
  }

  .description {
    font-size: 13px;
  }

  .columns {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;

    .column {
      &.icon {
        margin-right: 10px;
        font-size: 26px;

        // Indicates that the event is over
        &.true {
          font-size: 20px;
        }
      }
    }
  }

  &.completed {
    color: $dark-grey;

    i {
      color: $dark-grey;
    }

    .title, .description {
      color: $dark-grey;
    }

    .title {
      &.number-only {
        width: 40px;
        border-radius: 40px;
        height: 40px;
        vertical-align: middle;
        text-align: center;
        display: inline-flex;
        font-size: 16px;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &.process,
  &.is-over-and-final {
    flex: none;
    color: $theme-text-color;
    background: $background-green;
    border-color: $background-border-green;

    .title, .description {
      color: $theme-text-color;
    }

    i {
      color: $theme-icon-color;
    }
  }

  &.wait {
    .title, .description {
      color: $base-grey;
    }

    .title {
      &.number-only {
        color: $base-grey;
        border-color: $base-grey;
      }
    }
  }
}
