// Bootstrap Replacements
body {
  font-size: 0.875rem;
  line-height: 1.5;

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,0.1);
  }
}
