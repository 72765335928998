@import './variables';

$loadingSize: 290px;
$marginSize: ($loadingSize / 2) * -1;

$mediumDelta: 60;
$mediumLength: $loadingSize - $mediumDelta;
$mediumMargin: $marginSize + ($mediumDelta / 2);

$smallDelta: 120;
$smallLength: $loadingSize - $smallDelta;
$smallMargin: $marginSize + ($smallDelta / 2);

#splash-screen {
  .wrapper {
    background-image: linear-gradient(155deg, #edf4ed, #c5e2ea);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;

    .loading-wrapper {
      width: $loadingSize;
      height: $loadingSize;
      top: 40%;
      left: 50%;
      margin-top: $marginSize;
      margin-left: $marginSize;
      position: absolute;
      background: white;
      border-radius: 10px;
      box-shadow: 0 5px 20px 3px rgba(95, 163, 178, 0.1);
      text-align: center;

      @media screen and (max-width: $mediumBreak) {
        width: $mediumLength;
        height: $mediumLength;
        margin-top: $mediumMargin;
        margin-left: $mediumMargin;
      }

      @media screen and (max-width: $smallBreak) {
        top: 30%;
        width: $smallLength;
        height: $smallLength;
        margin-top: $smallMargin;
        margin-left: $smallMargin;
      }

      .loading-indicator-container {
        display: table;
        width: 100%;
        height: 100%;

        $logoSize: 150px;

        .loading-indicator-cell {
          display: table-cell;
          vertical-align: middle;
          text-align: center;

          .loading-indicator-tag {
            width: $logoSize;
            height: $logoSize;

            @media screen and (max-width: $mediumBreak) {
              width: $logoSize - 10;
              height: $logoSize - 10;
            }

            @media screen and (max-width: $smallBreak) {
              width: $logoSize - 20;
              height: $logoSize - 20;
            }
          }
        }
      }
    }
  }
}
