.processing-container {
  text-align: center;

  // Indicates wrapping element styles should be turned on
  &.true {
    padding: 100px 0;
    border: 1px solid $medium-light-grey;
    border-radius: 5px;
  }

  .progress-three-quarter-circle {
    fill: none;
    stroke-dasharray: 110;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: $theme-icon-color;
    animation: rotation 2s infinite linear;
  }

  .spinner-container {
    position: relative;
    width: 112px;
    height: 112px;
    margin: 0 auto;
    margin-bottom: 20px;

    .processing-icon-container {
      position: absolute;
      display: flex;
      top: 0;
      justify-content: center;
      align-items: center;
      height: 112px;
      width: 112px;
      text-align: center;
      font-size: 40px;

      img {
        animation: rotation 2.5s infinite linear;
      }
    }
  }

  .title {
    color: $medium-dark-grey;
    font-size: 18px;
    font-weight: 600;
  }

  .subtitle {
    color: $base-grey;
    font-size: 14px;
    margin-top: 5px;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
}
