@use '../variables.scss';

$bulletSize: 10px;
$labelOffset: 30px;

.time-range-display {
  .time-container {
    position: relative;
    padding: {
      left: $labelOffset;
      top: 8px;
      bottom: 8px;
    }
    overflow: hidden;

    // Circle
    &:before {
      content: '';
      width: $bulletSize;
      height: $bulletSize;
      position: absolute;
      left: 0;
      background: $theme-icon-color;
      border-radius: $bulletSize;
      top: 50%;
      margin-top: calc(($bulletSize / 2) * -1);
    }

    // Line
    &:after {
      content: '';
      width: 0px;
      height: 100%;
      top: 50%;
      left: 4px;
      margin-top: -1px;
      display: block;
      position: absolute;
      border: 1px solid $theme-icon-color;
    }

    &.to {
      &:before {
        background: $light-grey;
      }

      &:after {
        border-color: $light-grey;
        border-style: dashed;
        height: 50%;
        top: 0;
      }
    }

    // Indicates the time range display does not have valid time to display
    &.false {
      &:before {
        background: $light-grey;
      }

      &:after {
        border-color: $light-grey;
        border-style: dashed;
      }
    }

    .label {
      display: inline-block;
      color: $medium-grey;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      width: 70px;
    }

    .time-display {
      display: inline-block;
      color: $default-text;
      font-weight: 600;
      font-size: 18px;
    }
  }

  &.sm {
    .label {
      font-size: 12px;
      width: 50px;
    }

    .time-display {
      font-size: 14px;
    }
  }
}
