.engagement-container {
  font-size: 14px;
  font-weight: 600;
  color: $base-grey;
}

.average-score {
  font-size: 20px;
  font-weight: 600;
  color: $base-grey;

  &.green {
    color: $medium-green;
  }

  &.yellow {
    color: $medium-yellow;
  }

  &.orange {
    color: $medium-orange;
  }

  &.red {
    color: $medium-red;
  }

  .bar-container {
    border: 1px solid $lighter-grey;
    height: 11px;
    display: inline-block;
    border-radius: 10px;
    line-height: 10px;
    margin-top: 11px;

    .outer-bar {
      display: inline-block;
      height: 10px;
      margin-bottom: 11px;
      border-radius: 0;

      &.green {
        background-color: rgba(53, 138, 97, 0.3); // #medium-green with opacity 0.3
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        margin-left: 4px;
      }

      &.yellow {
        background-color: rgba(203, 171, 0, 0.3); // $medium-yellow with opacity 0.3
        margin-left: 4px;
      }

      &.orange {
        background-color: rgba(212, 112, 0, 0.3); // $medium-orange with opacity 0.3
        margin-left: 4px;
      }

      &.red {
        background-color: rgba(205, 65, 65, 0.3); // $medium-red with opacity 0.3
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
      }

      .inner-bar {
        height: 9px;

        &.green {
          background-image: linear-gradient(to bottom, #3cb178 14%, #358a61 82%);
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
        }

        &.yellow {
          background-image: linear-gradient(to bottom, #d8b600 19%, #c1a300 81%);
        }

        &.orange {
          background-image: linear-gradient(to bottom, #dd7500 33%, #c76900 80%);
        }

        &.red {
          background-image: linear-gradient(to bottom, #d00000 23%, #b80000 77%);
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
        }
      }
    }
  }
}

table.request-participation-table {
  width: 100%;
  font-size: 14px;

  thead {
    tr {
      th {
        font-size: 14px;
        text-align: center;
        font-weight: 600;
        color: $base-grey;
      }
    }
  }

  tr {
    td {
      font-size: 14px;
      border: 1px solid $light-grey;
      text-align: center;
      color: #6f7175;
      font-weight: 600;

      &:first-of-type {
        max-width: 130px;
        color: $base-grey;
        text-align: left;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
      }

      &.positive { color: #358a61; }
      &.negative { color: #b80707 }
    }
  }

  .ant-comment {
    .ant-comment-inner {
      padding: 12px 5px 12px 0;
    }

    .ant-comment-avatar {
      i {
        vertical-align: -.3em;
      }
    }
  }
}
