#scrollable-div{
  height: 50vh;
}
#user-requests-management {
  .table-wrapper {
    margin-top: 20px;
    max-height: 600px;
    overflow-y: auto;
    padding-right: 10px;
  }

  .requests-select-users-permissions-table {
    table {
      width: auto !important;

      .add-checkbox {
        text-align: right;
      }
    }
  }
}
