.infinite-table {
  position: relative;
  min-height: 200px;

  .loading-display-container {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: rgba(255, 255, 255, .6);
    z-index: 2;
    padding-top: 40px;
  }
}
