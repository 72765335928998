.zero-state-add-new-display {
  text-align: center;
  margin-top: 50px;
  padding-bottom: 100px;

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;

    button {
      &:not(:last-of-type) {
        margin-right: 15px
      }
    }
  }

  h2 {
    font-size: 21px;
  }

  .subtitle {
    margin-top: 20px;

    h3 {
      font-size: 18px;
      display: inline-block;
    }

    margin-bottom: 0px;
  }

  p {
    font-size: 14px;
    color: $medium-grey;
  }
}
