@import '@styles/variables.scss';

.group {
  .header {
    box-sizing: border-box;
    background-color: rgba(245, 245, 245, 1); // #F5F5F5
    border-top: 1px solid $colorBorderSecondary;
    border-bottom: 1px solid $colorBorderSecondary;
    padding: 8px 12px;

    display: flex;
    justify-content: space-between;

    .title {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      padding: 0;
      margin: 0;
      color: $colorTextSecondary;
    }

    .count {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      padding: 0;
      margin: 0;
      color: $colorTextSecondary;
    }
  }
}
