.contact-card {
  color: $base-grey;
  font-size: 16px;
  padding-right: 20px;

  .email {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .company-avatar {
    width: 139;
    height: 139;
  }

  .heading {
    color: $dark-grey;
    font-size: 24px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .domain-link {
    color: $theme-link-color;
    font-weight: 600;
  }

  .phone-number {
    color: $theme-text-color;
    font-weight: 600;
    text-decoration: underline;
  }

  a.company-name {
    font-weight: 600;
    color: $theme-text-color;
    text-decoration: underline;
  }

  .emphasis {
    font-weight: 600;
  }

  .ant-comment {
    .ant-comment-inner {
      padding-top: 0;
    }

    .ant-comment-avatar i {
      vertical-align: -.3em;
    }
  }
}
