.custom-line-items-table-container {
  .action-buttons-container {
    margin-top: 30px;
    text-align: center;
    padding: 40px;
    border: 1px solid $light-grey;
    border-radius: 5px;

    i {
      font-size: 30px;
      color: $light-grey;
    }

    .add-new-btn {
      color: $theme-button-secondary-color;
      font-weight: 600;
    }
  }

  table {
    tr {
      td {
        &.action-buttons {
          text-align: right;
        }
      }
    }
  }

  .importing {
    padding: 8px;
  }
}
