#company-settings {
  background: $colorBgContainer;
  border: 1px solid $light-grey;
  border-radius: 5px;
  padding: $sizeXl 0 $sizeLg;
  margin: $sizeLg;

  h1 {
    padding: 0 $sizeLg;
  }

  .ads-tabs-content-holder {
    padding: $sizeLg;

    .ads-btn-icon-only {
      margin: 0 $sizeXxs;
    }
  }

}
