@use '../variables.scss';

.auth-bidder-text {
  font-weight: 600;
  color: $dark-grey;
  font-size: 14px;
  padding-left: 38px;
}

.org-name-text {
  font-weight: 600;
  font-size: 20px;
  color: $dark-grey;
  line-height: 1.5;
}

.insight-box {
  margin-left: 10px;
  height: 75px;
  border: 1px solid #ddd;
  border-radius: 5px
}

.insight-label {
  padding: 14px 20px 0 20px;
  font-size: 14px;
  color: $base-grey;
}

.insight-content {
  max-height: 22px;
  line-height: 22px;
  padding: 0 20px 0 20px;
  font-size: 14px;
  color: $medium-grey;
}

.total-bid-price-container {
  &.positive { color: $dark-green; }
  &.negative { color: $base-red; }
}

.no-submission {
  font-size: 14px;
}

.engagement-score {
  &.green { color: $medium-green; }
  &.yellow { color: $medium-yellow; }
  &.orange { color: $medium-orange; }
  &.red { color: $medium-red; }
}

.engagement-dot {
  border-radius: 100%;
  border: solid;
  display: inline-block;
  margin-right: 5px;

  &.green { color: $medium-green; background-color: $medium-green; }
  &.yellow { color: $medium-yellow; background-color: $medium-yellow;}
  &.orange { color: $medium-orange; background-color: $medium-orange; }
  &.red { color: $medium-red; background-color: $medium-red;}

  &.big {
    width: 8px;
    height: 8px;
    margin-bottom: 1px;
  }

  &.small {
    width: 6px;
    height: 6px;
    opacity: 0.3;
    margin-bottom: 2px;
  }
}

.engagement-bar {
  height: 2px;
  width: 28px;
  border-radius: 0;
  border: 1px;
  background-color: $lightest-grey;

  .inner-bar {
    height: 2px;
    &.green { background-color: $medium-green; }
    &.yellow { background-color: $medium-yellow; }
    &.orange { background-color: $medium-orange; }
    &.red { background-color: $medium-red; }
  }
}

.clear-fix {
  clear: both;
}
