@use '../variables.scss';

#section-weighting-modal {
  h2 {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .footer {
    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid $light-grey;
      margin-bottom: 20px;

      .col {
        font-size: 14px;
        font-weight: 600;

        &.right {
          color: $base-grey;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
