.lot-panel-header {
  .panel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previous-rounds-text {
      font-size: 12px;
      line-height: 14px;
      color: $medium-light-grey;
    }

    .panel-col {
      display: flex;
      align-items: center;

      &.right {
        justify-content: flex-end;

        .amount-display {
          margin-right: 15px;
        }
      }

      .ranks-container {
        display: flex;
        border-right: 1px solid $light-grey;
        margin-right: 15px;
        white-space: nowrap;

        > * {
          margin-right: 10px;

          &:last-child {
            margin-right: 15px;
          }
        }
      }
    }
  }
}
