$borderRadius: 4px;

.question-header-input-container {
  font-size: 12px;
  font-weight: 500;
  padding: 0;
  margin-top: 4px;

  .input-value-display {
    border-right: 1px solid $light-grey;
    padding: 15px;
    width: 100%;
    max-width: 250px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    cursor: text;
    color: $default-text;
    font-weight: 600;
  }

  .question-header-select {
    padding: 0;
    font-size: 12px;
    display: block;

    &.answer-options {
      border: 1px solid $light-grey;
      border-radius: $borderRadius;
    }

    .ant-select-selection {
      border: none;
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 10px;

      .ant-select-selection__rendered {
        margin-right: 34px;

        .ant-select-selection-selected-value {
          font-weight: 600;
          color: $default-text;
        }
      }
    }
  }

  .question-header-answer-option-field-container {
    margin-top: 4px;
  }

  .question-header-is-required-field-container {
    margin-top: 4px;
  }
}

// Dropdown Menu for the Question Type Select tag
.ant-select-dropdown.question-header-select-dropdown {
  min-width: 170px; // Needs to be long enough to fit select options (e.g. "Multiple Choice")

  ul.ant-select-dropdown-menu {
    padding: 15px;

    li.ant-select-dropdown-menu-item {
      color: $medium-grey;
      border-radius: 4px;
      border: 2px solid transparent;

      &:not(:last-child) {
        margin-bottom: 2px;
      }

      // Hover State
      &.ant-select-dropdown-menu-item-active {
        border-color: $theme-hover-color;
        background: white;
      }

      // Currently Selected Value
      &.ant-select-dropdown-menu-item-selected {
        color: white;
        background: $theme-hover-color;
        border-color: $theme-hover-color;
        font-weight: 400;
      }

      // Disabled
      &.ant-select-dropdown-menu-item-disabled {
        color: $light-grey;
      }
    }
  }
}
