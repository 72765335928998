.sliding-drawer {
  .drawer-action-buttons {
    .drawer-confirm {
      width: 50%;
    }

    .drawer-cancel {
      width: 50%;
    }
  }
}
