@use '../variables.scss';

#lots-awarding-table {
  &.ant-table {
    border: 1px solid #e5e9ed;
    border-radius: 5px;

    .ant-table-thead {
      tr {
        th {
          border: 1px solid #e8e8e8;
          border-bottom-color: transparent;
          text-align: center;
        }
      }
    }

    // General styles for both fixed and scroll table
    .ant-table-tbody {
      tr {
        td {
          font-size: 13px;
          border: 1px solid #d3d6d9;
          border-left-color: transparent;
          border-top-color: transparent;
          text-align: center;
        }

        // First Column
        td:first-child {
          font-weight: 600;
          text-align: left;
        }
      }
    }

    // Scroll position classes appear when scrolling begins and handle the fixed column position/layering
    .ant-table-scroll-position-left {

    }

    .ant-table-scroll-position-middle {

    }

    // First Column that is fixed during scroll and only contains first column
    // Column visibility and layering changes as table is scrolled. There are essentially 2 "first" columns
    // but during scrolling, this fixed column is affixed over the original "first" column.
    .ant-table-fixed-left {
      border-right: 2px solid #989aa2;

      table.ant-table-fixed {
        .ant-table-thead {
          tr {
            th {
              border-top-color: transparent;
            }
          }

          tr:nth-of-type(2) {
            th {
              border-bottom-color: #e8e8e8;
            }
          }
        }

        .ant-table-tbody {
          // Selected Scenario Row
          tr:nth-last-child(1) {
            td {
              text-align: center;
              background:$theme-hover-color;
              color: white;
              padding: 24px 16px;
              border-color:$theme-hover-color;
            }
          }
        }
      }
    }

    // Standard table with all columns
    .ant-table-scroll {
      border-radius: 5px;

      .ant-table-thead {
        tr {
          th  {
            // Indicates first column within a Lot section
            &.first-lot-column {
              border-left-color: #d3d6d9 !important; // Using !important to force first lot styles
            }
          }
        }

        // First grouped row of the table
        tr:first-of-type {
          th {
            background: #e8eaec;
            font-weight: 600;
            font-size: 14px;
          }

          th:nth-child(1),
          th:nth-child(2) {
            background: #fafafa;
          }
        }

        tr:nth-of-type(2) {
          th {
            font-weight: 500;
            color: #6C6F78;
            border-left-color: transparent;
            border-bottom-color: #e8e8e8;
          }
        }
      }

      .ant-table-tbody {
        tr {
          td {
            // Indicates first column within a Lot section
            &.first-lot-column {
              border-left-color: #d3d6d9;
            }

            &.selection-cell {
              padding: 0;
              border-left: none; // need to handle border for sizing in child class below
              border-top: none;
              border-left-color: #F6F6F6;
              border-top-color: #F6F6F6;

              &:focus {
                outline: none;
              }

              .selection-container {
                cursor: pointer;
                border-left: 1px solid #F6F6F6;
                border-top: 1px solid #F6F6F6;
                padding: 16px;
                background: #F6F6F6;
                color: #7e7e80;

                &.no-bid {
                  cursor: not-allowed;
                }

                &.disabled {
                  cursor: default;
                }

                // Scenario finalized, but not selected cell
                &.finalized {
                  cursor: default;
                }

                &.selected {
                  color: white;
                  background:$theme-hover-color;
                  border-left-color: $theme-hover-color;
                  border-top-color: $theme-hover-color;
                  text-transform: uppercase;
                  font-weight: 500;

                  // Scenario finalized and the selected cell
                  &.finalized {
                    color: white;
                    background: #2b2c2f;
                    border-left-color: #2b2c2f;
                    border-top-color: #2b2c2f;
                    font-weight: 500;
                    text-transform: none;
                  }
                }

                &:focus {
                  outline: none;
                }
              }
            }
          }
        }

        // Selected Scenario Row
        tr:nth-last-child(1) {
          td {
            background: #e8eaec;
            padding: 24px 16px;
            border-bottom-color: transparent;
            font-weight: 600;
          }

          // First column
          td:first-child {
            text-align: center;
            background: $theme-button-secondary-color;
            color: white;
            padding: 24px 16px;
            border-color: $theme-button-secondary-color;
          }
        }
      }
    }
  }

  .item-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 300px;

    .color-indicator {
      display: inline-block;
      width: 15px;
      height: 15px;
      border-radius: 15px;
      margin-right: 15px;
    }

    .text {
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      text-overflow: ellipsis;
      max-width: 255px;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

// Special styles when there is a baseline for the request
.has-baseline {
  #lots-awarding-table {
    &.ant-table {
      table.ant-table-fixed {
        .ant-table-tbody {
          // Baseline / Budget Row
          tr:nth-last-child(2) {
            td {
              text-align: center;
              background: #fcfcfc;
              color: $theme-text-color;
              padding: 20px 16px;
            }
          }
        }
      }

      .ant-table-scroll {
        // Baseline / Budget Row
        tr:nth-last-child(2) {
          td {
            background: #fcfcfc;
            padding: 20px 16px;
          }
        }
      }
    }
  }
}
