@import '../../../styles/variables.scss';

.wrapper {
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
  text-align: center;
  white-space: nowrap;
}

.policiesContainer {
  a, .divider {
    color: $base-grey;
    font-size: 14px;
  }

  .divider {
    margin-left: 6px;
    margin-right: 6px;
  }
}
