#request-details-header {
  .view-bid-btn-container {
    margin-top: 32px;
    margin-bottom: 64px;
    text-align: right;

    @media screen and (max-width: $largeBreak) {
      text-align: center;
    }
  }
}
