.create-survey-container {
  padding: 100px 20px;
  text-align: center;

  .title-container {
    margin: 10px 0px;

    .title {
      font-size: 14px;
      color: $base-grey;
      font-weight: 500;
      display: inline-block;
    }

    .title-tooltip {
      display: inline-block;
    }
  }

  .button {
    margin-top: 30px;
  }
}
