#general-dashboard-info-block {
  section {
    margin-bottom: 25px;

    &:first-of-type {
      margin-bottom: 10px;
    }
  }

  .company-details {
    display: flex;
    margin-top: 12px;
    margin-bottom: 40px;

    .company-info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .company-name {
        font-size: 25px;
        color: #242528;
        font-weight: 600;
      }

      .company-site {
        font-size: 18px;
        color: #a4a5ab;
        font-weight: 600;

        .company-site-link { color: #a4a5ab; }
      }
    }

    .company-logo {
      margin-right: 20px;
      img {
        width: 84px;
        height: 84px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  .title {
    font-size: 25px;
    font-weight: 600;
    color: #242528;
  }

  .dates-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .arrow {
      // Indicates Open for Submission is active state
      &.open_for_bidding {
        margin-left: 15px;
      }

      &.closed {
        margin-right: 15px;
      }
    }
  }

  .description {
    font-size: 16px;
    font-weight: 600;
    white-space: pre-wrap;
  }

  .buyer-currency-container {
    display: flex;
    justify-content: flex-start;

    .column {
      margin-right: 40px;

      &.buyer-org {
        .company-avatar {
          width: 42px;
          margin-right: 15px;
        }

        span {
          font-size: 18px;
          font-weight: 600;
        }

        .buyer-org-container {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
