#sidekiq-page {
  width: 100%;
  height: 100%;

  .sign-in-text {
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    margin-top: 30px;
  }
}
