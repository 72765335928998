.documents-dashboard-info-block {
  color: $dark-grey;

  .submit-row {
    text-align: center;
    margin-top: 15px;

    .submit-btn {
      color: $theme-button-secondary-color;
      font-weight: 600;
      font-size: 15px;
    }
  }
}
