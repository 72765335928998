.character-limit-field {
  width: max-content;
  min-width: 150px;

  input {
    text-align: left;
  }
  // Indicates this is disabled
  &.true {
    opacity: .2;
    pointer-events: none;
  }
}
