@use '../variables.scss';

.countdown-clock-display {
  .time-container {
    display: inline-block;
    position: relative;
    height: 120px;
    width: min(calc(25% - 1rem), 100px);

    &:not(:last-child) {
      margin-right: 20px;

      // colon divider
      &:after {
        content: ':';
        font-size: 50px;
        position: absolute;
        right: -15px;
        top: 56px;
        line-height: 0;
        color: $light-grey;
      }
    }

    .time-display {
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #FAFAFA;
      width: 100%;
      border: 1px solid #F0F0F0;
      border-radius: 7px;
      color: #000;
      font-size: 42px;
      line-height: 0;
    }

    .label {
      font-size: 14px;
      color: $medium-grey;
      font-weight: 500;
      text-align: center;
      margin-top: 15px;

      div {
        display: inline-flex;
      }
    }

    @media screen and (max-width: $xlBreak) {
      height: 60px;
      width: 50px;

      .time-display {
        font-size: 20px;
      }

      .label {
        font-size: 12px;
        font-weight: 300;

        div {
          display: inline-flex;
        }
      }

      &:not(:last-child) {
        // colon divider
        &:after {
          content: ':';
          font-size: 30px;
          top: 26px;
          right: -13px;
        }
      }
    }
  }

  &.zero-state {
    .time-container {
      .time-display {
       background-color: #FAFAFA;
        color: #000;
        border-color: #e8e6e6;
      }
    }
  }
}
