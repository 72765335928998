.view-as-vendor-wrapper {
  position: absolute;
  right: 10px;
  top: 1px;

  .view-as-vendor-icon {
    opacity: 1;
    color: #2b2c2f;
    font-size: 37px;
  }
}
