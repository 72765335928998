.chat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .group-header {
    padding: 24px 25px 6px;
    font-size: 14px;
    color: $black;
    border-bottom: 1px solid #e5e9ed;

    &:not(:first-of-type) {
      background: $off-white;
    }
  }

  @media all and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }

  .error-boundary {
    margin: 15px 15px;
    width: 100%;
  }
  .str-chat__channel-list-empty{
    font-size: 14px;
  }
  .str-chat__channel-list .str-chat__channel-list-messenger .str-chat__channel-list-messenger__main .str-chat__channel-list-empty svg{
    width: 50px;
    height: 50px;
  }
}
