@use '../variables.scss';

.open-text-question-body-container {
  .preview-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .preview-container {
      flex-grow: 2;
      margin-right: 15px;

      // Ensure we don't show a "disallowed" cursor
      input {
        cursor: default;
      }

      // Styles need overriding here as it's overriding inline component style
      button {
        background: $light-grey !important;
        border-color: $light-grey !important;
        color: $medium-dark-grey !important;
      }
    }

    .extra {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .character-limit-container {
      width: 220px;
      position: relative;

      .input-overlay {
        position: absolute;
        right: 15px;
        font-size: 12px;
        color: $base-grey;
        bottom: 10px;
        z-index: 1;
      }
    }

    input {
      height: 40px; // keeps label alignment between siblings
      font-size: 14px;
      border-color: $light-grey;
    }
  }

  // Indicates in preview mode
  &.true {
    .input-container {
      // Hand cursor when in preview mode to indicate clickability
      input {
        cursor: pointer;
      }
    }
  }
}
