@use '../variables.scss';

$columnPadding: 10px;
$questionEditingColumnWidth: 320px;

.line-item-pre-qualification-table-container {
  width: 100%;
  border: 1px solid $light-grey;
  border-radius: 5px;
  padding: 0 15px 10px;

  .standard-scrollbar {
    overflow-x: auto;
  }

  .line-item-pre-qualification-table {
    width: 100%;
    border: none;

    th {
      padding: 10px 0 10px $columnPadding;
    }

    td {
      padding: 3px 0 3px $columnPadding;
    }

    th, td {
      &.column {
        &.number {
          min-width: 50px;
        }

        &.name {
          width: 100%;
        }

        &.custom {
          min-width: 150px;
        }

        &.question {
          width: $questionEditingColumnWidth;
          min-width: $questionEditingColumnWidth;
        }
      }
    }

    tbody {
      tr {
        &:first-child {
          td {
            padding-top: 10px;
          }
        }
      }
    }
  }

  // All tables except first table which would have the edit controls for headers
  .pre-qualification-lot {
    &:not(:first-child) {
      thead {
        th {
          &.question {
            text-overflow: ellipsis;
            max-width: calc($questionEditingColumnWidth / 2);
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }

  .load-more-line-items-container {
    text-align: center;
    margin-top: 10px;
  }
}
