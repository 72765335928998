.warning {
  border-radius: 0;
  letter-spacing: 1.6px;
  font-size: 12px !important;
  font-weight: 600 !important;
  overflow: hidden;
  left: 0;
  right: 0;
  top: 0;
  text-transform: uppercase;
  z-index: 100;

  .fixed {
    position: fixed;
  }
}
