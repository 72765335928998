.request-nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  background: #999999;
  padding: 8px 20px;

  .request-nav-button {
    padding: 5px 10px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    border: 1px solid white;
    border-radius: 4px;
    display: flex;
    background-color: transparent;
    white-space: nowrap;
    gap: 5px;
    align-items: center;
    justify-content: flex-start;
    height: 30px;
    cursor: pointer;

    .request-nav-label {
      display: inline-block;
    }

    @media all and (min-width: $xsmallBreak) and (max-width: $smallBreak) {
      .request-nav-label {
        display: inline-block;
      }
    }

    @media all and (max-width: $xsmallBreak) {
      .request-nav-label {
        display: none;
      }
    }
  }

  .mobile-only {
    @media all and (min-width: 960px) {
      display: none;
    }
  }
}
