#submissions {
  .subtabs-row {
    // This extra span wrapper is a necessary hack for tooltip triggering behavior to function as expected
    // when the wrapped <button> is disabled.
    span {
      button {
        &.ant-btn-disabled,
        &.ant-btn[disabled] {
          pointer-events: none;
        }
      }
    }
  }
}
