@use '../variables.scss';

.multiple-choice-question-body-container {
  .answer-choices-container {
    margin-bottom: 5px;
  }

  .add-container {
    color: $theme-text-color;
    margin-left: 15px;
  }
}
