.answer-option-field {
  &.text {
    min-width: 150px;

    input {
      text-align: left;
    }

    .ant-input-group-addon {
      padding: 0;
      height: 32px;
      font-weight: 600;
    }

    .qualifying-answer-container {
      color: $base-grey;
      font-size: 14px;
      font-weight: 600;
      margin-top: 5px;

      i {
        color: $bright-yellow;
        margin-right: 10px;
        line-height: 24px;
      }
    }
  }
}
