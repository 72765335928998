$size: 6px;

.addresses-display {
  padding: 15px 15px 10px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;

  .ant-carousel {
    padding: 0 15px;

    ul.slick-dots {
      li {
        background: #dedede;
        width: $size;
        height: $size;
        border-radius: $size;

        button {
          background: #dedede;
          width: $size;
          height: $size;
          border-radius: $size;
        }

        &.slick-active {
          background: $theme-hover-color;

          button {
            background: $theme-hover-color;
            width: $size;
            height: $size;
          }
        }
      }
    }

    .slick-slider {
      &.slick-initialized {
        padding-bottom: 35px;

        .slick-arrow {
          color: $theme-color;
          font-size: 14px;

          &:before, &:after {
            content: '' // removes default slick arrow chars
          }

          &.slick-prev {

          }

          &.slick-next {

          }
        }
      }
    }
  }
}
