@use '../variables.scss';

.line-item-preview-collapse {
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-content-active {
        overflow-x: auto;
      }
    }
  }

  .table-container {
    overflow-x: auto;
  }

  th, td {
    min-width: 100px;
  }

  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .line-item-count {
      text-align: right;
      font-size: 13px;
      color: $base-grey;
      padding: 5px;
    }
  }

  .load-more-line-items-container {
    text-align: center;
    padding-top: 20px;
  }

  .green {
    color: $medium-green;
  }

  .red {
    color: $medium-red;
  }

  ins.green {
    text-decoration: none;
  }
}
