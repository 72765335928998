#scenario-creation-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  .selects-container {
    .customized-select {
      &:first-of-type {
        margin-right: 20px;
      }
    }
  }
}
