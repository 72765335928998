#main {
  background: $lightest-grey;
  display: flex;
  height: 100%;
  width: 100%;

  #survey-review {
    width: 100%;

    .header {
      background: white;
      padding: 15px 25px;
      border-bottom: 1px solid #ccc;
      color: lightgrey;

      .logo-row {
        border-bottom: 1px solid $light-grey;
        padding-bottom: 20px;
      }

      .user-avatar-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $medium-grey;

        .info-container {
          font-weight: 300;
          text-align: right;
          margin-right: 20px;
          line-height: 16px;

          .email {
            font-weight: 600;
          }
        }

        .avatar {
          border-radius: 45px;
          width: 45px;
          height: 45px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          line-height: 24px;
          font-size: 24px;
          background: $light-grey;
          color: $medium-grey;
          font-weight: 500;
        }
      }

      .subheader-container {
        color: $default-text;
        font-size: 14px;
        padding-top: 30px;

        .request-id {
          color: $medium-grey;
        }

        .name {
          font-size: 26px;
          font-weight: 500;
          margin-bottom: 5px;
        }
      }
    }

    .content {
      margin: 0 auto;
      padding: 30px 20px 20px;

      .content-body {
        background: white;
        border-radius: 5px;
        padding: 25px;
        border: 1px solid $base-grey;

        .table-info-container {
          font-size: 20px;
          margin-bottom: 15px;
          font-weight: 600;
        }

        .table-container {

        }
      }
    }
  }
}
