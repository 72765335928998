#team-status-bar {
  background: white;
  border: 1px solid #c9cdd1;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;

  .org-display {
    text-align: right;

    @media screen and (max-width: 992px) {
      text-align: left;
    }
  }

  .title {
    font-size: 13px;
    color: $medium-light-grey;
    font-weight: 500;
    text-transform: uppercase;
  }

  .subtitle {
    font-size: 22px;
    font-weight: 600;
    color: $dark-grey;

    &.org {
      line-height: 120%;

      @media screen and (min-width: 991px) {
        font-size: 16px;
      }

      @media screen and (min-width: 1599px) {
        font-size: 22px;
      }
    }

    &.fraction {
      color: $dark-green;

      &.warning {
        color: $medium-yellow;
      }

      &.bad {
        color: $medium-orange;
      }

      &.danger {
        color: $medium-red;
      }
    }
  }

  .plan-type-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .plan-container {
      border-radius: 22px;
      padding: 10px 20px;
      background: white;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $light-grey;

      .plan-name {
        color: $theme-text-color;
        font-weight: 600;
      }
    }
  }

  .statuses-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .status-container {
      border-radius: 22px;
      padding: 10px 20px;
      background: $lightest-grey;
      font-weight: 600;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .active {
        color: $dark-green;
      }

      .pending {
        color: $medium-yellow;
      }

      .divider {
        margin-left: 15px;
        margin-right: 15px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background: $light-grey;
      }
    }
  }

  .org-row {
    border-right: 1px solid $light-grey;
    height: 100%;

    @media screen and (max-width: 992px) {
      border-color: transparent;
    }
  }
}
