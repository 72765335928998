@use '../variables.scss';

#bid-submission {
  .upload-documents-bid-btn {
    background: $bright-yellow !important;
    border-color: $bright-yellow !important;
  }

  .search-container {
    padding-top: 25px;
    padding-bottom: 10px;
    border-bottom: 1px solid $lighter-grey;
  }

  .top-bar-button-row {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 10px;

    &.show-pricing {
      justify-content: space-between;
    }

    .icon-button {
      margin-bottom: 10px;
    }

    .col {
      > * {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }

      &.left {
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        margin-right: 20px;
        align-items: center;
        justify-content: flex-start;

        @media screen and (min-width: $mediumBreak) {
          max-width: 50%;
        }

        @media screen and (min-width: $largeBreak) {
          max-width: 60%;
        }

        @media screen and (min-width: $xlBreak) {
          max-width: 70%;
        }

        @media screen and (min-width: $xxlBreak) {
          max-width: 85%;
        }
      }

      &.right {
        text-align: right;
      }
    }
  }

  .apply-discount-container {
    background: $background-green;
    border-radius: 4px;
    padding: 10px;
    color: $default-text;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 30px;

    input {
      height: 30px;
    }

    > div {
      &:first-child {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        > span {
          margin-right: 10px;
          white-space: nowrap;
        }
      }

      &:last-child {
        text-align: right;

        button {
          height: 30px;

          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .lots-list-info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $medium-dark-grey;
    margin-bottom: 5px;

    > div:last-of-type {
      text-align: right;
      padding-right: 20px;
    }

    .my-awarded-items-container {
      display: flex;
      align-items: center;
      justify-content: baseline;
      gap: 16px;

      .my-awarded-items-content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        label {
          margin: 0px;
          font-size: 14px;
          color: $default-text;
        }
      }
    }
  }

  .ant-collapse {
    background: transparent;
    border: none;

    // We set the background here to change the Lot Header color, and we force the background of the collapse
    // body to white.
    .ant-collapse-item {
      background: $lightest-grey;
      border: 1px solid $light-grey;
      border-radius: 5px;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }

      &.ant-collapse-item-disabled {
        .ant-collapse-content {
          border-color: transparent;

          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
    }
  }

  &.no-content-container {
    padding-top: 20px;

    strong {
      font-size: 20px;
    }

    > div {
      margin-top: 15px;
    }
  }

  button {
    &.save-draft-bid {
      margin-right: 15px;
    }
  }

  .ant-affix {
    height: auto !important;
    bottom: 0 !important;
    background-color: $white;

    .bid-submit-block {
      box-shadow: 0 -10px 10px -10px $light-grey;
    }
  }
  .footer-container{
    margin-top: 100px;
    position: relative;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    flex-direction: row;
    align-items: center;

    i.check-icon {
      display: inline-block;
      position: absolute;
      color: $theme-icon-color-inverted;
      font-size: 20px;
      margin-left: 15px;
      margin-top: 30px;
      vertical-align: middle;
      &.true{
        top: 10%;
      }
    }
    .footer-icon{
      position: absolute;
      top: 25%;
      width: 34px;
      height: 34px;
      margin-bottom: 10px;
      &.true{
        top: 10%;
      }
    }
  }
}
