.custom-categories-table-container {
  .action-buttons-container {
    text-align: center;

    .add-new-btn {
      padding: 10px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      border: 1px solid $light-grey;
      background: $lightest-grey;
      cursor: pointer;
      border-radius: 5px;
      color: $medium-light-grey;

      &:hover {
        border-color: $medium-dark-grey;
        color: $medium-dark-grey;
      }
    }
  }

  table {
    tr {
      td {
        &.action-buttons {
          text-align: right;
        }
      }
    }
  }
}
