$menuWidth: 30%;

// Menu width set in component
#channel-settings-menu {
  background: white;
  border-left: 1px solid #e5e9ed;
  min-height: 100vh;
  height: 100%;

  &.floating {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    box-shadow: 0px 10px 10px 4px #ccc;
    z-index: 1;

    #settings-title {
      position: relative;
      justify-content: space-between;

      &:after {
        content: 'Click to hide';
        font-size: 10px;
        right: 50px;
        bottom: 50%;
        position: absolute;
        z-index: 1;
        height: 10px;
        margin-top: -5px;
      }
    }
  }

  @media screen and (max-width: $smallBreak) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    box-shadow: 0px 10px 10px 4px #ccc;
    z-index: 1;
  }

  #settings-title {
    padding: 15px 20px !important;
    color: #9fa9ba;
    border-bottom: 1px solid #e5e9ed;
    cursor: pointer;

    &:hover, &:focus {
      outline: none;
      background: #efefef;
    }

    .text {
      font-weight: 600;
      font-size: 14px;
    }

    .settings-title-icon {
      font-size: 16px;
      margin-left: 8px;
    }
  }
}
