@use '../variables.scss';

#notifications-display.wrapper {
  float: right;
  cursor: pointer;
  height: 40px;
  width: 20px;
  margin-right: 25px;

  // Positioning is to ensure notifications dropdown stays fixed during scroll with sticky header
  .notifications-container {
    position: absolute;
    margin-top: 8px
  }

  .ant-badge-count {
    background: $base-red;
  }

  .ant-badge {
    .notification-icon {
      font-size: 24px;
      color: #9b9ea8;
    }

    &.ant-popover-open {
      .notification-icon {
        position: relative;
        color: $theme-icon-color;

        &:after {
          position: absolute;
          content: '';
          opacity: .3;
          left: -8px;
          top: -7px;
          border-radius: 30px;
          width: 40px;
          background: #ccc;
          height: 40px;
        }
      }
    }
  }
}

.notifications-list {
  min-width: 450px;

  .top {
    padding: 10px 16px 10px 16px;
    background-color: $off-white;
    font-weight: 600;
    line-height: 32px;
    border-bottom: 1px solid $lighter-grey;

    .title-text {
      font-size: 14px;
      float: left;
      color: $medium-grey;
    }

    .action-text {
      color: $theme-text-color;
      font-size: 16px;
      float: right;
      cursor: pointer;
      text-transform: unset;

      &:hover {
        background-color: $lightest-green;
      }
    }
  }

  .section {
    .header {
      padding: 7px 14px;
      background-color: $off-white;
      font-weight: 600;

      .title-text {
        font-size: 14px;
        color: $medium-light-grey;
        font-weight: 400;
      }

      .number-container {
        text-align: center;
        padding: 0 12px;
        margin-left: 14px;
        background-color: $dark-grey;
        border-radius: 45px;
        width: 45px;
        color: $white;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .horizontal-rule {
    margin: 0 0 0 0;
    background-color: $lighter-grey;
  }

  .clear-fix {
    clear: both;
  }

  .list-items {
    max-height: 450px;
    overflow: auto;

    > div:first-of-type {
      > .horizontal-rule {
        background: none;
        border-color: transparent;
      }
    }

    > hr.horizontal-rule {
      &:last-of-type {
        margin-bottom: 10px;
      }
    }
  }
}
