@use '../variables.scss';

.bidding-event-dashboard-block {
  @media(max-width: 960px) {
    .info-container{
     width: 100%;
     display: flex;
     flex-direction: column;

     .info-container-child{
       margin-bottom: 1rem;
       width: 100%;
     }
    }
  }

  .format-container {
    margin-top: 10px;
    border-top: 1px solid #eeeeee;
    padding-top: 20px;
  }

  &.without-event-details {
    .time-range-display {
      display: inline-block;
      margin-right: 50px;
    }

    .format-container {
      display: inline-block;
      margin-top: 0;
      border: none;
      padding-top: 10px;
      vertical-align: top;

      .title {
        min-width: 150px;
      }
    }
  }
}

#round-timer {
  margin-top: 0;
}
