@use '../variables.scss';

#survey-footer{
  .saving-changes {
    margin-right: 15px;
    text-align: right;
    font-size: 13px;
    color: $light-grey;
  }
  .action-btns {
    margin-top: 15px;
    background: white;
    padding: 15px 0;
    border-top: 1px solid transparent;

    .publish-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .saving-changes {
      margin-right: 15px;
      color: $medium-grey;
      font-size: 12px;

      // Indicates should be hiding the "Saved" text so fade it out
      &.true {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s 500ms, opacity 500ms linear;
      }
    }
  }

  .ant-affix {
    height: auto !important;

    .action-btns {
      margin-top: 0;
      border-color: $light-grey;
      box-shadow: 0 -10px 10px -10px $light-grey;
    }
  }
}
