@use '../variables.scss';

.flags-list {
  display: flex;

  .template {
    color: $awarded;
    border-color: $awarded;
  }
}
