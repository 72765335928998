@use '../variables.scss';

#award-scenarios-overview {
  // Extra padding make sure the select dropdown does not become a dropup and
  // overlap the tooltips
  .bid-request-tab-pane-content {
    padding-bottom: 240px;
  }

  .search-bar {
    background: $white;
    padding: 5px 10px;
    height: auto;
    border-radius: 0;
  }

  .title-container {
    padding: 22px 0 0 32px;
    font-size: 22px;
    font-weight: bold;
    line-height: 34px;
    vertical-align: middle;
  }
}
