@use '../variables.scss';

.header-item {
  display: flex;
  font-weight: 500;
  min-width: 200px;

  .ant-input {
    color: $medium-dark-grey;
    font-weight: 600;
    font-size: 14px;
  }

  .supplier {
    font-size: 16px;
    padding: 8px 6px 0 0;
    color: $theme-text-color;

    &:hover {
      color: $theme-hover-color;
    }

    &.hidden {
      color: $base-grey;

      &:hover {
        color: $theme-hover-color;
      }
    }

    &.disabled {
      opacity: 0.4;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .add {
    font-size: 20px;
    padding: 6px 0 0 8px;
    color: $theme-button-secondary-color;
    &:hover {
      color: $theme-hover-color;
    }

    &.disabled {
      opacity: 0.4;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .minus {
    font-size: 16px;
    padding: 8px 0 0 8px;
    color: $base-grey;

    &:hover {
      color: $dark-grey;
    }

    &.disabled {
      opacity: 0.4;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

