.currency-container {
  display: flex;
  justify-content: 'flex-start';
  align-items: center;

  .symbol {
    display: inline-block;
    font-size: 22px;
  }

  .code {
    display: inline-block;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 600;
  }
}
