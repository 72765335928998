.tag-group-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
