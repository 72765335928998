.chat-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  .str-chat__empty-channel{
    font-size: 16px;
    svg {
      width: 50px;
      height: 50px;
    }
  }
  .error-boundary {
    margin: 15px 15px;
    width: 100%;
  }

  .chat-channels-container {
    width: 30%;
    background-color: $white;

    .channel-lists-wrapper {
      @media all and (max-width: 960px) {
        display: none;
      }
    }
    // Remove archive button from channel preview
    .str-chat__channel-preview__action-button--archive {
      display: none;
    }
    .str-chat__channel-preview__action-button--pin {
      display: none;
    }
    .str-chat__channel-list-empty{
      font-size: 16px;
    }
    .str-chat__channel-list .str-chat__channel-list-messenger .str-chat__channel-list-messenger__main .str-chat__channel-list-empty svg{
      width: 50px;
      height: 50px;
    }
  }
}
