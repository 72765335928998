@use '../variables.scss';

.request-state-container {
  display: inline-block;

  .request-state-icon-container {
    font-size: 15px;
    display: inline-block;
    line-height: 0;
  }

  // Indicates should render without label text and border
  &.false {
    .request-state-icon-container {
      color: white;
      padding: 6px;
      border-radius: 50%;
      text-align: center;

      &.draft, &.closed, &.awarded { background: $theme-color; }
      &.open, &.open_for_questions, &.open_for_bidding, &.response_submitted { background: $green7; }
      &.ready_to_award { background: $blue6; }
      &.unawarded { background: $orange6; }
    }
  }

  // Indicates should render with label text and border
  &.true {
    .request-state-icon-container {
      margin-right: 10px;
    }

    background: $lightest-grey;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;

    &.draft, &.closed, &.awarded { color: $theme-color; }
    &.open, &.open_for_questions, &.open_for_bidding, &.response_submitted { color: $green7; }
    &.ready_to_award { color: $blue6; }
    &.unawarded { color: $orange6; }
  }
}
