.grouped-collapser-container {
  .collapsible-card {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .page-header-container {
    margin-bottom: 20px;
  }

  .importing {
    padding: 8px;
  }
}
