@use '../variables.scss';

#finalize-award-wrapper {
  h3.title {
    font-size: 26px;
    font-weight: 300;
    margin-bottom: 15px;
    margin-top: 30px;
  }

  .scenario-stats-container {
    position: relative;
    border: 1px solid #e5e9ed;
    border-radius: 5px;
    padding: 25px;
    z-index: 1;
    background: white;

    .scenario-title {
      font-size: 26px;
      font-weight: 300px;
    }

    .request-name {
      color: $theme-text-color;
      font-size: 14px;
    }

    .pricing-container {
      display: flex;
      justify-content: flex-end;
      font-size: 26px;

      .label {
        color: #95979e;
        font-size: 14px;
        margin-bottom: 5px;
      }

      .base {
        margin-right: 30px;
      }
    }
  }

  .line-item-toggle {
    position: absolute;
    text-align: center;
    color: $theme-color;
    font-size: 14px;
    border: 1px solid #e5e9ed;
    height: 30px;
    width: 50px;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    -webkit-box-pack: center;
    justify-content: center;
    left: 50%;
    margin-left: -25px;
    bottom: -30px;
    background: #f8fffe;
    z-index: 0;
    cursor: pointer;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .action-container {
    margin-top: 45px;

    .action-buttons-row {
      margin-top: 20px;
    }
  }
}
