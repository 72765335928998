.media-display-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .avatar-container {
    margin-right: 10px;

    .adsicon.avatar { font-size: 18px; }
    img.avatar { width: 18px; }

    &.medium {
      margin-right: 20px;

      .adsicon.avatar { font-size: 40px; }
      img.avatar { width: 40px; }
    }

    &.large {
      margin-right: 30px;

      .adsicon.avatar { font-size: 90px; }
      img.avatar { width: 90px; }
    }
  }

  .body {
    .name {
      font-weight: 600;
      font-size: 25px;
      line-height: 28px;
      color: $dark-grey;
    }

    .summary {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $medium-dark-grey;
    }
  }
}
