@use '../variables.scss';

.table-cell {
  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    background: white;

    &.last-child {
      &:after {
        content: '';
        z-index: 1;
        top: 0;
        right: 0;
        position: absolute;
        bottom: 0;
        border-right: 1px solid $light-grey;
      }
    }
  }
}
