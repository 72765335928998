.ads-popconfirm {
  .ads-popconfirm-message-icon {
    margin-right: 10px;
    line-height: 28px; // matches vertical alignment of title
  }

  .ads-popconfirm-title {
    font-size: 18px;
    font-weight: 300;
    color: $default-text;
  }

  .subtitle {
    font-size: 14px;
    color: $medium-grey;
  }
}
