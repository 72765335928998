.suppliers-page-header-container {
  background: $white;
  border-radius: 5px;
  padding: 32px 25px 20px 25px;
  overflow: hidden;

  span.title {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: $medium-dark-grey;
    text-transform: uppercase;
  }

  .second-line {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    @media screen and (min-width: 576px) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: stretch;
    }
  }
}
