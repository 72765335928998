.division-details-pane-container {
  padding: 20px;

  .division-details-overview {
    padding: 20px;
    border: 1px solid $medium-light-grey;
    border-radius: 5px;
    margin-bottom: 15px;
  }

  section {
    border-bottom: 1px solid $lighter-grey;
    margin-bottom: 25px;

    &.addresses {
      .firmographic-label-container {
        margin-bottom: 0;
      }
    }
  }

  .notes-field-container {
    opacity: 1 !important;
    margin-bottom: 5px;

    .title {
      font-weight: 500;
      color: $default-text;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
