.scorers-request-table.styled-table.ant-table-wrapper {
  .ant-table > .ant-table-content .ant-table-body {
    table {
      tbody.ant-table-tbody {
        > tr {
          td {
            cursor: auto; // remove this when row has clickable action
            color: #2b2c2f;
            font-weight: 600;
          }
        }
      }
    }
  }
}
