@import '../../../../styles/variables.scss';

$smallBreak: 576px;
$maxMobileMenuItem: 280px;
$maxDesktopMenuItem: 200px;
$maxUserInfoTextLength: 160px;
$menuTopOffset: 70px; // depends on relative positioning on wrapper div around SettingsDisplay

.wrapper {
  line-height: 140%;
  overflow: hidden;
  padding: 8px 0px 8px 8px;
  display: flex;
  flex-direction: row-reverse;

  .notificationsButton {
    margin-right: 15px;
  }
}

div:not(.userDisplayMenu) {
  .userDisplay {
    @media screen and (max-width: $smallBreak) {
      display: none;
    }
  }
}

.userDisplay {
  // float: right;
  color: $medium-light-grey;
  font-size: 16px;
  margin-right: 15px;
  max-width: 70%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .displayName {
    font-weight: 600;
  }
  .displayCompany {
    font-weight: 400;
  }

  .displayName,
  .displayCompany {
    margin: 0;
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

div.userDisplayMenu {
  max-width: $maxMobileMenuItem;
  padding: 12px;

  @media screen and (min-width: $smallBreak - 1) {
    max-width: $maxDesktopMenuItem;
    display: none;
  }

  .userDisplay {
    max-width: 100%;
    margin-right: 0;
    display: block;
    float: none;
    padding-bottom: 15px;
    border-bottom: 1px solid #e8eaf0;
  }
}

// Styles handle dropdown position when scrolling within affixed header
.dropdownWrapper {
  // float: right;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.dropdownContainer {
  position: absolute;
}

.menuItem {
  width: $maxDesktopMenuItem;
  font-size: 16px;

  a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 0px;
  }

  @media screen and (max-width: $smallBreak) {
    width: $maxMobileMenuItem;
  }
}

/* Adjusts AntD Dropdown component positioning */
.dropdown {
  top: $menuTopOffset !important;
}

.flexStart {
  align-items: flex-start;
}

.flexEnd {
  align-items: flex-end;
}

.settingsIcon {
  margin-right: 5px;
}
