.qualifying-line-item-answer-container {
  font-weight: 600;
  font-size: 12px;
  display: inline-block;
  color: $default-text;
  background: $lightest-grey;
  border-radius: 4px;
  padding: 20px;
  width: 100%;
  min-width: 200px;

  .title {
    margin-right: 10px;
    white-space: nowrap;
  }

  .value {
    &.yes_no {
      text-transform: capitalize;
    }
  }
}
