@import '@styles/variables.scss';

.container {
  width: 380px;
  padding: 0;

  &.standardScrollbar * {
    &::-webkit-scrollbar {
      width: .6rem;
      height: .6rem;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e8e8e8;
      outline: none;
      border-radius: 10px;
    }
  }

  .emptyList {
    padding: 16px 12px;
    text-align: center;

    .icon {
      font-size: 40px;
    }

    .title {
      margin-top: 15px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;

    .title {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      color: $colorText;
      padding: 0;
      margin: 0;
    }
  }

  .groupWrapper {
    max-height: 450px;
    overflow: auto;
  }
}
