.wrapper {
  display: flex;
  flex: 1 0 auto;

  .textAndNavWrapper {
    .textWrapper {
      flex: 1 0 auto;
      min-height: 210px;
      flex-direction: column;

      .title {
        color: #2b2c2f;
        font-size: 27px;
        line-height: 1.33;
        font-weight: 300;
        margin-bottom: 30px;
      }

      .subtitle {
        color: #95979e;
        font-size: 16px;
        line-height: 1.33;
        margin-bottom: 50px;
      }
    }

    .screenIndicators {
      flex: 0 0 auto;
      text-align: center
    }
  }

  .imageWrapper {
    padding-left: 20px;

    img {
      width: 100%;
      max-height: 300px;
    }
  }
}