.upload-special-template-container {
  // margin-bottom: 20px;

  .fields-container {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: normal;
  }

  .upload-input-container {
    flex: 1;

    label.select-file {
      border: 1px dashed $light-grey;
      padding: 10px 25px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        border-color: $base-grey;
      }

      input {
        display: none;
      }
    }

    // Indicates editing existing document
    &.true {
      label.select-file {
        text-align: left;
        display: flex;
        justify-content: space-between;

        .replace-text {
          color: $theme-text-color;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }

  .message {
    color: $medium-yellow;
  }
}
