.firmographic-label-container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  width: 260px;
  margin-right: 15px;
  margin-bottom: 25px;

  a {
    color: $theme-link-color;
  }

  .icon-container {
    margin-right: 10px;

    img {
      width: 16px;
    }

    i {
      font-size: 16px;
    }
  }

  .body {
    color: $medium-grey;

    .label {
      font-weight: 500;
    }

    .child {
      color: $default-text;
    }
  }
}
