@use '../variables.scss';

.notifications-comment-container {
  max-width: 550px;
  padding: 0 14px 0 14px;

  .ant-btn {
    text-transform: uppercase;
  }

  &:last-of-type {
    .ant-comment-inner {
      padding-bottom: 5px;
    }
  }

  .tiny-oval {
    margin: 0 5px 6px 5px;
    border: none;
    display: inline;
  }

  .ant-comment-inner {

    .ant-comment-content {

      .ant-comment-content-author {
        justify-content: space-between;

        .ant-comment-content-author-name {
          color: $dark-grey;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
