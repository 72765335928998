.survey-section-summary-stat {
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: normal;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  width: 260px;
  margin-bottom: 10px;

  .bullet {
    margin-right: 10px;
    width: 12px;
    height: 12px;
    background: #efefef; // colors assigned in component
    border-radius: 4px;
  }
}
