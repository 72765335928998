$placeholder: $theme-text-color;

.request-context-display-button {
  padding: 18px 25px;
  font-size: 18px;
  border-radius: 4px;
  color: white;
  border: 1px solid transparent;
  background: rgba(76, 77, 84, 1);
  display: block;
  margin-bottom: 16px;
  cursor: pointer;

  &.draft, &.closed, &.awarded { background: $theme-color; }
  &.openForQuestions, &.openForBidding { background: $green7; }
  &.readyToAward { background: $blue6; }
  &.unawarded { background: $orange6; }

  &:focus,
  &:hover {
    outline: none;
    color: white;
    background: rgba(76, 77, 84, .8);

    &.draft, &.closed, &.awarded { background: lighten($theme-color, 10%); }
    &.openForQuestions, &.openForBidding { background: darken($green7, 10%); }
    &.readyToAward { background: darken($blue6, 10%); }
    &.unawarded { background: darken($orange6, 10%); }

  }

  .title {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 90%;
    font-weight: 600;
    display: inline-block;
    overflow-x: hidden;
    vertical-align: middle;
  }

  .round {
    font-size: 14px;
  }

  .unreadIndicator {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    display: inline-block;
    margin-left: 8px;
    background: $badge-background-color;
    vertical-align: middle;
  }

  @media all and (max-width: $smallBreak) {
    padding: 8px 16px;
    margin-bottom: 8px;
  }
}
