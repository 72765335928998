@use '../variables.scss';

.question-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1;

  .question-number {
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    width: 40px;
    padding-right: 20px;
    padding-top: 20px;

    .required-label {
      border-radius: 15px;
      background: #F5F5F5;
      color: red;
      text-align: center;
      font-size: 18px;
      width: 15px;
      height: 15px;
      display: inline-block;
      line-height: 120%;
      font-weight: 300;
      vertical-align: center;
      white-space: nowrap;
      position: relative;
      right: -5px;
    }
  }

  .question-container {
    cursor: pointer;
    border: 1px solid $light-grey;
    background: white;
    padding: 20px 15px;
    border-radius: 5px;
    flex-grow: 1;
    margin-bottom: 15px;
    position: relative;

    // Is dragging
    &.true {
      background: $lightest-grey;
    }

    &.active {
      cursor: default;
      border-color: $theme-hover-color;
    }

    .handle {
      opacity: 0;
      position: absolute;
      top: 5px;
      width: 30px;
      text-align: center;
      left: 50%;
      margin-left: -15px;
      cursor: grab;
      transition: all 0.3s ease 0s;
    }

    &:hover {
      border-color: $theme-hover-color;

      .handle {
        opacity: 1;
      }
    }

    .question-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .field-container {
        &.text {
          span {
            font-size: 18px;

            // indicates question is active
            // span must exist in DOM in order for click event callbacks to accurately
            // find DOM parent elements
            &.true {
              display: none;
            }
          }
        }

        &.question-type {
          width: 220px;
        }

        &.double {
          flex-grow: 2;
        }

        .label {
          font-size: 12px;
          color: $medium-grey;
          margin-bottom: 10px;
        }

        .vertical-divider {
          width: 1px;
          background: $light-grey;
          height: 40px;
          margin-left: 15px;
        }

        &.toggle-column {
          min-width: 120px; // avoids UI adjustment when toggling state

          .switch-container {
            display: flex;
            align-items: center;
            height: 40px;

            .toggle {
              margin-right: 10px;
            }

            .toggle-text {
              font-weight: 300;
            }
          }
        }

        .actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 10px;

          .delete {
            font-size: 20px;
            padding: 15px;
            color: $medium-grey;

            &:hover {
              color: $dark-red;
            }
          }
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}
