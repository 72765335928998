@use '../variables.scss';

.custom-tab-button {
  background: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-bottom: 3px solid transparent;
  height: 100%;
  width: 100%;
  display: block;
  text-align: left;
  padding-left: 44px;
  box-sizing: border-box;
  cursor: pointer;

  @media screen and (max-width: $largeBreak) {
    padding: 0;
    text-align: center;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;

    &.icon {
      font-size: 24px;
      display: inline-block;
    }

    @media screen and (max-width: $largeBreak) {
      font-size: 14px;
    }
  }

  .count {
    font-size: 40px;
    font-weight: 600;

    @media screen and (max-width: $largeBreak) {
      font-size: 24px;
    }
  }

  // Add additional class names below to handle correct color for various uses of this button
  // Class names correspond to the camelcased title passed into TabButton

  // Dark-Grey
  &.all { color: $dark-grey; }

  // Grey
  &.draft, &.pending { color: $draft; }

  // Base Green
  &.openForQuestions, &.open_for_questions { color: $openForQuestions; }

  // Base Green
  &.openForBidding, &.openForSubmission, &.shortlisted, &.open_for_bidding { color: $openForBidding; }

  // Orange
  &.responseSubmitted, &.surveyPending { color: $responseSubmitted; }

  // Base Blue
  &.closedRequests, &.closedBids, &.invited, &.closed { color: $closed; }

  // Dark Blue
  &.awardedRequests, &.awardedBids, &.ready, &.accepted, &.awarded { color: $awarded; }

  &.bounced { color: $bounced; }

  &.rejected, &.declined { color: $declined; }

  &.active {
    // Dark-Grey
    &.all {
      border-color: $dark-grey;
      background: rgba($dark-grey, .1);
    }

    // Grey
    &.draft, &.draftRequests, &.responseSubmitted {
      border-color: $draft;
      background: rgba($draft, .1);
    }

    // Base Green
    &.open_for_questions, &.openForQuestions {
      border-color:$theme-color;
      background: rgba($theme-color, .1);
    }

    // Base Green
    &.open_for_bidding, &.openForBidding, &.openForSubmission, &.shortlisted {
      border-color: $openForBidding;
      background: rgba($openForBidding, .1);
    }

    // Orange
    &.responseSubmitted, &.surveyPending {
      border-color: $responseSubmitted;
      background: rgba($responseSubmitted, .1);
    }

    // Base Blue
    &.closed, &.closedRequests, &.closedBids, &.invited {
      border-color: $closed;
      background: rgba($closed, .1);
    }

    // Dark Blue
    &.awarded, &.awardedRequests, &.awardedBids, &.ready, &.accepted {
      border-color: $awarded;
      background: rgba($awarded, .1);
    }

    &.bounced {
      border-color: $bounced;
      background: rgba($bounced, .1);
    }

    &.rejected, &.declined {
      border-color: $declined;
      background: rgba($declined, .1);
    }
  }

  &:focus,
  &:hover {
    outline: none;
    border-color: #cccccc;
  }
}
