.engagement-score-container {
  border-radius: 45px;
  background-color: $lightest-grey;
  width: 45px;
  text-align: center;

  .score-text {
    font-size: 14px;
    font-weight: 600;
    &.green { color: $medium-green; }
    &.yellow { color: $medium-yellow; }
    &.orange { color: $medium-orange; }
    &.red { color: $medium-red; }
    &.grey { color: $medium-dark-grey; }
  }
}

.insight-popover-table {
  .title-head {
    padding: 4px 0 0 0;
    background-image: linear-gradient(to top, #fbf9f9, #fcfcfc);

    .title {
      padding-left: 20px;
      padding-bottom: 8px;
      font-size: 16px;
      font-weight: 600;
    }

    .table-head {

      .lot {
        float: left;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 20px;
        font-size: 12px;
        font-weight: 600;
        color: $base-grey;
      }

      .num {
        float: right;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 20px;
        font-size: 12px;
        font-weight: 600;
        color: $base-grey;
      }
    }
  }

  .table-content {

    .name {
      float: left;
      line-height: 18px;
      font-weight: 600;
      padding-left: 20px;
      padding-top: 8px;
    }

    .num {
      float: right;
      line-height: 18px;
      font-weight: 600;
      padding-right: 35px;
      padding-top: 8px;
    }

    .score-binary {
      float: right;
      line-height: 18px;
      font-weight: 600;
      padding-right: 30px;
      padding-top: 8px;
    }

    .score-ratio {
      float: right;
      line-height: 18px;
      font-weight: 600;
      padding-right: 16px;
      padding-top: 8px;
    }
  }

  .rank-foot-outer {
    padding: 8px 20px 12px 20px;

    .inner {
      font-size: 16px;
      font-weight: 600;
      line-height: 38px;
      vertical-align: center;
      height: 38px;
      color: $dark-grey;
      background-color: $lightest-grey;

      .overall {
        float: left;
        padding-left: 16px;
      }

      .num {
        float: right;
        padding-right: 12px;
      }
    }
  }
}

.rank-module {

  .numerator {
    font-size: 18px;
    font-weight: 800;

    &.one {
      background: -webkit-linear-gradient(#ffcb13, #ffa513);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.two {
      background: -webkit-linear-gradient(#c7c7c7, #939393);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.three {
      background: -webkit-linear-gradient(#d7a2a2, #a65c5c);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.four { color: #67696f; }
  }

  .denominator {
    font-size: 12px;
    font-weight: 600;
    color: #030000;
  }
}

.clear-fix {
  clear: both;
}
