$rowBorderRadius: 4px;
$rowPadding: 15px;

.standalone-table-headers-container {
  // Width can be overridden by props
  width: 100%;

  // Indicates this should be a scrollable table with a max width
  &.true {
    overflow: auto;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  table {
    min-width: 100%;

    thead.ant-table-thead {
      > tr {
        background: white;

        th {
          color: #9b9ea8;
          font-size: 12px;
          background: transparent;
          border-bottom-color: transparent;
          padding-bottom: 4px;

          &:hover {
            background: transparent;
          }

          .ant-table-header-column {
            vertical-align: baseline; // fix for mixing and matching sortable columns
          }

          &:first-of-type {
            padding-left: $rowPadding;
          }

          &.ant-table-column-has-sorters {
            &.ant-table-column-sort {
              background: transparent;

              &:hover {
                background: transparent;
              }
            }

            .ant-table-column-sorter {
              vertical-align: baseline;

              .ant-table-column-sorter-up,
              .ant-table-column-sorter-down {
                &.on {
                  color: $theme-color;
                }

                &.off {
                  color: #e6e6e6;
                }
              }
            }
          }
        }
      }
    }
  }
}
