.survey-form-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $lighter-grey;
  border-radius: 5px;
  padding: 25px;

  .info-container {
    .title {
      color: $default-text;
      font-size: 24px;
    }

    .progress-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .percentage {
        margin-right: 15px;
      }

      .progress-bar {
        width: 180px;
        background: $lighter-grey;
        position: relative;
        height: 6px;
        border-radius: 6px;

        .progress-color-bar {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height: 100%;
          border-radius: 4px;
          width: 0;

          &.bad { background: $dark-red; }
          &.notice { background: $dark-yellow; }
          &.good { background: $medium-green; }
        }
      }
    }
  }

  .action-btn-container {
    button {
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
