#vendor-onboard-wrapper {
  background-image: linear-gradient(155deg, #edf4ed, #c5e2ea);
  min-height: 100vh;
  padding-top: 25vh;

  // First of type signifies first usage of Card.
  // Doing this so that the Card component can still be used if necessary without overriding styles
  .ant-card:first-of-type {
    box-shadow: 0 5px 20px 3px rgba(95, 163, 178, 0.1);
    margin-bottom: 150px; // Handles the delighted survey showing up along bottom

    .ant-card-head {
      border-bottom: none;
    }
  }

  .title {
    font-size: 27px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #2b2c2f;
  }

  .subtitle {
    color: #95979e;
  }
}
