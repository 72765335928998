@import '@tokens';

#vendor-bid {
  // True indicates it's a Buyer Previewing
  &.true {
    .vendor-bid-container {
      background: white;

      .vendor-bid-body {
        padding: 20px;
      }
    }
  }
  .vendor-bid-body{
    padding-top: 20px;
  }
  .vendor-bid-step-header-container{
    padding-left: 20px;
    display: flex;
    justify-content: space-between;
  }

  .round-number-wrapper {
    margin-bottom: 25px;
  }

  .request-dashboard-block {
    &.bidding-event-dashboard-block {
      border: none;
      // Hide if user is supplier
      &.true{
        display: none;
      }
    }
  }

  .questions-count {
    font-size: 18px;
    text-align: right;
  }

  .csv-upload-modal {
    .processing {
      font-size: 18px;
      margin: 20px 0;
    }
    .heading {
      font-size: 18px;
      margin: 20px 0;
    }
    .csv-error {
      color: #FF0000;
      font-size: 18px;
      margin: 20px 0;
    }
  }
}

// Override the styles when used within buyer side quoting.
// TOMBSTONE: When the input field element with add-on is updated in the component
// library, these styles should not be necessary. This was a temporary implementation to get
// new styles in place with buyer side quoting while not affecting existing experiences.
#user-quote-submission-detail {
  .bid-request-tab-pane-content {
    padding: 0;

    .search-container {
      padding-top: 0;

      .search-bar {
        .input.ant-input-group-wrapper {
          .ant-input-wrapper {
            input {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              background: $colorBgContainer;
              border: 1px solid $colorBorder;
            }

            &.ant-input-group {
              .ant-input-group-addon:last-child {
                background: $colorBgContainer;
                border-color: $colorBorder;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border-left-color: transparent;
              }
            }
          }
        }
      }
    }
  }

  #vendor-bid {
    .vendor-bid-container {
      .vendor-bid-body {
        padding-top: 0;
      }
    }

    #bid-submission {
      .show-pricing {
        // The Icons next to Apply Discount, Reload, Clear, Download & Upload
        .icon-wrapper {
          border-radius: 8px;
          border: 1px solid $colorBorder;
        }
      }
    }
  }
}
