.upload-special-template-edit {
  color: $theme-text-color;
  font-size: 18px;
  cursor: pointer;
  opacity: .6;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  input {
    display: none;
  }
}
