.actions-dropdown-menu-icon {
  margin-right: 10px;
}

.remove-org-modal {
  text-align: center;

  .modal-btns-container {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    justify-content: center;

    button {
      width: auto;
    }
  }
}
