@use '../variables.scss';

.general-dashboard-block {
  .label {
    color: $medium-grey;
    font-size: 13px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  .business-unit {
      font-weight: 600;
      margin-bottom: 16px;

    .bu-label {
      color: $medium-grey;
      font-size: 12px;
    }

    .bu-value {
      font-size: 16px;
      color: $black;
    }
  }

  .business-unit-select {
    margin-bottom: 24px;
  }

  .bu-select-label {
    margin-bottom: 5px;
    color: $medium-grey;
    font-size: 14px;
  }

  .custom-field-container {
    margin-top: 8px;
    color: $medium-grey;
    font-size: 12px;
  }

  .custom-field-type {
    color: $medium-grey;
    font-size: 12px;
    margin-bottom: 4px;
    font-weight: 500;
  }

  .custom-field-instructions {
    color: $medium-grey;
    font-size: 12px;
    margin-bottom: 4px;
  }

  .custom-field-col {
    font-size: 13px;
    padding-right: 8px;
    max-width: 24em;
  }

  input,
  textarea {
    padding: 15px;
    background: $white;
    color: $default-text;
    border: 1px solid rgba(0, 0, 0, 0.15);

    &:read-only {
      border-color: transparent;
    }
  }

  input {
    font-size: 22px;
    margin-bottom: 20px;
  }

  textarea {
    font-size: 14px;
  }

  .request-title {
    font-size: 21px;
    line-height: 24px;
    font-weight: 700;
    color: $black;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .tag-list {
    padding-left: 10px;
    display: flex;
    align-items: center;
    font-weight: normal;
  }

  .formField {
    margin-bottom: 24px;
  }

  #template-flag {
    color: $awarded;
    border-color: $awarded;
  }
}
