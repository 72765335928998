@use '../variables.scss';

.type-dashboard-block {
  .title {
    &.subcategory {
      padding: 15px 20px;
      border: 1px solid $light-grey;
      border-radius: 5px;
    }

    i {
      &.starred {
        color: #ffc514;
        margin-right: 6px;
      }
    }
  }

  .option-row {
    .ant-col {
      margin-bottom: 15px;
    }
  }

  // Override margin styles built into Component that renders this class
  .request-onboarding-tooltip-line {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}
