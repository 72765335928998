.round-number-wrapper {
  display: inline-block;

  .round-number-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .badge-container {
      margin-right: 20px;
      color: white;
      background: $theme-button-secondary-color;
      height: 65px;
      width: 65px;
      border-radius: 65px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      .adsicon {
        font-size: 30px;
      }
    }

    .info-container {
      color: $medium-grey;
      font-size: 14px;

      strong {
        color: $default-text;
        font-size: 22px;
      }
    }
  }
}
