#requests-overview {
  .search-container {
    display: flex;
    flex-direction: row;
  }

  .search-bar {
    margin-right: 1.5rem;
    flex-grow: 1;
  }

  .table-filters-container {
    margin-bottom: 15px;
  }

  .styled-table.requests-table.ant-table-wrapper {
    .ant-table > .ant-table-content .ant-table-body {
      table {
        .copy-request-icon {
          font-size: 24px;
          vertical-align: text-bottom;

          &.disabled {
            opacity: 0.3;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
