#weighting-choices-modal {
  margin-top: 55px;

  h2 {
    font-size: 27px;
    font-weight: 300;
    margin-bottom: .1em;
  }

  .footer-container {
    margin-top: 25px;
    margin-bottom: 10px;
  }
}
