.progress-bar-wrap {
  width: 100%;
  height: 5px;
  border-radius: 5px;

  .progress-bar {
    background-color: $theme-color;
    height: 5px;
    border-radius: 5px;
    transition: width .5s;
  }
}
