$registrationsContainerWidth: 800px;
$mobileRegistrationsContainerWidth: 700px;
$smallerContainerWidth: 500px;

body {
  background: linear-gradient(155deg, #444548 10%, #2b2c2f 79%);
  background-repeat: no-repeat;
  background-attachment: fixed;

  #registration {
    height: 100%;
    width: 100%;

    .login-layout.registrations {
      .wrapper {
        padding-top: 15%;
        padding-bottom: 50px;

        .logo-container {
          text-align: right;
          margin-bottom: 50px;

          img  {
            width: 135px;
          }
        }

        .login-fields-container {
          text-align: center;
          margin: 0 auto;
          width: auto;
          max-width: $registrationsContainerWidth;

          &.small {
            max-width: $smallerContainerWidth;

            // Small Mobile Device
            @media screen and (max-width: $registrationsContainerWidth) {
              width: $mobileRegistrationsContainerWidth;
              max-width: $mobileRegistrationsContainerWidth;
              padding: 15px 15px;
            }
          }

          .col {
            &.col-left {
              float: left;
              width: 40%;
            }

            &.col-right {
              float: right;
              width: 60%;
            }

            @media screen and (max-width: $mobileRegistrationsContainerWidth) {
              float: none !important;
              width: 100% !important;
              margin-top: 20px;
            }
          }

          .recaptcha {
            margin-top: 20px;
          }

          .title {
            text-align: left;
            display: flex;
            align-items: center;

            span {
              margin-right: 20px;
            }
          }

          .subtitle {
            text-align: left;
          }

          .success {
            text-align: center;

            .title, .subtitle {
              text-align: center;
              display: block;
            }
          }

          input {
            &[readonly='readonly'] {
              &[type='email'] {
                border: none;
                font-weight: 600;
              }
            }
          }
        }

        &.has-valid-email {
          .login-fields-container {
            max-width: $smallerContainerWidth;

            .header {
              display: none;
            }

            .col-left {
              display: none;
            }

            .col-right {
              width: 100%;
            }
          }
        }
      }
    }
  }
}