@use '../variables.scss';

$inputHeight: 45px;

.line-items-table {
  border: $light-grey 1px solid;
  border-radius: 2px;

  .standard-scrollbar {
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: .5em !important;
    }
  }

  &:hover {
    &.standard-scrollbar {
      &::-webkit-scrollbar-thumb {
        background: $theme-hover-color;
      }
    }
  }

  table {
    thead {
      tr {
        th {
          color: $medium-dark-grey;
          background-color: $lightest-grey;
          font-weight: 600;
          font-size: 14px;
          padding-left: 14px;

          .add {
            font-size: 20px;
            color: $theme-button-secondary-color;

            &:hover {
              color: lighten($theme-button-secondary-color, 20%);
            }

            &.disabled {
              opacity: 0.4;

              &:hover {
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
    tbody {
      td {
        padding: {
          top: 4px;
          bottom: 4px;
          left: 14px;
        }
        border-bottom: none;

        .ant-input-number,
        .ant-input-number input {
          min-width: 75px;
          height: $inputHeight;
          min-height: $inputHeight;
          text-align: left;
        }

        .ant-input {
          min-width: 200px;
        }

        input,
        textarea {
          min-height: $inputHeight !important;
          height: $inputHeight !important;
          text-align: left;
        }
      }
    }
  }

  .load-more-line-items-container {
    text-align: center;
    margin: {
      top: 15px;
      bottom: 20px;
    }
  }
}
