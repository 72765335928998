@use '../variables.scss';

.document-card {
  border: 1px solid #c9cdd1;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  width: 320px;
  max-width: 320px;

  .ant-comment {
    .ant-comment-inner {
      padding-top: 0;

      .ant-comment-content-author {
        font-size: 14px;
        font-weight: 600;
        color: $medium-grey;

        .ant-comment-content-author-name {
          max-width: 240px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .ant-comment-avatar {
        font-size: 42px;
        line-height: 100%;

        img {
          width: auto;
          height: auto;
          border-radius: 0;
        }
      }
    }
  }

  .filename {
    display: inline-block;
    color: $theme-text-color;
    font-weight: 600;
    font-size: 18px;
    text-decoration: underline;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 230px;
  }

  .submission-details {
    border-radius: 4px;
    border: 1px solid #c9cdd1;
    margin-bottom: 25px;

    .submission-progress-container {
      padding: 15px;
      background: $lightest-grey;

      .label-row {
        display: flex;
        justify-content: space-between;
        font-weight: 500;

        .label {
          font-size: 13px;
          color: $medium-grey;
        }

        .count {
          font-size: 14px;
          color: $default-text;
          text-align: right;
        }
      }

      .progress-bar {
        position: relative;
        height: 6px;
        width: 100%;
        background: $light-grey;
        border-radius: 3px;
        margin-top: 15px;

        .progress {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          height: 100%;
          border-radius: 3px;

          &.good {
            background: $medium-green;
          }

          &.notice {
            background: $medium-yellow;
          }

          &.warning {
            background: $medium-orange;
          }

          &.bad {
            background: $medium-red;
          }
        }
      }
    }

    .progress-note {
      font-size: 14px;
      font-weight: 600;
      padding: 8px;
    }
  }

  .footer {
    margin-top: auto;

    .org-submissions-container {
      display: flex;
      justify-content: space-between;

      .submitted-suppliers-container {
        width: 270px;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        color: $default-text;
      }

      .navigate-icon {
        font-size: 22px;
        color: $theme-icon-color;
        vertical-align: top;
      }
    }
  }
}
