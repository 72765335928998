.lot-ranks-table-collapse {
  background-color: $white;
  border: none;

  &.ant-collapse {
    background-color: $white;

    &.ant-collapse-icon-position-right {
      background-color: $white;
      border: none;
    }

    .ant-collapse-item {
      border: 1.7px solid $light-grey;
      margin-bottom: 10px;
      border-radius: 5px;

      .ant-collapse-header {
        background-color: white;
      }
      .ant-collapse-content-active {
        border: none;
        overflow-x: auto;

        .ant-collapse-content-box {
          padding: 0 16px 16px 16px;
        }
      }
    }
  }

  .line-item-ranks-table-container {
    overflow-x: auto;
    border-top: 2px solid #d9d9d9;
  }

  th, td {
    border: none !important;
    min-width: 100px;
  }

  .name-cell {
    min-width: 300px;
  }

  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
