@use '../variables.scss';

.bid-totals-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  color: $white;
  padding: 0 20px;
  gap: 8px;

  .bid-totals-round {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  .label {
    display: flex;
    gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    span {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }
  }

  .value {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
  }

  .col {
    &.left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
    }

    &.right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
    }
  }

  @media screen and (min-width: $smallBreak) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .bid-totals-round {
      flex-direction: row;
    }

    .col {
      &.left {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      &.right {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
