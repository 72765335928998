@use '../variables.scss';

.page-tab-row-container {
  border-bottom: 1px solid $light-grey;

  // Default to header-level tab styles
  .tab-btn {
    padding: 24px 50px;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 3px solid transparent;
    color: $medium-grey;
    text-align: center;
    cursor: pointer;

    &:focus,
    &:hover {
      outline: none;
    }

    // Is active
    &.true {
      border-color: rgba(63, 207, 193, 1);
      color: rgba(63, 207, 193, 1);
      background: rgba(63, 207, 193, .1);
    }
  }

  // Use Pilled tabs
  &.true {
    background: $lightest-grey;
    padding: 10px 20px;
    border-bottom: none;
  }
}
