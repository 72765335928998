@use '../variables.scss';

.notification-action-text {
  color: $theme-text-color;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  display: inline;

  &:hover {
    background-color: $lightest-green;
  }
}
