.ant-card.request-card {
  text-align: left;
  margin-bottom: 15px;
  font-size: 16px;
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: $theme-hover-color;
  }

  a {
    color: #2b2c2f;
  }

  * {
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
  }

  .awardee-icon {
    position: absolute;
    top: -10px;
    right: -14px;
    width: 28px;
    font-size: 16px;
    height: 28px;
    background: #fff7db;
    color: #ffcb13;
    border-radius: 14px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .ant-card-head {
    border-bottom: none;

    .ant-card-head-title {
      text-align: left;
      font-size: 18px;
      font-weight: 600;
    }
  }

  .data-label {
    color: #9b9ea8;
    font-size: 14px;
  }

  .total-bid-price-container {
    &.positive { color: #358a61; }
    &.negative { color: #b80707; }
    &.neutral { color: $dark-grey }
  }

  .no-submission {
    font-size: 14px;
  }

  .ant-row:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .view-button {
    display: inline-block;
    font-size: 14px;
    color: #2b2c2f;
    opacity: 1;
    float: right;
    padding-top: 6px;
    padding-right: 20px;
  }

  .persona-oval {
    display: inline-block;
    float: left;
    border-radius: 20px;
    border: solid 1.7px #f1f1f1;
    text-align: center;
    vertical-align: middle;
    padding-left: 16px;
    padding-right: 16px;
  }

  .persona-text {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    vertical-align: top;
    letter-spacing: normal;
    color: #80838e;
    padding-left: 4px;
  }

  .persona-info-icon-span {
    padding-left: 4px;
    font-size: 12px;
  }

  .insight-info-icon {
    color: #c9cdd1;
  }

  .engagement-score-container {
    border-radius: 45px;
    background-color: $lightest-grey;
    width: 45px;
    text-align: center;

    .score-text {
      font-size: 14px;
      font-weight: 600;
      &.green { color: $medium-green; }
      &.yellow { color: $medium-yellow; }
      &.orange { color: $medium-orange; }
      &.red { color: $medium-red; }
      &.grey { color: $medium-dark-grey; }
    }
  }

  .clear-fix {
    clear: both;
  }
  .authorized-display-link {
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;

    * {
      transition: none;
      -webkit-transition: none;
      -moz-transition: none;
    }

    &:hover {
      color: $theme-hover-color;
    }

    .detail-btn-wrapper {
      padding-top: 4px;

      .detail-btn {
        font-size: 14px;
      }

      i {
        font-size: 14px;
      }
    }
  }
}
