.evaluation-criteria-wrapper {
  border: 1px solid #e5e9ed;
  padding: 20px;
  border-radius: 5px;

  .title {
    color: #95979e;
    margin-bottom: 10px;
  }

  .criteria-container {
    .criteria-item {
      display: inline-block;
    }
  }
}
