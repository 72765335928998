@use '../variables.scss';

$rowBorderRadius: 4px;
$rowPadding: 15px;

.styled-table.ant-table-wrapper {
  .ant-table > .ant-table-content .ant-table-body {
    overflow-x: auto !important; // override inline AntD style for horizontal scroll

    table {
      border-collapse: separate;
      border: 0;
      border-spacing: 0 4px;

      // Header Row
      thead.ant-table-thead {
        > tr {
          background: white;

          th {
            color: $medium-dark-grey;
            font-size: 12px;
            line-height: 16px;
            background: transparent;
            border-bottom-color: transparent;
            padding-bottom: 4px;

            &:hover {
              background: transparent;
            }

            .ant-table-header-column {
              vertical-align: baseline; // fix for mixing and matching sortable columns
            }

            &:first-of-type {
              padding-left: $rowPadding;
            }

            &.ant-table-column-has-sorters {
              &.ant-table-column-sort {
                background: transparent;

                &:hover {
                  background: transparent;
                }
              }

              .ant-table-column-sorter {
                vertical-align: baseline;

                .ant-table-column-sorter-up,
                .ant-table-column-sorter-down {
                  &.on {
                    color: $theme-color;
                  }

                  &.off {
                    color: #e6e6e6;
                  }
                }
              }
            }
          }
        }
      }

      tbody.ant-table-tbody,
      tbody.ant-table-tbody .infinite-scroll-component {
        > tr {
          td {
            cursor: pointer;
            font-size: 14px;
            color: #2b2c2f;
            vertical-align: middle;
            border: solid 1px #e1e3e6;
            border-left: none;
            border-right: none;
            padding-top: $rowPadding;
            padding-bottom: $rowPadding;

            &:first-of-type {
              padding-left: $rowPadding;
              border-left-style: solid;
              border-left-color: #e1e3e6;
              border-left-width: 1px;
              border-top-left-radius: $rowBorderRadius;
              border-bottom-left-radius: $rowBorderRadius;
            }

            &:last-of-type {
              padding-right: $rowPadding;
              border-right-style: solid;
              border-right-color: #e1e3e6;
              border-right-width: 1px;
              border-top-right-radius: $rowBorderRadius;
              border-bottom-right-radius: $rowBorderRadius;
              position: relative; // necessary to position arrow indicator
            }

            .row-action-buttons {
              span {
                font-size: 18px;
                color: $theme-text-color;
                margin: 0px 8px 0px 8px;
              }
              span.disabled {
                color: $light-grey;
                cursor: not-allowed;
              }
            }

            .dropdown-ellipsis {
              span {
                font-size: 18px;
                color: $theme-text-color;
                margin: 0px;
              }
            }

            // The chevron-right arrow to indicate navigation row
            .go-to-x-indicator {
              position: absolute;
              right: $rowPadding;
              top: 50%;
              margin-top: -7px;
              height: 14px;
            }

            .name-column {
              display: flex;

              .avatar {
                font-size: 36px;
                padding-right: 10px;
                float: left;
              }
            }
          }

          &.not-accessible,
          &.not-accessible td {
            cursor: default;
            background: transparent;

            &:hover {
              background: transparent;
            }
          }
        }
      }
    }

    .ant-checkbox-wrapper {
      > span:not(.ant-checkbox) {
        color:  $theme-link-color;
      }
    }
  }
}

.styled-table.ant-table-wrapper.compact {
  .ant-table > .ant-table-content .ant-table-body {
    table {
      border-spacing: 0;
      tbody.ant-table-tbody,
      tbody.ant-table-tbody {
        > tr {
          td {
            border-radius: 0;
            &:first-child {
              position: relative;

              &:after {
                position: absolute;
                border-bottom: none;
                background: white;
                z-index: 2;
                left: 0;
                right: 0;
                bottom: -2px;
                width: 15px;
                height: 2px;
                content: '';
              }
            }
            &:last-child {
              position: relative;

              &:after {
                position: absolute;
                border-bottom: none;
                background: white;
                z-index: 2;
                right: 0;
                bottom: -2px;
                width: 15px;
                height: 2px;
                content: '';
              }
            }
          }
          &:first-of-type {
            td {
              &:first-of-type {
                border-top-left-radius: $rowBorderRadius;
              }
              &:last-of-type {
                border-top-right-radius: $rowBorderRadius;
              }
            }
          }
          &:last-of-type {
            td {
              &:first-of-type {
                border-bottom-left-radius: $rowBorderRadius;
                &:first-child {
                  &:after {
                    content: none;
                  }
                }
              }
              &:last-of-type {
                border-bottom-right-radius: $rowBorderRadius;
                &:last-child {
                  &:after {
                    content: none;
                  }
                }
              }
            }
          }
          &:not(:first-of-type) {
            td {
              border-top-color: transparent;
            }
          }
        }
      }
      .hidden span {
        font-size: 0;
      }
    }
  }
}
.styled-table.ant-table-wrapper .ant-table > .ant-table-content .ant-table-body table tbody.ant-table-tbody > tr td{
  background-color: transparent;
}
