@use '../variables.scss';

.survey-status-container {
  padding: 20px;
  color: $default-text;

  .header {
    justify-content: space-between;

    .title {
      font-size: 16px;
      font-weight: 600;
      display: inline-block;
      width: 50%;
    }

    .actions-container {
      font-size: 32px;
      line-height: 0;
      display: inline-block;
      width: 50%;
      text-align: right;
    }
  }

  .body {
    border-radius: 5px;
    margin-top: 20px;
    background: lighten($light-grey, 15%);

    .body-main {
      padding: 20px 20px 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .counts-container {
        .counts {
          font-weight: 600;
          font-size: 34px;
          line-height: 40px;
        }

        .subtitle {
          color: $medium-dark-grey;
        }
      }

      .progress-circle-container {
        width: 50px;
      }
    }

    .body-footer {
      padding: 10px 15px;
      background: lighten($lighter-grey, 5%);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .show-suppliers-toggle {
        font-weight: 500;
        font-size: 13px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .footer {
    text-align: center;

    .button {
      margin-top: 20px;
    }
  }
}

.pre-qualification-dropdown-menu {
  .ant-dropdown-menu {
    padding: 10px;
  }

  i {
    margin-right: 10px;
    width: 20px;
    color: $default-text;
  }

  .ant-dropdown-menu-item {
    font-weight: 600;

    &.delete {
      color: $base-red;

      i {
        color: $base-red;
      }
    }
  }
}
