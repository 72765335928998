.supplier-dashboard-header {
  .about-this-request {
    border: 0;
    color: $dark-grey;

    .description-container{
      margin-bottom: 30px;
      min-height: 100px;
      max-height: 300px;
      overflow-y:auto;
      border: 1px solid $light-grey;
      border-radius: 5px;
      padding: 10px;
    }

    .title {
      font-weight: normal;

      .company-website-link {
        color: $theme-link-color;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .currency-column{
      text-align: center;

      .currency-container {
        display: block;
        text-align: center;

        .code {
          font-weight: normal;
        }

        .symbol{
          font-size: 14px;
        }
      }
    }
    .event-timeline{
      text-align: right;

      .title{
        text-align: right;
      }
    }
  }
}
