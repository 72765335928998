@use '../variables.scss';

.stats-label-container {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  .stats-label {
    span {
      color: $default-text;
    }
  }

  .progress-circle-container {
    display: inline-block;
    width: 24px;
    line-height: 0;
    margin-left: 15px;
  }
}
