@use '../variables.scss';

.survey-settings-dropdown {
  background: white;
  border: 1px solid $medium-grey;
  padding: 15px 10px 5px;
  border-radius: 4px;
  min-width: 190px;
  box-shadow: 0 10px 10px 4px $light-grey;

  .survey-settings-dropdown-menu {
    display: flex;
    flex-direction: column;

    .dropdown-menu-title {
      border-bottom: 1px solid $light-grey;
      padding-bottom: 15px;
      margin-bottom: 15px;
      font-weight: 600;
    }

    .label {
      font-size: 12px;
    }

    .menu-item {
      color: $default-text;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
      line-height: 26px;
      padding: 4px 10px;
      border-radius: 5px;

      &:hover {
        background-color: $lightest-grey;
      }

      &:focus {
        outline: none;
      }

      &.switch {
        padding: 4px 4px;

        &:hover {
          background: none;
        }

        .ant-switch-small {
          min-width: 24px;
        }
      }

      .icon {
        padding-bottom: 4px;
      }

      .toggle {
        margin-right: 10px;
      }

      .toggle-text {
        font-weight: 300;
      }

      .icon-text {
        vertical-align: middle;
      }

      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    hr {
      display: block;
      border-top: 1px solid $light-grey;
      width: 100%;
    }
  }
}
