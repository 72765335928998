@use '../variables.scss';

#add-document-modal {
  .ant-comment {
    .ant-comment-avatar {
      min-width: 60px;
      font-weight: 500;
      padding-top: 10px;
      cursor: default;

      .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        word-wrap: break-word;
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .ant-comment-content-author {
      display: none;
    }
  }

  .switch-row {
    .text {
      margin-left: 15px;
      margin-right: 10px;
      vertical-align: middle;
      &.label-text {
        display: inline-block;
        font-weight: normal;
        font-size: 14px;
        line-height: 1.5;
        word-wrap: break-word;
        color: #595959;
      }
    }
  }

  .upload-input-container {
    input {
      display: none;
    }

    label {
      border: 1px dashed $light-grey;
      padding: 10px 25px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;

      &:hover {
        border-color: $base-grey;
      }
    }

    .file-display-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $light-grey;
      border-radius: 5px;
      padding: 10px 20px;

      .name {

      }

      .delete {
        color: $base-red;
        font-size: 18px;
        cursor: pointer;
        opacity: .6;

        &:hover {
          opacity: 1;
        }

        &:focus {
          outline: none;
        }
      }

      .uploading-text {
        text-align: right;
        font-size: 13px;
        color: $medium-grey;
      }
    }

    // Indicates editing existing document
    &.true {
      label {
        text-align: left;
        display: flex;
        justify-content: space-between;

        .existing-file-text {

        }

        .replace-text {
          color: $theme-text-color;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
}
