@import '../variables.scss';

#pat{
  .ant-table-pagination.ant-pagination .ant-pagination-prev, .ant-table-pagination.ant-pagination .ant-pagination-next {
    border: none;
  }

  h1{
    font-size: 40px;
  }

  p{
    font-size: 1rem;
  }

  .button-container{
    width: max-content;
    margin-left: auto;
    padding-bottom: 1rem;
  }
}


.pat__key{
    display: inline-block;
    width: 5.75rem;
    text-align: center;
    padding: .0625rem .5rem;
    color: #F5222D;
    background-color: #FFF1F0;
    border-radius: 4px;
    border: 1px solid #FFA39E;
}

.pat__keygen {
    text-align: center;
    padding: .0625rem .5rem;
    color: #F5222D;
    background-color: #FFF1F0;
    border-radius: 4px;
    border: 1px solid #FFA39E;
}

.pat__create--container{
  display: flex;

  * {
    height: 100%;
  }

  span{
    margin-left: .25rem;
  }
}

.pat__delete_button{
   background-color: transparent;
   margin-left: .25rem;
   border: 2px solid transparent;
   transition: all 0.1s linear;
   color: #1890ff;

   &:is(:hover, :focus){
    border: 2px solid $open;
   }
}
