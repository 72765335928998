.answer-options-container {
  .mark-all {
    margin-right: 10px;
    font-size: 16px;
  }

  &.single_text,
  &.single_numeric {
    display: flex;
    align-items: center;

    .answer-option-field {
      display: inline-block;
      white-space: nowrap;
    }
  }

  .answer {
    line-height: 28px;
    font-weight: 600;
  }

  &.lot {
    white-space: nowrap;

    .ant-radio-group {
      white-space: nowrap;
    }
  }

  &.lineItem {
    .mark-all {
      display: none;
    }

    .answer {
      display: block; // Ants radios default to inline-block which we do not want here
    }
  }

  .qualifying-icon {
    margin-left: 10px;
    color: $bright-yellow;
    top: 2px;
    position: relative;
  }
}
