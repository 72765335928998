.bid-request-schedule {
  .request-dashboard-container {
    border: 1px solid $light-grey;
    margin-bottom: 16px;
    border-radius: 5px;

    .title {
      font-size: 18px;
      text-align: left;
      color: $default-text;
      padding: 10px 0 10px 10px;
      background: #f6f6f6;

      a {
        color: $default-text;
        cursor: text;

        &:hover {
          color: $default-text;
        }
      }

      // Indicates title is a hyperlink
      &.true {
        a {
          color: $default-text;
          cursor: pointer;

          i.adsicon,
          i.anticon {
            color: $theme-icon-color;
          }

          &:hover {
            color: $theme-hover-color;
          }
        }
      }
    }

    .body {
      // Indicates a title is present
      padding: 20px 0;

      &.true {
        margin-top: 20px;
      }
    }
  }
  .request-dashboard-block {
    padding-top: 0;

    .title {
      background: grey;
    }
  }
}
