.profile-details-container {
  border-left: 1px solid #d3d3d3;
  padding: 10px 20px;

  .engagement-container {
    font-size: 14px;
    font-weight: 600;
    color: $base-grey;
  }

  .average-score {
    font-size: 20px;
    font-weight: 600;
    color: $base-grey;

    &.green {
      color: $medium-green;
    }

    &.yellow {
      color: $medium-yellow;
    }

    &.orange {
      color: $medium-orange;
    }

    &.red {
      color: $medium-red;
    }
  }
}
