@import '@tokens';

.closeBtn {
  display: none;
  position: absolute;
  right: 30px;
  top: 35px;
  font-size: 20px;
  color: white;

  @media screen and (max-width: $mediumBreak) {
    display: block;
  }
}

.backdrop {
  background: rgba(36, 37, 40, .4);
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: $backdropZIndex;

  @media screen and (max-width: $mediumBreak) {
    display: block;
  }
}

.wrapper {
  position: fixed;
  top: 0px;
  z-index: $affixedZIndex + 1;

  @media screen and (max-width: $mediumBreak) {
    top: 122px;
  }
}
