.toggle-button {
  cursor: pointer;
  border: 1px solid $light-grey;
  background: $lightest-grey;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  color: $light-grey;
  padding: 20px;
  text-align: center;

  // Indicates button is active
  &.true {
    border-color: $theme-hover-color;
    color: $theme-hover-color;
    background: $theme-hover-color;
    cursor: default;
  }

  &:not(.true):hover {
    border-color: $default-text;
    color: $default-text;
  }

  &:focus {
    outline: none;
  }
}
