.file-upload-button {
  &.upload-input-container {
    input {
      display: none;
    }

    label.upload-btn {
      text-align: center;
      cursor: pointer;
      border-radius: 35px;
      display: inline-block;
      color: white;
      background: $theme-button-secondary-color;
      border-color: $theme-button-secondary-color;
      font-size: 16px;
      font-weight: 500;
      padding: 10px 35px;
      margin-bottom: 0;

      &:hover {
        border-color: $base-grey;
      }

      // Indicates a file is currently being uploaded
      &.true {
        background: $base-grey;
        border-color: $base-grey;
        cursor: none;
        pointer-events: none;
      }
    }

    .file-display-container {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;

      .file-contents {
        border: 1px solid $light-grey;
        border-radius: 5px;
        padding: 10px 20px;
        margin-right: 15px;

        .uploading-text {
          text-align: right;
          font-size: 13px;
          color: $medium-grey;
        }
      }

      .delete {
        color: $base-red;
        font-size: 18px;
        cursor: pointer;
        opacity: .6;

        &:hover {
          opacity: 1;
        }

        &:focus {
          outline: none;
        }
      }
    }

    label.replacing {
      text-align: left;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      margin-bottom: 0;

      .existing-file-text {
        margin-right: 15px;
      }

      .replace-text {
        color: $theme-text-color;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

  }
}

