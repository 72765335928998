.organization-vendors-request-table.styled-table.ant-table-wrapper {
  .ant-table > .ant-table-content .ant-table-body {
    table {
      tbody.ant-table-tbody {
        > tr {
          // Don't show a clickable state if invitation is pending
          &.not-accessible,
          &.not-accessible td {
            cursor: default;
            background: transparent;

            &:hover {
              background: transparent;
            }
          }

          &.selection-disabled,
          &.selection-disabled td {
            cursor: default;
            span {
              cursor: not-allowed;
              label {
                span {
                  background-color: $lightest-grey;
                  input {
                    cursor: not-allowed;
                  }
                }
              }
            }
          }

          td {
            color: $default-text;
            font-weight: 600;
          }
        }
      }
    }
  }
}

#vendor-organizations-request-view {
  .ant-card {
    .ant-card-head {
      border-bottom: none;

      // Fixes jumpy UI when toggling edit state
      .ant-card-head-title {
        padding: 22px 0 0 0;
      }
    }

    .ant-card-body {
      padding-top: 0;
    }

    .ant-comment-inner {
      padding: 0;

      .ant-comment-content-author {
        margin-bottom: 14px;
      }
    }
  }

  .customized-select {
    &.ant-select-disabled {
      .ant-select-selection {
        background: transparent;
      }

      .ant-select-arrow {
        display: none;
      }
    }
  }

  .remove-organization-btn {
    padding: 6px 15px;
    border-radius: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: $rejected;
    display: flex;
    cursor: pointer;

    &:hover, &:focus {
      background: #f5eded;
    }

    .remove-organization-icon {
      color: $rejected;
      margin-left: 15px;
      font-size: 20px;
    }
  }
}
