#request-state-tab-row {
  height: 125px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $light-grey;
  flex-wrap: nowrap;

  .state-button-container {
    width: 100%;
  }
}
