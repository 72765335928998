.alert-container {
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 100%;

  &.success {
    background: $background-green;

    // Add the "action-link" class to anchor tags within an alert to style based on the type
    a {
      &.action-link {
        color: $dark-green;
      }
    }
  }

  &.warning {
    background: $background-yellow;

    a {
      &.action-link {
        color: $dark-yellow;
      }
    }
  }

  &.error {
    background: rgba(190, 0, 0, 0.1);

    a {
      &.action-link {
        color: $dark-red;
      }
    }
  }
}
