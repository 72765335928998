@use '../variables.scss';

$iconSpacing: 20px;

.award-scenarios-table.styled-table.ant-table-wrapper {
  .ant-table > .ant-table-content {
    .ant-table-body table {
      tr {
        td {
          &.action-cell {
            position: relative;

            .action-btn-container {
              height: 100%;
              width: 100%;
              position: absolute;
              top: 0;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .cell-container {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                text-align: center;
                font-size: 16px;
                padding-left: $iconSpacing;

                &:focus {
                  outline: none;
                }

                &.delete {
                  a, i {
                    color: $dark-red;
                  }
                }

                &.awarded {
                  background: linear-gradient(90deg, #edf4ed, #ddeef3);
                  color: $bright-yellow;
                  margin-left: $iconSpacing;
                }

                &.download,
                &.finalize {
                  z-index: 999;
                  position: relative;
                  cursor: pointer;

                  i {
                    color: $theme-icon-color;
                  }
                }

                &:last-of-type {
                  padding-right: $iconSpacing;
                }
              }

              .arrow {
                margin-right: 10px;
              }

              // Indicates awarded
              &.true {
                .cell-container {
                  color: $bright-yellow;

                  &.download {
                    i {
                      color: $bright-orange;
                    }
                  }
                }
              }
            }
          }
        }

        .coverage {
          &.bad { color: $dark-red; }
          &.notice { color: $dark-yellow; }
          &.good { color: $medium-green; }
        }

        &.is-create-row {
          td {
            border-color: $medium-dark-grey;
            border-top-style: dashed;
            border-bottom-style: dashed;
            padding: {
              top: 10px;
              bottom: 10px;
            }

            &:first-of-type {
              border-left-color: $medium-dark-grey;
              border-left-style: dashed;
            }

            &:last-of-type {
              border-right-color: $medium-dark-grey;
              border-right-style: dashed;
            }

            &.title {
              color: $base-grey;
            }
          }
        }

        &:hover {
          // If it's the creation row, don't apply any row hover effects
          &.is-create-row {
            td {
              cursor: default;
              background: transparent;
            }
          }
        }
      }
    }
  }
}
