// Hide no api key error in case it appears.
.tox .tox-notification--warn,
.tox .tox-notification--warning {
  display: none;
}

.tox {
  &.tox-silver-sink {
    &.tox-tinymce-aux {
      .tox-notifications-container {
        display: none;
      }
    }
  }
}

.rich-text-editor {
  // Indicates loading the editor
  &.true {
    display: none;
  }

  .tox-tinymce--disabled {
    .tox-editor-header {
      display: none;
    }
  }
}