$borderRadius: 10px;

#scoring-survey-summary {
  margin-bottom: 15px;

  .totals-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    .stacked-chart-container {
      width: 280px; // arbitrary from mock
      margin-right: 15px;
      height: 10px;
      border-radius: $borderRadius;
      line-height: 0;
      white-space: nowrap;

      .chart-section {
        height: 100%;
        background: #efefef; // actual colors set within component
        display: inline-block;
        border-right: 1px solid white;

        &:first-child {
          border-top-left-radius: $borderRadius;
          border-bottom-left-radius: $borderRadius;
        }

        &:last-child {
          border-top-right-radius: $borderRadius;
          border-bottom-right-radius: $borderRadius;
        }
      }
    }

    .points-container {
      font-size: 16px;
      font-weight: 600;
    }
  }
}
