$padding: 20px;
$padding2x: $padding * 2;

.requests-autocomplete-wrapper {
  min-width: 500px;

  .search-wrapper {
    padding: $padding;
    background: #fbf9f9;
  }

  .table-wrapper {
    .head-row {
      color: #9b9ea8;
      font-size: 14px;
      padding: 5px $padding 10px;
      background: #fbf9f9;
    }

    .requests-wrapper {
      padding: 5px 10px;

      .requests-body {
        max-height: 260px;
        overflow-y: auto;

        // Style the scrollbar
        &::-webkit-scrollbar {
          width: .6em;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
          box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #e8e8e8;
          outline: none;
          border-radius: 10px;
        }

        .body-row {
          padding: 10px 10px;
          color: #2b2c2f;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
          border: 1px solid transparent;
          margin: 2px 0;
          border-radius: 4px;

          &:hover {
            background: #efefef;
          }

          &.selected {
            border-color: $theme-hover-color;
          }

          .request-status {
            &.draft, &.closed, &.awarded { color: $theme-color; }
            &.open_for_questions, &.open_for_bidding { color: $green7; }
            &.ready_to_award { color: $blue6; }
            &.unawarded { color: $orange6; }
          }
        }
      }
    }
  }
}
