@use '../variables.scss';

.question-body {
  border-top: 1px solid transparent;
  padding-top: 0px;
  margin-top: 5px;

  // Indicates this question is active
  &.true {
    border-color: $lighter-grey;
    padding-top: 20px;
    margin-top: 20px;
  }

  .title {
    color: $base-grey;
    font-size: 12px;
    margin-bottom: 10px;
  }
}
