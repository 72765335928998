@use '../variables.scss';

 // Greater specificity and less !importants would be nice here
.incumbent-selection-wrapper {
  margin-top: 10px;
  margin-bottom: 10px;

    .ant-select-selection {
      border: 1px solid #d9d9d9 !important;
      padding: 0 !important;
    }

    .ant-select-selection-selected-value {
      font-size: 16px !important;
    }
}

// Bid Response Specific Lot CSS

$tableBorderColor: $light-grey;
$innerTableBorderColor: $lighter-grey;
$tableBorderRadius: 4px;
$sideCellPadding: 20px;

.bid-submission-lot {
  .lot-details-container {
    display: flex;
    justify-content: space-between;

    .line-items-count {
      white-space: nowrap;
      margin-left: 30px;
      text-align: right;
      font-size: 13px;
      color: $base-grey;
    }
  }

  .lot-container {
    overflow-x: auto;
    padding-bottom: 10px;

    // Line Items Table
    table.line-item-table {
      border-collapse: separate;
      width: 100%;
      border: 0;
      border-spacing: 0;

      thead {
        tr {
          th {
            font-size: 12px;
            font-weight: 600;

            &.custom-item-header {
              padding: {
                left: $sideCellPadding;
                right: 10px;
                bottom: 10px;
              }
            }

            &.price {
              padding-left: $sideCellPadding;
            }

            // Add transparent borders to keep things in alignment with body.
            // Without this, we'll be off by 1px left & right.
            &:first-child {
              border-left: 1px solid transparent;
              padding-left: $sideCellPadding;
            }

            &:last-child {
              border-right: 1px solid transparent;
            }

            &:not(.checkbox):not(.sno):not(.no-quote) {
              min-width: 130px;
            }

            &.no-quote {
              min-width: 75px;
            }

            &.name {
              // Indicates descriptive columns exist
              &.true {
                min-width: 200px;

                div {
                  display: flex;
                  justify-content: space-between;
                  align-items: baseline;
                }
              }
            }
          }
        }
      }

      // All <tr> in entire table
      tr {
        height: 45px;
        min-height: 45px;
      }

      // All <td> in entire table
      td {
        padding: {
          top: 10px;
          bottom: 10px;
          right: $sideCellPadding;
        }
        min-height: 20px;

        &.qualifying {
          vertical-align: top;
          background: $background-green;
        }

        &.qualifying,
        &.description {
          padding-left: $sideCellPadding;
        }

        &.price {
          background: $background-green;
          padding: {
            left: $sideCellPadding;
          }
          .price-container {
            white-space: nowrap;
          }
        }

        // First column in entire table
        &:first-child {
          padding-left: $sideCellPadding;
        }

        &.checkbox {
          line-height: 0;
        }

        &.sno {
          margin-right: 10px;
        }

        .line-item-input {
          white-space: nowrap;
          background: white;
        }
      }

      tbody {
        tr {
          // First body row
          &:first-child {
            td {
              border-top: 1px solid $tableBorderColor;

              // First column, first row
              &:first-child {
                border-top-left-radius: $tableBorderRadius;
              }

              // Last column, first row
              &:last-child {
                border-top-right-radius: $tableBorderRadius;
              }
            }
          }

          // All body <td> elements
          td {
            border-bottom: 1px solid $innerTableBorderColor;

            &.name {
              border-right: 1px solid $tableBorderColor;
            }

            // First column in any row
            &:first-child {
              border-left: 1px solid $tableBorderColor;
            }

            // Last column in any row
            &:last-child {
              border-right: 1px solid $tableBorderColor;
            }

            .line-item-input {
              border: 1px solid $tableBorderColor;
              border-radius: 4px;

              input {
                border: none;
                text-align: right;
                min-width: 72px;
              }
            }
          }

          // Last body row
          &:last-child {
            td {
              border-bottom: 1px solid $tableBorderColor;

              // First column, last row
              &:first-child {
                border-bottom-left-radius: $tableBorderRadius;
              }

              // Last column, last row
              &:last-child {
                border-bottom-right-radius: $tableBorderRadius;
              }
            }
          }

          &.warning-line-item {
            background: $light-red;

            td {
              &.qualifying,
              &.price {
                background: darken($light-red, 5%);
              }

              .qualifying-answer-container {
                span {
                  color: $default-text;
                }
              }
            }
          }
        }
      }

      .sticky_name {
        left: max($snoColumnMinWidth,5vw);
        position: sticky;
        background-color: white;
      }

      .sticky-sno{
        left: 0;
        position: sticky;
        background-color: white;
      }

      .sno {
        margin-left: 10px;
      }
    }

    .cell-side-padding {
      padding-left: $sideCellPadding;
    }
  }

  .price-match-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #efefef;
    border-radius: 5px;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 10px;

    .animate-flicker {
      display: inline-block;
      margin-right: 10px;
      font-weight: 600;
    }
  }

  .load-more-line-items-container {
    text-align: center;
    margin-top: 15px;
  }
}
