.sliding-drawer {
  .ant-drawer-content{
    border-radius: 4px 0 0 4px;
  }
  .ant-drawer-header {
    position: absolute;
    width: 90%;
  }
  .sliding-drawer-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;
  }
  .sliding-drawer-body {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
