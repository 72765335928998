#supplier-survey-submissions {
  .stats-bar {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
  }

  // This fixes the padding issue with the table header
  .ant-table-fixed-header .ant-table-scroll .ant-table-header {
    padding-bottom: 4px;
  }
}
