@use '../variables.scss';

$toggleSize: 16px;
$innerSelectionSize: calc($toggleSize / 2);
$innerSelectionOffset: calc(($innerSelectionSize / 2) * -1); // for centering

.ant-dropdown {
  &.spend-category {
    ul.ant-dropdown-menu {
      li.ant-dropdown-menu-item {
        position: relative;

        .radio-toggle {
          position: absolute;
          border: 1px solid rgb(169, 169, 169); // matches AntD
          border-radius: $toggleSize;
          width: $toggleSize;
          height: $toggleSize;
          background: white;
          right: 20px;
          top: 50%;
          margin-top: calc(($toggleSize / 2) * -1);

          // True indicates selected
          &.true {
            &:after {
              content: '';
              display: block;
              position: absolute;
              background: $theme-hover-color;
              border-radius: $innerSelectionSize;
              width: $innerSelectionSize;
              height: $innerSelectionSize;
              top: 50%;
              margin-top: $innerSelectionOffset;
              left: 50%;
              margin-left: $innerSelectionOffset;
            }
          }
        }
      }
    }
  }
}
