@use '../variables.scss';

$containerWidth: 400px;
$mobileContainerWidth: 300px;
$ssoContainerWidth: 600px;

body {
  background: linear-gradient(155deg, #444548 10%, #2b2c2f 79%);
  background-repeat: no-repeat;
  background-attachment: fixed;

  &.login-layout {
    .action {
      margin-top: 20px;
    }

    .login-logo {
      max-width: 180px;
    }

    .centered {
      margin: 0 auto;
      width: $containerWidth;

      @media screen and (max-width: $containerWidth) {
        width: $mobileContainerWidth;
      }

      .messages {
        color: white;
      }
    }

    &.sso-login {
      .centered {
        width: $ssoContainerWidth;

        @media screen and (max-width: $ssoContainerWidth) {
          width: $mobileContainerWidth;
        }
      }

      .login-fields-container {
        width: $ssoContainerWidth;

        @media screen and (max-width: $ssoContainerWidth) {
          width: $mobileContainerWidth;
          padding: 15px 15px;

          .title {
            font-size: 20px;
          }
        }

        .redirect-btn {
          @media screen and (max-width: $ssoContainerWidth) {
            display: block;
            margin-bottom: 10px;
            width: 100%;
            margin-right: 0;
          }
        }
      }
    }
  }

  .bottom-link-actions {
    text-align: center;
    margin-top: 30px;

    a {
      color: white;
    }
  }

  .login-fields-container {
    background-color: $white;
    border-radius: 4px;
    margin: 20px 0 15px;
    padding: 30px 40px;
    box-shadow: 0 5px 20px 3px rgba(95, 163, 178, 0.1);
    width: $containerWidth;
    box-sizing: border-box;

    @media screen and (max-width: $containerWidth) {
      width: $mobileContainerWidth;
      padding: 15px 15px;
    }

    input {
      border: none;
      font-size: 14px;

      &:not([type='submit']) {
        width: 100%;
        border-bottom: 1px solid $other-medium-grey;
        height: 30px;
      }

      &[type='submit'] {
        color: #fff;
        margin-top: 30px;
        background: $theme-button-secondary-color;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: 500;
        padding: 15px 35px;
        text-align: center;
        display: inline-block;
        border-radius: 30px;
        cursor: pointer;
        width: auto;

        &:hover {
          background: lighten($theme-button-secondary-color, 20%);
        }
      }

      &:focus {
        outline: none;
        border-color: #333;
      }
    }

    label {
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: $other-medium-grey;

      &:not(:first-of-type) {
        margin-top: 20px;
      }
    }

    .title {
      font-size: 26px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 27px;
      letter-spacing: normal;
      text-align: center;
      color: #2b2c2f;
      margin-bottom: 30px;

      &.error {
        color: $medium-red;
      }

      &.has-subtitle {
        margin-bottom: 10px;
      }

      .error-image {
        vertical-align: bottom;
      }
    }

    .subtitle {
      color: #95979e;
      font-size: 13px;
      margin-bottom: 30px;
    }

    .password-links {
      margin-top: 10px;
      font-size: 13px;
      font-weight: 500;

      .left {
        float: left;
      }

      .right {
        float: right;
      }

      a {
        color: $theme-link-color;
      }
    }

    .clear {
      clear: both;
    }

    .company {
      color: #888888;

      .name {
        margin-bottom: 10px;
      }
    }

    .policy-links {
      margin-top: 40px;
      color: #c0c0c0;

      .divider {
        display: inline-block;
        font-size: 11px;
        margin-left: 5px;
        margin-right: 5px;
      }

      a {
        color: #c0c0c0;
        font-size: 12px;
      }
    }

    .error-msg {
      margin-top: 10px;
      color: $medium-red;
    }
  }

  .centered-flex {
    display: flex;
    justify-content: center;
  }

  .action-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .redirect-btn {
    background: $theme-button-secondary-color;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    font-weight: 500;
    padding: 15px 35px;
    text-align: center;
    display: inline-block;
    border-radius: 30px;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  .forgot-password {
    background: $white;
    border-color: $theme-button-secondary-color;
    color: $theme-button-secondary-color;
  }

  .emphasis-text {
    color: $other-medium-grey;
    font-weight: 600;
  }

  .error-container {
    text-align: left;
  }

  .error-msg {
    margin-top: 10px;
    color: $medium-red;
  }

  .sso-login-link-container {
    color: $white;
    font-size: 16px;

    .tool-tip-text {
      font-size: 14px;
      color: $white;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.5s ease-in-out;
    }

    a {
      font-weight: 600;
      color: lighten($theme-link-color, 20%);
    }

    &:hover .tool-tip-text {
      visibility: visible;
      opacity: 1;
    }
  }
}
