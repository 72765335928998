.answer-option-field {
  width: max-content;

  &.numeric {
    min-width: 150px;

    input {
      text-align: left;
    }

    .ant-input-wrapper {
      &.ant-input-group {
        .ant-input-group-wrapper {
          .ant-input-group-addon {
            padding: 0;
            height: 32px;
            font-weight: 600;

            .numeric-answer-option-dropdown {
              cursor: pointer;
              padding: 0;
              width: 45px;
              height: 100%;
              display: flex;
              vertical-align: middle;
              justify-content: center;
              align-items: center;

              &.ant-dropdown-trigger {
                &.ant-dropdown-open {
                  background: $theme-color;
                  color: white;
                }
              }
            }
          }
        }
      }
    }

    // Indicates this is disabled
    &.true {
      opacity: .2;
      pointer-events: none;
    }

    .qualifying-answer-container {
      color: $base-grey;
      font-size: 14px;
      font-weight: 600;
      margin-top: 5px;

      i {
        color: $bright-yellow;
        margin-right: 10px;
        line-height: 24px;
      }
    }
  }
}

.numeric-answer-option-dropdown-menu {
  li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 4px;
    }

    &.ant-dropdown-menu-item-selected {
      border-radius: 4px;
      background: transparent;
      border: 1px solid $theme-hover-color;
      color: $default-text;
    }
  }
}
