.division-header-container {
  border-bottom: 3px solid rgb(184, 235, 230);
  background: white;
  padding: 20px 20px 90px;

  .back-button-container {
    border-bottom: 1px solid $lighter-grey;
    padding-bottom: 10px;
    margin-bottom: 30px;
  }

  .statuses-dropdown-container {
    .label {
      font-size: 13px;
      color: $base-grey;
      margin-bottom: 10px;
    }
  }
}
