.channel-lists-desktop-wrapper {
  height: 90vh;
  max-height: 90vh;
  min-height: 90vh;
  overflow-y: auto;
  border-right: 1px solid $lighter-grey;
  background: white;

  @media all and (max-width: 960px) {
    display: none;
  }
}

.str-chat-channel {
  &.messaging {
    &.str-chat {
      height: 100vh;
      min-height: auto;

      // Main message list container
      .str-chat__list {
        ul.str-chat__ul {
          li.str-chat__li {
            .vendor {
              .str-chat__message {

                .str-chat__avatar {
                  // Main avatar circle
                  .str-chat__avatar-fallback {
                    background: $base-blue;
                  }
                }

                // Reference for future styles
                // .str-chat__message-inner {

                //   .str-chat__message-text {

                //     .str-chat__message-simple-text-inner {

                //     }
                //   }
                // }
              }
            }

            .buyer {
              .str-chat__message {
                .str-chat__message-simple-status {
                  position: initial;
                }

                .str-chat__avatar {
                  .str-chat__avatar-fallback {
                    background: $theme-color;
                  }
                }
              }
            }

            .vendor {
              .str-chat__message {
                .str-chat__message-simple-status {
                  position: initial;
                }
              }
            }
          }
        }
      }
    }
  }
}
