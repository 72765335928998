@use '../variables.scss';

.request-dashboard-block {
  border: 1px solid $light-grey;
  padding: 20px;
  margin-bottom: 16px;
  border-radius: 5px;

  &.editing {
    border-color: $theme-hover-color;
    box-shadow: 0 4px 15px 0 $light-grey;
    -webkit-box-shadow: 0 4px 15px 0 $light-grey;
    -moz-box-shadow: 0 4px 15px 0 $light-grey;
  }

  section {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    color: $default-text;

    a {
      color: $theme-link-color;
      cursor: text;

      &:hover {
        color: $default-text;
      }
    }

    // Indicates title is a hyperlink
    &.true {
      a {
        color: $theme-link-color;
        cursor: pointer;

        i.adsicon,
        i.anticon {
          color: $theme-icon-color;
        }

        &:hover {
          color: $theme-hover-color;
        }
      }
    }
  }

  .edit-btn-container {
    text-align: right;
  }

  .label {
    color: $theme-text-color;
    font-size: 14px;
    text-transform: none;
  }

  .body {
    // Indicates a title is present
    &.true {
      margin-top: 20px;
    }
  }

  .scheduler-link {
    margin-left: auto;
    margin-right: 1rem;
    color: #0958d9;
    text-decoration: underline;
  }
}
