
.ant-dropdown.team-dropdown {
  border: solid 1px #e5e9ed;
  border-radius: 5px;

  ul {
    padding: 15px;

    li {
      &.ant-menu-item:hover {
        color: white;
        background: $theme-hover-color;
        font-weight: 400;
        border-radius: 4px;
      }
    }
  }
}

.suppliers-page-header-dropdown-menu {
  background-color: $white;
  border: 1px solid $base-grey;
  padding: 10px;

  .ant-dropdown-menu-item {
    border: 2px solid $white;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 15px;

    span {
      font-style: normal;
      font-weight: 600;
      font-size: 21px;
      line-height: 24px;
      color: $default-text;
    }
  }

  .ant-dropdown-menu-item.selected {
    border: 2px solid $theme-hover-color;
  }
}
