#multiple-choice-weightings-select {
  .applied-to-container {
    margin-bottom: 20px;
  }

  .errors-container {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 15px;
    min-height: 25px;
    opacity: 0;
    transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;

    // Indicates errors are present
    &.true {
      color: $dark-yellow;
      opacity: 1;
    }
  }

  .add-weighting-action {
    margin-top: 20px;
    color: $theme-text-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }

  .actions-container {
    margin-top: 20px;
    text-align: center;
  }
}
