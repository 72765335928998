i {
  &.line-item-row-dropdown {
    font-size: 26px;
    line-height: 0;
  }
}

.line-item-row-dropdown-menu {
  .ant-dropdown-menu {
    padding: 10px;
  }

  i {
    margin-right: 10px;
    width: 20px;
    color: $default-text;
  }

  .ant-dropdown-menu-item {
    font-weight: 600;

    &.delete {
      color: $base-red;

      i {
        color: $base-red;
      }
    }
  }
}
