// Global Chart JS Styles
.standard-chart-tooltip {
  background: rgba(0,0,0, .8);
  border-radius: 16px;
  padding: 16px;
  color: white;
  font-size: 14px;

  .title-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .color-indicator {
      width: 15px;
      height: 15px;
      border: 2px solid white;
      border-radius: 15px;
      margin-right: 15px;
    }
  }

  .body {
    margin-top: 10px;
    padding-left: 30px;

    .line-item {
      margin-top: 6px;
    }
  }
}
