
@use '../variables.scss';

.line-item-table {
  tr td {
    //vertical-align: bottom;
  }
  input, textarea {
    width: 100%;
    //border: none;
    //border-bottom: 1px solid $dark-grey;
  }
  textarea {
    margin-top: 6px;
  }
}

.section-lot {
  margin-bottom: 15px;
}

.email-template-modal {
  width: 800px;
  .button {
    margin-right: 15px;
  }
}


.line-item-import {
  .panel-error .ant-collapse-header {
    color: $error-highlight;
  }

  .review-text {
    font-size: 18px;
  }

  .warning {
    color: #ff0000;
  };

  .table-error {
    color: $error-highlight;
    tr, th {
      color: #242528;
    }
  }
}

.document-file-upload {
  border: 1px solid #b4b4b4;
  display: inline-block;
  background-color: #efeff4;
  padding: 4px 10px;
  cursor: pointer;
  text-transform: none;
}

.document-file-upload:hover {
  border: 1px solid #b4b4b4;
  display: inline-block;
  background-color: #d7d7dc;
  padding: 4px 10px;
  cursor: pointer;
  text-transform: none;
}
