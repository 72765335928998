.pretty-date-display-container {
  text-align: center;
  margin: 0 auto;

  // Indicates should display a box & border
  &.true {
    border: 1px solid rgba(237, 244, 237, 1);
    background: rgba(237, 244, 237, .4);
    border-radius: 5px;
    padding: 25px;
  }

  .day {
    font-size: 16px;
    font-weight: 600;
    color: $medium-grey;
    text-transform: uppercase;
    line-height: 10px;
  }

  .day-of-month {
    color: $theme-text-color;
    font-size: 60px;
    font-weight: 300;
    line-height: 65px;
  }

  .month,
  .year {
    display: inline-block;
    color: $default-text;
    font-size: 14px;
    text-transform: uppercase;
  }

  .month {
    font-weight: 600;
    margin-right: 6px;
  }

  .year {
    font-weight: normal;
  }
}
