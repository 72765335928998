.documents-dashboard-block {
  .documents-container {
    overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    padding-bottom: 20px;
    flex-flow: row;
    display: flex;

    .document-card {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
