@use '../variables.scss';

.loading-container {
  text-align: center;

  // Indicates to render as inline block
  &.true {
    display: inline-block;
  }

  i.adsicon,
  i.anticon {
    color: $theme-icon-color;
  }

  &.placeholder {
    background: $lightest-grey;
    background: -webkit-linear-gradient(45deg, $lightest-grey 0%, $lighter-grey 33%, $lightest-grey 66%, $lighter-grey 100%);
    background: -moz-linear-gradient(45deg, $lightest-grey 0%, $lighter-grey 33%, $lightest-grey 66%, $lighter-grey 100%);
    background: -ms-linear-gradient(45deg, $lightest-grey 0%, $lighter-grey 33%, $lightest-grey 66%, $lighter-grey 100%);
    background: -o-linear-gradient(45deg, $lightest-grey 0%, $lighter-grey 33%, $lightest-grey 66%, $lighter-grey 100%);
    background: linear-gradient(45deg, $lightest-grey 0%, $lighter-grey 33%, $lightest-grey 66%, $lighter-grey 100%);
    background-size: 400% 400%;
    -webkit-animation: loadingBackground 5s linear infinite;
    -moz-animation: loadingBackground 5s linear infinite;
    animation: loadingBackground 5s linear infinite;
    height: 30px;
    border-radius: 1px;
    opacity: .6;
  }

  @-webkit-keyframes loadingBackground {
    0% { background-position: 100% 0% }
    100% { background-position: 15% 100% }
  }

  @-moz-keyframes loadingBackground {
    0% { background-position: 100% 0% }
    100% { background-position: 15% 100% }
  }

  @keyframes loadingBackground {
    0% { background-position: 100% 0% }
    100% { background-position: 15% 100% }
  }
}
