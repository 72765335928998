@use '../variables.scss';

#vendors-tab-pane .add-invite-btns {
  display: flex;
  justify-content: flex-end;

  .invite, .send {
    margin-left: 10px;
  }
}
