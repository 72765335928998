.yes-no-btn-group {
  border-color: $light-grey;
  white-space: nowrap;
  display: flex;

  .yes-no-btn {
    height: 32px;
    font-size: 12px;
    color: $light-grey;
    font-weight: 600;
    background: transparent;
    padding-left: 15px;
    padding-right: 15px;
    text-transform: capitalize;

    // True indicates active
    &.true {
      border-color: $theme-button-secondary-color;
      color: $theme-button-secondary-color;
    }
  }
}
