#survey-header {
  margin-bottom: 30px;

  > .ant-row-flex {
    .ant-col {
      &:last-child {
        text-align: right;
      }
    }
  }

  .title {
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
  }
}
