@use '../variables.scss';

$borderRadius: 5px;

.submissions-lot-container {
  border-radius: $borderRadius;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  .lot-title {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .submissions-lot-table-container {
    padding: 0;

    .submissions-lot-table {
      width: 100%;
    }

    .load-more-line-items-container {
      text-align: center;
      margin-top: 15px;
    }

    .submissions-line-items-table {
      .ant-table-body {
        overflow-x: auto !important; // override built-in AntD "scroll" rule to hide scrollbar
      }

      table {
        border-radius: $borderRadius;

        thead {
          tr {
            th {
              color: $medium-dark-grey;
              font-size: 15px;
              font-weight: 500;
              padding: {
                top: 20px;
                bottom: 20px;
              }

              &:first-child {
                border-top-left-radius: $borderRadius;
              }

              &:last-child {
                border-top-right-radius: $borderRadius;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              font-size: 14px;
              font-weight: 500;
              padding: 4px;

              &.qualifying {
                background: $lightest-grey;
                white-space: nowrap;
              }

              &.line-number {
                text-align: center;
              }

              .status-color-display {
                position: relative;
                background: white;
                height: 100%;
                border-radius: 5px;
                padding: 16px;
                border-color: $lighter-grey;
                width: 100%;
                cursor: pointer;

                &.good { color: $medium-green; }
                &.notice { color: $medium-yellow; }
                &.bad { color: $medium-red; }

                .expand-icon {
                  position: absolute;
                  right: 15px;
                  top: 5px;
                  color: $theme-icon-color;
                  font-size: 20px;
                }
              }
            }

            // Last Row
            &:last-child {
              td {
                &:first-child {
                  border-bottom-left-radius: $borderRadius;
                }

                &:last-child {
                  border-bottom-right-radius: $borderRadius;
                }
              }
            }
          }
        }
      }
    }
  }
}
