@use '../variables.scss';

.lot-header {
  position: relative;
  font-size: 20px;

  .unanswered-count {
    position: absolute;
    right: 0;
    font-size: 11px;
    bottom: -20px;
    color: $medium-grey;
    text-align: right;
  }

  label {
    color: $medium-dark-grey;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;

    &.inline {
      display: inline-block;
      margin-right: 10px;
    }
  }

  // self-serve requires additional bottom padding to avoid buggy icon layout
  &.is-self-serve {
    padding-bottom: 40px;
  }

  label {
    color: #6c6f78;
    font-size: 14px;
    font-weight: 600;
    margin-top: 10px;
    cursor: pointer;

    &.inline {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .lot-incumbent-container {
    display: inline-block; // important to keep collapse panel clickability
    margin-top: 15px;
  }

  .lot-delete-btn-container  {
    position: absolute;
    bottom: 4px;
    right: 40px;
  }

  .ads-btn.lot-delete-btn {
    .adsicon {
      font-size: 22px;
    }

    &:hover {
      color: $base-red;
    }
  }

  .line-item-count {
    position: absolute;
    bottom: 0;
    right: 85px;
    text-align: right;
    font-size: 13px;
    color: $base-grey;
    padding: 5px;
  }

  input[type='text'] {
    &[disabled] {
      background-color: $gray3;
      color: $light-grey;
      cursor: not-allowed;
      border-color: $light-grey;
    }
  }

  button {
    &.access-control {
      &.disabled {
       [aria-label="delete"] {
         color: $light-grey;
         cursor: not-allowed;
       }
      }
    }
  }
}
