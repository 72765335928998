@use '../variables.scss';

.table-filter-dropdown-button {
  background: white;
  border: 1px solid $medium-dark-grey;
  position: relative;
  color: $medium-dark-grey;
  border-radius: 5px;
  padding: 5px 10px;
  display: inline-block;
  cursor: pointer;
  min-width: 8rem;

  &.ant-dropdown-open {
    color: $dark-grey;
    border-color: $dark-grey;

    > i, .adsicon {
      color: $dark-grey;
    }
  }

  > i, .adsicon {
    margin-left: 10px;
    font-size: 12px;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }

  .table-filter-button-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: auto;
    }

    i, .adsicon {
      align-self: center;
    }
  }

  // Special Styling for Top of Button. Applies When drop down is enabled.
  @keyframes fade {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      transform: scaleY(0) translateY(-100%);
      opacity: 0;
    }
    to {
      transform: scaleY(1) translateY(0%);
      opacity: 1;
    }
  }

  @keyframes topText {
  from {
    transform: scale(0) translateY(0%);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(-5%);
    opacity: 1;
  }
}


  @keyframes fadeOut {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  .hide {
    display: none;
  }

  .fade-in {
    transform-origin: 50% 0;
    animation: fadeIn 0.3s ease-in-out;
  }

  .fade-out {
    transform-origin: 50% 0;
    animation: fadeOut 0.3s ease-in-out;
  }

  .button-text {
    color: $dark-grey;
    height: 22px;
  }

  .table-filter-button-top-content {
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: -8px;
    left: 5px;
    text-align: left;
    font-size: 12px;
    background: white;
    padding: 0 7px;
    line-height: 12px;
    white-space: nowrap;
    transition: padding 0.2s;
  }

  &.visible {
    .table-filter-button-top-content {
      opacity: 1;
      transform-origin: 50% 0;
      animation: topText 0.3s ease-in-out;
    }
  }
}

.table-filter-dropdown-overlay {
  padding: 10px;
  background: white;
  border: 1px solid $base-grey;
  border-radius: 2.5px;

  .ant-dropdown-menu {
    box-shadow: none;
    padding: 0;
    margin: 0;

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      border-radius: 4px;
      border: 1px solid transparent;

      &.ant-dropdown-menu-item-selected {
        background: $theme-hover-color;
        color: white;
      }

      &:not(.ant-dropdown-menu-item-selected) {
        background: transparent;

        &:hover {
          border-color: $theme-hover-color;
        }
      }
    }
  }

  button.ads-btn {
    display: flex;

    label {
      margin-right: 10px;
    }

    .circle-icon {
      vertical-align: middle;
      margin-left: 8px;
      display: inline-block;
      height: 7px;
      width: 7px;
      border-radius: 50%;
    }
  }
}


.table-filter-overlay-overflow {
  max-height: 20rem;
  overflow: hidden scroll;
}
