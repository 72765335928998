.suppliers-by-table-container.project {
  table {
    tr {
      td {
        &.project {
          strong {
            margin-right: 15px;
          }
        }
      }
    }
  }
}