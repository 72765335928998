@use './variables.scss';

// 1 below AntD's default modal backdrop zIndex
$affixedZIndex: 999;

//Ant
body {
  .ant-layout {
    background: #F5F6FB;
  }

  .ant-avatar-circle {
    vertical-align: middle;
  }

  .ant-menu-vertical .ant-menu-item:after,
  .ant-menu-vertical-left .ant-menu-item:after,
  .ant-menu-vertical-right .ant-menu-item:after,
  .ant-menu-inline .ant-menu-item:after {
    border-right: none;
  }

  .ant-menu-vertical .ant-menu-item:before,
  .ant-menu-vertical-left .ant-menu-item:before,
  .ant-menu-vertical-right .ant-menu-item:before,
  .ant-menu-inline .ant-menu-item:before {
    border-left: 2px solid #ffffff;
  }

  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }

  .ant-layout-sider-trigger {
    display: none;
  }
}

// Antd (Ant Design) overrides
.ant-affix {
  // Dropdown z-index is 1051
  // Tooltip & Popover z-index is 1060
  z-index: $affixedZIndex;
}

// Alerts
.ant-message .ant-message-notice .ant-message-notice-content {
  padding: 0;
  border-radius: 2px;

  .ant-message-custom-content {
    padding: 10px 16px 10px 12px;
    border-left: 4px solid $base-grey;

    &.ant-message-success {
      border-color: $medium-green;

      .adsicon,
      .anticon {
        color: $medium-green;
      }
    }

    &.ant-message-warning {
      border-color: $medium-orange;

      .adsicon,
      .anticon {
        color: $medium-orange;
      }
    }

    &.ant-message-error {
      border-color: $medium-red;

      .adsicon,
      .anticon {
        color: $medium-red;
      }
    }
  }
}

// Pagination
.ant-table-pagination.ant-pagination {
  font-size: 14px;

  .ant-pagination-prev,
  .ant-pagination-next {
    border-radius: 12px;
    border: 1px solid $theme-icon-color;
    color: $theme-icon-color;
    line-height: 20px;

    .ant-pagination-item-link {
      color: $theme-icon-color;
    }
  }

  .ant-pagination-prev {
    margin-right: 20px;
  }

  .ant-pagination-item {
    color: #9b9ea8;

    &:focus {
      color: #9b9ea8;
    }

    &.ant-pagination-item-active {
      background: $theme-button-secondary-color;
      color: white;
      border-color: $theme-button-secondary-color;

      a {
        color: white;
      }

      &:hover,
      &:focus {
        a {
          color: white;
        }
      }
    }

    &:not(.ant-pagination-item-active) {
      color: $medium-light-grey;

      a {
        color: $medium-light-grey;
      }
    }
  }

  .ant-pagination-next {
    margin-left: 20px;
  }
}

// Calendar
.ant-calendar-today .ant-calendar-date {
  border: none;
  color: $theme-color;
}

.ant-calendar-selected-date .ant-calendar-date {
  color: white;
  background-color: $theme-color;
}

// The input fields
.ant-calendar-picker.standard-picker {
  width: 100%;

  input.ant-calendar-picker-input {
    border-radius: 0;
    border: 1px solid $medium-grey;
    border-top: none;
    border-left: none;
    border-right: none;

    &:hover,
    &:focus {
      border-color: $theme-hover-color;
      outline: none;
    }
  }
}

.ant-input-number.standard {
  border: 1px solid $medium-grey;
  width: 100%;
  color: #000000;
  font-size: 18px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;

  &:hover,
  &:focus {
    border-color: $theme-hover-color;
    outline: none;
  }
}

// Input Groups (Input Field w/ Attached Button or Dropdown)
.ant-input-group-wrapper .ant-input-wrapper.ant-input-group {
  input {
    border: 1px solid $medium-grey;
    color: $default-text;
    font-size: 14px;
    line-height: 1;
    padding: 10px 15px;

    &:hover,
    &:focus {
      border-color: $theme-hover-color;
      outline: none;
    }

    &[readonly] {
      cursor: not-allowed;
    }
  }

  .ant-input-group-addon {
    border-color: $medium-grey;
    padding: 0 20px;

    // Select Field
    .ant-select {

      &.ant-select-open,
      &.ant-select-focused {
        .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value {
            color: $theme-hover-color;
        }
      }

      .ant-select-selection {

        // The visible selection
        .ant-select-selection__rendered {
          margin-right: 40px;
        }

        .ant-select-arrow {
          color: $default-text;
        }
      }
    }
  }
}

// Having an AntD input field and an AntD select as a single component
$selectBorderSize: 1px;

.inline-input-select {
  .ant-input-number {
    line-height: 30px;
  }

  .ant-select {
    border: none;

    .ant-select-selection {
      min-width: auto;
      height: 32px - $selectBorderSize;
      border-bottom: $selectBorderSize solid $medium-grey;
      padding: 0;
      border-radius: 0;
      background: $lightest-grey;

      .ant-select-selection-selected-value {
        font-size: 14px;
      }
    }
  }
}

// The actual calendar panel
.ant-calendar-picker-container .ant-calendar .ant-calendar-panel {

  // the input fields
  .ant-calendar-input-wrap .ant-calendar-date-input-wrap .ant-calendar-input {
    font-weight: 600;
  }

  .ant-calendar-date-panel {
    .ant-calendar-header {
      background: #000;
      color: white;

      a {
        color: white;

        &:before,
        &:after {
          // the arrows
          border-color: white;
        }
      }
    }

    .ant-calendar-body {
      padding: 0;

      .ant-calendar-table thead tr {
        background: #f6f6f6;
      }
    }
  }
}

.ant-time-picker {
  &.standard,
  &.standard-picker {
    width: 100%;

    input.ant-time-picker-input {
      border-radius: 0;
      border: 1px solid #95979e;
      border-top: none;
      border-left: none;
      border-right: none;

      &:hover,
      &:focus {
        border-color: $theme-hover-color;
        outline: none;
      }
    }
  }
}

// AntD Checkboxes
.ant-checkbox-wrapper .ant-checkbox {
  .ant-checkbox-inner {
    border-color: $theme-color;
    border-width: 1px;
  }

  &.ant-checkbox-checked .ant-checkbox-inner {
    background: $theme-hover-color;
    color: white;
  }
  &.ant-checkbox-disabled .ant-checkbox-inner{
    background: $lightest-grey;
  }
  &.ant-checkbox-indeterminate .ant-checkbox-inner {
    background: white;

    &::after {
      background: $theme-hover-color;
    }
  }
}

.ant-modal .ant-modal-body h3 {
  font-weight: 300;
}

.ant-select-dropdown ul {
  padding: 15px;

  &.ant-select-dropdown-menu li {
    color: $medium-grey;

    &.ant-select-dropdown-menu-item-selected {
      color: white;
      background: $theme-hover-color;
      font-weight: 400;
      border-radius: 4px;
    }

    &.ant-select-dropdown-menu-item-disabled {
      color: $light-grey;
    }
  }
}

.ant-collapse-borderless>.ant-collapse-item {
  border-bottom: none;
}

.ant-select-black-arrow {
  .ant-select-arrow {
    color: black;
  }

  .ant-select-selection {
    &:hover,
    &:focus {
      box-shadow: none;
      border-color: $theme-hover-color !important;
    }
  }
}

.bid-request-subtab {
  .ant-tabs-top-bar {
    border: none;
  }

  &.ant-tabs.ant-tabs-line .ant-tabs-bar .ant-tabs-nav-wrap .ant-tabs-tab {
    padding-bottom: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 12px;

    &.ant-tabs-tab-active {
      color: white;
      background-color: #2b2c2f;
      text-transform: none;
    }
  }

  .ant-tabs-tab {
    border-radius: 22.5px;
    width: 182px;
    height: 45px;
    text-align: center;
  }

  .ant-tabs-ink-bar {
    background-color: inherit; // effectively hides
  }
}

.ant-tabs-tab-active.ant-tabs-tab {
  color: $theme-active-color;
  background-color: #fff;
}

.ant-tabs-nav .ant-tabs-tab {
  margin-right: 0;

  &:hover {
    color: $theme-hover-color;
  }
}

.ant-tabs-ink-bar {
  background-color: $theme-active-color; // bar under the active tab
  height: 3px;
}

.ant-tabs-top-bar {
  padding-left: 20px;
}

.borderless {
  border: none !important;
}

// AntD Switch
.ant-switch.ant-switch-checked {
  background-color: $theme-hover-color;
}

// AntD Popovers
.ant-popover.no-padding .ant-popover-inner-content,
.antd-v5-popover.no-padding .antd-v5-popover-inner {
  padding: 0;
}

.ant-dropdown {
  // Standard class signifies this should be styled with our base green list items
  &.standard ul.ant-dropdown-menu {
    padding: 10px;
  }

  &.min-300 {
    min-width: 300px;
  }
}

.disable-suggested-pricing-toggle {
  margin-bottom: 5px;
}

// AntD Radio Buttons
.ant-radio {
  .ant-radio-inner {
    border-color: $theme-color;
  }

  &.ant-radio-checked {
    .ant-radio-inner {
      border-color: $theme-hover-color;

      &:after {
        background-color: $theme-hover-color;
      }
    }
  }

  &:hover :not(.ant-radio-checked) .ant-radio-inner {
    border-color: $theme-hover-color;
  }
}

.customCriteriaSurveySectionAntdOverrides {
  .antd-v5-collapse-header {
    margin: 0 !important;
    padding: 1.5rem !important;
  }

  .antd-v5-collapse-content-box {
    padding: 0;
    margin: 0;
  }
}

// Ant Tabs color style overrides
.antd-v5-tabs .antd-v5-tabs-tab.antd-v5-tabs-tab-active .antd-v5-tabs-tab-btn {
  color: $theme-active-color;
}

// Ant Tabs color style overrides
.antd-v5-tabs .antd-v5-tabs-ink-bar {
  background-color: $theme-active-color;
}

.antd-v5-dropdown-menu-item-danger{
  color: $colorErrorBase !important;

  &.antd-v5-dropdown-menu-item-active {
    color: white !important;
    background-color: $colorErrorBase !important;
  }
}

// Notifications
.ant-notification-notice-wrapper {
  background: transparent;
  box-shadow: none;
  margin-bottom: 0;

  .ant-notification-notice {
    margin-bottom: 0;
  }
}

// Drag and Drop
// TODO: Convert to ADS
.ant-upload.ant-upload-drag{
  border: none;

  .anticon-plus{
    color: inherit;
    font-size: 14px;
  }

  span.ant-upload.ant-upload-btn {
    padding: 0;
  }
}
