@use '../variables.scss';

$borderRadius: 5px;

#supplier-survey-table {
  border: 1px solid $lighter-grey;
  border-radius: $borderRadius;

  .ant-table-body {
    overflow-x: auto !important; // override built-in AntD "scroll" rule to hide scrollbar
  }

  table {
    border: none;

    thead {
      tr {
        th {
          color: $medium-dark-grey;
          font-size: 15px;
          font-weight: 500;
          padding: {
            top: 20px;
            bottom: 20px;
          }

          &:first-child {
            border-top-left-radius: $borderRadius;
          }

          &.overall-parent-header,
          &.lot-parent-header {
            text-align: center;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
          font-weight: 500;
          padding: 4px;

          &.submitted-at,
          &.tco-criteria {
            font-weight: 600;
          }

          &.line-number,
          &.supplier-name,
          &.submitted-at {
            padding: 20px;
          }

          &.yes_no,
          &.multiple_choice {
            padding: 0;
          }

          .supplier-name-container {
            a {
              font-size: 14px;
              font-weight: 600;
              color: $theme-link-color;

              &.org-name {
                font-weight: normal;
              }
            }
          }
        }

        // Last Row
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: $borderRadius;
            }

            border-bottom: 1px solid transparent;
          }
        }
      }
    }
  }

  .ant-table-fixed {
    border-bottom-left-radius: $borderRadius;
  }

  .ant-table-scroll {
    border-radius: $borderRadius;

    table {
      thead {
        tr {
          th {
            &:last-child {
              border-top-right-radius: $borderRadius;
            }
          }
        }
      }

      tbody {
        tr {
          // Last Row
          &:last-child {
            td {
              &:last-child {
                border-bottom-right-radius: $borderRadius;
              }
            }
          }
        }
      }
    }

    th, td {
      &:last-child {
        border-right: 1px solid transparent;
      }

      &:nth-last-child(2) {

      }
    }
  }
}


// Specific to a transposted table (e.g. RFP Scoring Submissions)
.transposed-supplier-survey-wrapper {
  table {
    thead {
      tr {
        th {
          background: white;

          .ant-table-header-column {
            width: 100%;
          }

          .header-container {
            display: flex;
            justify-content: space-between;

            .column-number {
            }

            .organization {
              display: flex;
              padding-left: 4px;

              .submitted-at {
                font-size: 10px;
                color: $medium-grey;
              }

              .vendor-organization-name {
                text-overflow: ellipsis;
              }
            }

            .fraction-container {
              padding-left: 4px;

              .label {
                padding-bottom: 4px;
              }
            }
          }
        }
      }
    }

    tbody {
      tr {
        td {
          .submission-cell-display {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: row;
            padding: 10px;
            flex-wrap: wrap;

            > *:first-child {
              flex-grow: 2;
            }

            .fraction-display {
              margin-left: 15px;
            }


            .ant-select {
              height: auto;

              .ant-select-selection {
                border-radius: 5px;
                border-color: $base-grey;
                background: white;

                .ant-select-arrow {
                  i.adsicon,
                  i.anticon {
                    color: $theme-icon-color;
                  }
                }
              }
            }

            &.doc {
              a {
                color: $theme-icon-color;
              }
            }
          }

          // First column
          &:first-child {
            padding: 20px !important; // override #ID above
          }
        }

        &.section-row {
          td {
            background: $lightest-grey;

            // First column display
            .section-info-container {
              .section-info-weighting {
                font-size: 12px;
              }
            }

            .score-container {
              text-align: right;
              padding-right: 10px;
              height: 100%;
              padding-top: 10px;
            }
          }
        }

        &.question-row {
          .question-info-container {
            div {
              font-size: 12px;
              color: $medium-grey;
            }
          }
        }
      }
    }
  }
}
