@use '../variables.scss';

#round-timer {
  .rounds-container {
    background: $lightest-grey;
    border-radius: 10px;
    margin: {
      top: 20px;
      bottom: 0;
    }
    border: none;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    overflow: auto;
  }

  .round-timer-display {
    margin-bottom: 15px;
    padding: 10px 5px;
    color: $medium-grey;
    font-size: 16px;
    margin-top: 10px;
    text-align: center;
  }

  .round-ranks-container {
    display: flex;
    justify-content: flex-end;
  }
}
