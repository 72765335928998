.title {
  font-size: 20px;
}

.subtitle {
  font-size: 15px;
}

.inputLabel {
    font-size: 15px;
    margin-top: 15px;
}
