// Select button element
.ant-select.customized-select {
  border: 1px solid transparent;
  border-radius: 5px;

  .ant-select-selection {
    border: none;
    padding: 15px 15px 15px 0;
    min-width: 250px;
    height: auto;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    .ant-select-selection-selected-value {
      font-size: 20px;
      font-weight: 600;
    }
  }

  &.auto-width {
    min-width: 100% !important;

    .ant-select-selection {
      min-width: 100%;
    }
  }

  &.inline {
    border: none;

    .ant-select-selection {
      padding: 0;
      border-bottom: 1px solid $medium-grey;
      border-radius: 0;
      box-sizing: border-box;
      height: 30px; // box-sizing not correctly handling div height, so we force it here to line things up

      .ant-select-selection-selected-value {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }

  &.bordered {
    border-color: $light-grey;

    // Default
    &.light-grey {
      border-color: $light-grey;

      .ant-select-arrow-icon {
        color: $light-grey;
      }
    }

    .ant-select-arrow-icon {
      color: $light-grey;
    }

    &.base-grey {
      border-color: $base-grey;

      .ant-select-arrow-icon {
        color: $base-grey;
      }
    }

    &.medium-grey {
      border-color: $medium-grey;

      .ant-select-arrow-icon {
        color: $medium-grey;
      }
    }
  }

  &.as-form {
    border: none;
    border-bottom: 1px solid $medium-grey !important;
    border-radius: 0;
  }

  &.small {
    .ant-select-selection {
      padding: 5px 5px 5px 0;

      .ant-select-selection-selected-value {
        font-size: 14px;
      }
    }
  }

  &.ant-select-disabled.unstyled-disable {
    background: transparent;
    border-color: transparent !important;

    .ant-select-selection {
      background: transparent;
      border-color: transparent;

      .ant-select-arrow {
        opacity: 0;
      }
    }
  }
}

// Floating dropdown elements
.ant-select-dropdown.customized-select-dropdown {
  min-width: 250px;

  &.auto-width {
    min-width: auto;
  }

  ul.ant-select-dropdown-menu {
    padding: 15px;

    li {
      color: $medium-grey;

      &.ant-select-dropdown-menu-item-selected {
        color: white;
        background: $theme-button-secondary-color;
        font-weight: 400;
        border-radius: 4px;
      }

      &.ant-select-dropdown-menu-item-disabled {
        color: $light-grey;
      }
    }
  }
}
