.round-row {
  font-size: 14px;
  line-height: 18px;

  .round-title {
    padding-left: 50px;

    &:before {
      content: '';
      position: absolute;
      left: -30px;
      width: 1px;
      height: 100%;
    }

    svg {
      left: -5px;
      bottom: -2px;
      position: relative;
      line-height: 0;
    }
    padding-top: 20px;
  }

  .round-date-time {
    padding-top: 10px;
    text-align: center;

    p {
      margin: 0;
    }
  }

  .passed-round {
    color: #141516;
  }

  .delimiter-column {
    font-size: 18px;
    text-align: center;
    p {
      padding-top: 20px;
    }
  }
}

.active-round {
  color: $theme-text-color;
  background: $lightest-grey;

  border: 1px solid $light-grey;
  border-radius: 10px;
  .round-column {
    .round-title {
      padding-top: 20px;
      padding-left: 50px;
      position: relative;

      ::before {
        content: '';
        position: absolute;
        left: -30px;
        width: 1px;
        height: 100%;
      }

      svg {
        left: -5px;
        bottom: -2px;
        position: relative;
        line-height: 0;
      }
    }

    .round-title-text{
      padding-right: 10px;
    }
  }
  .round-countdown-container{
    display: inline;
    background: #FFFFFF;
    /* Primary/Default/Green */
    padding: 4px 8px;

    border: 1.2px solid $theme-text-color;
    border-radius: 3px;
    .closed-in{
      font-weight: 400;
    }
    color: $theme-text-color;
    width: 209px;
    height: 26px;
  }
}
