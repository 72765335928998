#awarded-stats-bar {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 -10px 20px -15px #ccc;
  background: white;
  border-top: 1px solid #e5e9ed;
  z-index: 2;

  .awarded-stats-bar-container {
    padding: 20px 45px;
    width: 100%;

    .selected-container {
      display: flex;
      align-items: center;
      height: 100%;
      flex-wrap: wrap;

      .price-container {
        margin-bottom: 10px;
        width: 100%;

        .title, .value {
          font-size: 22px;
          font-weight: 300;

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }

        .value {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }

    .baseline-container {
      text-align: center;

      .price-container {
        margin-bottom: 10px;

        .title, .value {
          font-size: 18px;
          font-weight: 300;

          @media screen and (max-width: 768px) {
            font-size: 14px;
          }
        }

        .value {
          margin-left: 10px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .price-container {
        text-align: center;

        .title {
          display: block;
        }
      }
    }
  }
}
