@use '../variables.scss';

// Gets utilized in both the Select Rendered Display and the Dropdown Container
.with-weighting-display {
  // Override the selected style to be a border and not a full background color
  &.ant-select-dropdown {
    ul.ant-select-dropdown-menu {
      li.ant-select-dropdown-menu-item-selected {
        background: transparent;
        color: $default-text;
        border: 1px solid $theme-hover-color;
      }
    }
  }

  .select-option-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .answer-text {
      white-space: break-spaces;
      line-height: 140%;
    }

    .weight-container {
      font-size: 12px;
      color: $medium-dark-grey;
      text-align: right;
      line-height: 140%;
      padding-right: 15px;

      &.bonus {
        .bonus-malus {
          color: $dark-green;
        }

        .value-display {
          color: $dark-green;
        }
      }

      &.malus {
        .bonus-malus {
          color: $dark-red;
        }

        .value-display {
          color: $dark-red;
        }
      }
    }
  }
}

.submission-cell-display {
  display: flex;
  flex-direction: column;
  height: 100%;

  // Make the first child 100% width
  &.doc > *:first-child,
  &.single_text > *:first-child {
    flex: 1 0 100%;
  }

  .ant-select {
    height: 100%;

    .ant-select-selection {
      height: 100%;
      border-color: transparent;
      border-radius: 0;
      background: transparent; // Let's table row hover effect show through

      .ant-select-selection__rendered {
        height: 100%;
        display: flex;
        align-items: center;

        .ant-select-selection-selected-value {
          width: 100%;
        }
      }

      .ant-select-arrow {
        i.adsicon,
        i.anticon {
          color: $theme-icon-color;
        }
      }
    }
  }

  .pointable-question {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-top: 15px;

    .pointable-container {
      flex-grow: 2;

      input {
        height: 45px;
      }
    }

    .fraction-display {
      padding: {
        top: 8px;
        bottom: 8px;
      }
      line-height: 12px;
    }
  }

  textarea {
    resize: none;
  }

  &.no-submission {
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
  }
}

.scores-list-popover-container {
  width: 280px;
}
