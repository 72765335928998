.customize-email-step {
  .ant-comment {
    .ant-comment-inner {
      padding-top: 0;
      padding-bottom: 10px;

      .ant-comment-avatar {
        min-width: 60px;
      }
    }

    &.to-section {
      padding: 10px 15px 10px 0;
      min-height: 44px;
    }
  }

  .label {
    position: relative;
    top: 5px;

    &.subject {
      top: 15px;
    }
  }
}
