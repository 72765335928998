@import '@tokens';

.contract-step-container {
  .request-onboarding-tooltip-line{
    margin-top: 0;
    margin-bottom: 10px;
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-button-wrapper {
      width: 50%;
      text-align: center;
      height: 36px;
      padding: 2px 15px;
      color: $default-text;
      font-size: 14px;

      &.ant-radio-button-wrapper-checked {
        border-color: $theme-color;
        background: $theme-color;
        box-shadow: none;
        color: $white;

        &:focus-within {
          outline: none;
        }
      }
    }
  }

  .request-date-wrapper {
    margin-top: 20px;

    .subtitle-text {
      font-size: 12px;
      color: $medium-grey;
    }

    .ant-input-number {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right-color: transparent;
    }

    .ant-select {
      .ant-select-selection {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
