// Button
.sort-dropdown-container {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  .key-container {
    border-radius: 5px;
    padding: 4px 8px;
  }

  .order-container {
    border-radius: 30px;
    font-size: 18px;
    padding: 4px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 24px;
    margin-left: 4px;
    border: none;
    background: transparent;
  }

  .key-container,
  .order-container {
    cursor: pointer;

    &:hover {
      background: $lighter-grey;
    }
  }
}
