.request-tab-page-header {
  padding: 0 15px 15px 0;
  margin-bottom: 15px;
  text-align: left;
  font-size: 15px;
  font-weight: 600;
  color: $base-grey;
  border-bottom: 1px solid $lighter-grey;

  a {
    color: $base-grey;

    i {
      margin-right: 15px;
    }
  }
}
