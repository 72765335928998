@use '../variables.scss';

#request-dashboard {
  .wrapper {
    padding: 10px 20px 0;
  }

  .preview-as-supplier-container {
    text-align: right;
    margin-bottom: 10px;
  }

  .delete-request-container {
    text-align: center;
    max-width: 300px;
    margin: 25px auto;
    padding-top: 40px;
    border-top: 1px solid $light-grey;
  }
}
