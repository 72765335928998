@use '../variables.scss';

$qualifyingIconSize: 18px;

.line-item-submissions-modal {
  .table-wrapper {
    overflow-x: auto;
    border-radius: 5px;
    border: 1px solid $light-grey;
    padding-bottom: 15px;

    .ant-table-header-column {
      white-space: nowrap;
    }

    .ant-table-wrapper.line-item-submissions-table {
      .ant-table {
        thead {
          tr {
            th {
              background: white;
              font-size: 12px;
              color: $medium-light-grey;

              // Name and Org Columns
              &:not(.qualifying) {
                min-width: 180px;
              }
            }
          }
        }

        > .ant-table-content {
          .ant-table-body {
            table {
              tbody.ant-table-tbody {
                > tr {
                  td {
                    border-color: transparent;
                    padding: {
                      top: 5px;
                      bottom: 5px;
                    }

                    &:not(.qualifying) {
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .answer-display {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    .supplier-response-answer-select {
      &.answer-options {
        width: 90px !important;
      }
    }

    .answer-option-field {
      &.numeric {
        input {
          min-height: 40px;
        }
      }
    }

    .qualifying-icon-display {
      margin-left: 15px;
      background: $background-red;
      color: $dark-red;
      width: $qualifyingIconSize;
      min-width: $qualifyingIconSize;
      height: $qualifyingIconSize;
      min-height: $qualifyingIconSize;
      text-align: center;
      display: inline-flex;
      align-items: center;
      border-radius: 20px;
      font-size: 10px;
      justify-content: center;
      font-size: 10px;

      &.true {
        background: $background-green;
        color: $dark-green;
      }
    }
  }

  .actions-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    // Indicates changes have been made to the line item answers
    &.true {
      justify-content: space-between;
    }
  }
}

.supplier-response-answer-dropdown {
  min-width: 120px !important;
}
