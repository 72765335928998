#yes-no-weightings-select {
  .applied-to-container {
    margin-bottom: 35px;
  }

  .add-weighting-action {
    margin-top: 20px;
    color: $theme-text-color;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
}
