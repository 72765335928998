@use '../variables.scss';

#scroll-to-div { display: none; }

.lots-and-line-items-container {
  .ant-collapse {
    border: none;

    // Collapse Arrow creates undesired padding that we need to correct
    .ant-collapse-item {
      border: 1.5px solid $border-grey-color;
      margin-bottom: 10px;
      border-radius: 5px;
      background: $white;

      > .ant-collapse-header {
        position: relative;
        padding-right: 16px;

        .adsicon.adsicon-right,
        .anticon.anticon-right {
          &.ant-collapse-arrow {
            right: 16px;
            left: auto;
            top: auto;
            bottom: 5px;
            color: $base-grey;
            border-radius: 50%;
            font-size: 22px;
            transition: all 0.3s ease 0s;
          }
        }
      }

      &:hover {
        border-color: $theme-hover-color;

        > .ant-collapse-header {
          > .adsicon,
          > .anticon {
            &.ant-collapse-arrow {
              background: $theme-button-secondary-color;
              font-size: 12px;
              color: $white;
              bottom: -5px;
              padding: 10px;
            }
          }
        }
      }

      .ant-collapse-content {
        border-top: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        background: transparent;
      }
    }
  }

  .search-bar  {
    margin-bottom: 20px;
  }

  // Don't re-style the expand arrow for evaluation criteria
  .lot-questions-container .ant-collapse {
    .ant-collapse-item,
    .ant-collapse-item:hover {
      .ant-collapse-header {
        .adsicon.adsicon-right,
        .anticon.anticon-right {
          &.ant-collapse-arrow {
            right: 16px;
            left: auto;
            top: 50%;
            bottom: auto;
            color: $base-grey;
            border-radius: 50%;
            font-size: 16px;
            transition: all 0.3s ease 0s;
            padding: 0;
            background: transparent;
            border-color: transparent;
          }
        }
      }
    }
  }

  .lots-empty {
    border: 1.5px solid $border-grey-color;
    border-radius: 5px;
    background: $white;
  }
}
