@import '~@arkestro/arkestro-design-system/tokens/scss/tokens.scss';

$white: $gray1;
$black: $gray13;
$default-text: #2b2c2f;
$off-white: #fcfcfc;

$theme-primary-button-color: $blue7;
$theme-primary-button-color-submitted : #{$theme-primary-button-color} + #{42}; // darken alpha by 42;
$theme-button-secondary-color: $gray13; // previously was the base-green accent color
$theme-link-color: $blue7;
$theme-disabled-color: lighten($gray13, 40%);
$theme-hover-color: lighten($gray13, 20%);
$theme-text-color: $gray13;
$theme-icon-color: $gray13;
$theme-icon-color-inverted: $white;
$theme-header-color: $gray13;
$theme-color: $gray13;
$theme-active-color: $blue7;


$lightest-green: #f8fffe;
$base-green: #3fcfc1;
$hover-base-green: #6b9da1;
$medium-green: #358a61;
$dark-green: #246847;
$background-green: #edf5f4; // this and background-border are commonly used together
$background-border-green: #e0eeec; // commonly used with above variable
$status-green: #72dc88;

$lightest-grey: #f6f6f6;
$lighter-grey: #e1e3e6; // or when #e5e9ed, #e6e6e6 is specified
$light-grey: #d3d3d3; // or when #c0c0c0 is specified
$base-grey: #a5a6ab;
$medium-light-grey: #9b9ea8;
$medium-grey: #898b91; // or when #95979e is specified
$medium-dark-grey: #6c6f78;
$mostly-dark-grey: #2f3034; // For going in dark grey for embedding
$dark-grey: #242528;

$base-blue: #1d93d3;
$sky-blue: #61b8d1;
$medium-blue: #4060ff; // Looks like awarded
$lightest-blue: #f1faff;

$medium-yellow: #cbab00;
$bright-yellow: #ffcb13; // Looks close to response submitted color
$background-yellow: #fffbee;
$dark-yellow: #a67300;

$bright-orange: #ffa513;
$medium-orange: #d47000;

$medium-red: #cd4141;
$medium-light-red: #b86161;
$gray-red: #a48181;
$light-red: #efafac; // Also $error-highlight: #efafac; in colors
$error-highlight: #efafac;
$background-red: #efafac;
$base-red: #be0000;
$dark-red: #9e0000;

// Request States
$draft: rgba(137, 139, 145, 1);
$open: rgba(63, 207, 193, 1);
$openForQuestions: #389E0D;
$openForBidding: rgba(97, 184, 209, 1);
$responseSubmitted: rgba(255, 197, 21, 1);
$closed: rgba(29, 147, 211, 1);
$awarded: rgba(64, 96, 255, 1);
$readyToAward: $blue6;
$notAwarded: $orange6;

//Request Types
$proposal: #4060ff;
$quote: #1d93d3;
$information: #be00a0;
$purchaseRequest: #246847;

// Invitation States
$bounced: #d47000;
$rejected: #cd4141;
$declined: #cd4141;

$snoColumnMinWidth: 86px;

// Layout
$backdropZIndex: 2;
$fixedNavBarZIndex: 10; // Default z-index from AntD's Affix component

// DOM Elements
$marks: #e5f7f4;

// $base-grey: ...
$dark-grey: #242528;

$other-medium-grey: #95979e;  // in _variables it's 898b91, should consolidate to one or the other

$medium-red: #cd4141;

// Using Bootstrap Breaks for now: https://getbootstrap.com/docs/4.3/layout/grid/

$xsmallBreak: 440px;
$smallBreak: 576px;
$mediumBreak: 768px;
$largeBreak: 992px;
$xlBreak: 1200px;
$xxlBreak: 1600px;

// AntD Vars

$affixedZIndex: 999;

// Additional Color Vars
$primary-color: #1D9ED3;
$highlight-color: #ff0000;
$primary-button-color: #0958d9; // primary-button-color
$page-header: #1A94CD;
$yes: #4AC16A;
$no: #EB5B71;
$maybe: #FAD13D;
$badge-background-color: #f50057;

$border-grey-color: #c9cdd1;

$antd-border-color: #d9d9d9;
$antd-warning-background-color: #fffbe6;
$antd-warning-color: #FAAD14;
$antd-warning-border-color: #ffe58f;
$antd-info-background-color: #E6F7FF;
$antd-info-color: #1890FF;

$chinese-silver: #ccc;

// Sidebar
$minSidebarBreak: calc($mediumBreak + 1px);
$collapsedSidebar: 80px;
$expandedSidebar: 240px;
