.organization-vendors-table.styled-table.ant-table-wrapper {
  .ant-table > .ant-table-content .ant-table-body {
    table {
      tbody.ant-table-tbody {
        > tr {
          td {
            color: #2b2c2f;
            font-weight: 600;

            &:last-of-type {
              padding-right: 45px;
            }
          }
        }
      }
    }
  }
}
