@import '@tokens';

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .indicators {
    .indicator {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      border: 2px solid #ccc;
      background: #ccc;
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      cursor: pointer;

      &.active {
        width: 30px;
        border-color: $theme-button-secondary-color;
        background: white;
      }
    }
  }
}
