@use '../variables.scss';

.csv-dropdown-menu {
  .menu-item {
    .disabled {
      color: $light-grey;
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}
