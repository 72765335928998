@use '../variables.scss';

.request-type {
  &.proposal {
    color: $proposal;
  }

  &.quote {
    color: $quote;
  }

  &.information {
    color: $information;
  }

  &.purchase_request {
    color: $purchaseRequest;
  }
}
